import { Button } from 'components/common/Button/Button';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const Footer = ({ onClick, disabled }) => (
  <MaxWidthWrapper>
    <GS.Container justifyContent="center" alignItems="center" padding={GS.spacing.xs}>
      <Button text="Siguiente" maxWidth={GS.screenSizes.xs} onClick={onClick} disabled={disabled} />
    </GS.Container>
  </MaxWidthWrapper>
);

export default Footer;
