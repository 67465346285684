/* eslint-disable no-nested-ternary */
import {
  NavigationWrapper,
  UiWrapper,
  Page,
  TargetsUtilsWrapper,
  RulesWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import initial from 'assets/images/rules-initial.svg';
import noImage from 'assets/images/target-header-bg.png';

import useTargetRules from 'hooks/useTargetRules';
import useTarget from 'hooks/useTarget';

import { getRemainingMonths } from 'utils/getMonths';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import HeaderChat from 'components/common/Header/HeaderChat';
import Footer from '../CreateTarget/components/Footer';
import Modal from '../CreateTarget/components/Modal';

import * as GS from 'styles/globalStyles';
import { SummaryLineItem } from './SummaryLineItem';
import { SummaryItem } from './SummaryItem';
import { Button } from 'components/common/Button/Button';
import { useState } from 'react';

export const Summary = () => {
  const { params } = Page.use();
  const target = useTarget(params.targetId);
  const targetRules = useTargetRules(target?.id);
  const { useFooter, openModal, useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();

  const { activate } = TargetsUtilsWrapper.use();
  const { extractValue } = RulesWrapper.use();

  const [loading, setLoading] = useState(false);
  const [loadingProps, setLoadingProps] = useState({
    message: 'Guardando datos',
  });

  const getValue = (rule) => {
    const value = extractValue(rule, rule?.ruleDefinition?.fieldDefinitions[0]?.identifier) || 'No';

    if (rule.isActive) {
      if (rule.type === 'currency' || rule.name.toLowerCase() === 'redondeo') {
        return value + '€';
      }
      if (rule.type === 'increment') {
        return value + '%';
      }
      return value;
    }
    return value;
  };

  useHeader(<HeaderChat text="Resumen" onBack={() => goBack()} />);

  useFooter(
    <Footer
      onClick={async () => {
        setLoading(true);
        try {
          await activate(params.targetId);
          setLoading(false);
          openModal((close) => (
            <Modal
              close={close}
              txt="¡Enhorabuena tu meta ha sido creada con éxito!"
              click={() => navigateTo(routes.targets)}
            />
          ));
        } catch (e) {
          setLoading(false);
          openModal((close) => (
            <Modal
              close={close}
              txt="Ha ocurrido un error al activar tu objetivo"
              click={() => close()}
            />
          ));
        }
      }}
    />
  );

  useLoading(loading, loadingProps);

  return (
    <MaxWidthWrapper>
      <GS.Container
        justifyContent="start"
        alignItems="center"
        padding={`0 ${GS.spacing.xs}`}
        gap={GS.spacing.xs}
        grow={1}
      >
        <GS.Container position="relative" alignItems="center" gap={GS.spacing.xs}>
          <GS.Container
            alignItems="center"
            justifyContent="center"
            borderRadius={GS.radius.sm}
            overflow="hidden"
          >
            <GS.Image width="100%" src={target?.image || noImage} />
          </GS.Container>
          <GS.Container position="absolute" alignItems="center" bottom={GS.spacing.xs}>
            <Button
              text="Editar objetivo"
              buttonStyle="minimal"
              width={GS.spacing.xl7}
              onClick={() =>
                navigateTo(routes.createTarget, {
                  routeParams: {
                    section: 'start',
                    targetId: target?.id,
                  },
                  queryParams: {
                    from: 'fill',
                  },
                })
              }
            />
          </GS.Container>
        </GS.Container>

        <GS.Container alignItems="start" gap={GS.spacing.xs2}>
          <SummaryLineItem title="Nombre" text={target?.name || 'Nombre'} />
          <SummaryLineItem title="Dinero" text={(target?.creationData?.price || 0) + '€'} />
          <SummaryLineItem title="Duración" text={getRemainingMonths(target) + ' meses'} />
        </GS.Container>

        <GS.Container
          alignItems="start"
          padding={GS.spacing.xs}
          gap={GS.spacing.xs2}
          background={GS.colors.lightGrey}
          borderRadius={GS.radius.sm}
        >
          <SummaryItem
            icon={initial}
            title="Aportación inicial"
            text={`${target?.creationData?.initialContribution || 0} €`}
          />
          {targetRules.map((rule) => (
            <SummaryItem key={rule.name} icon={rule.icon} title={rule.name} text={getValue(rule)} />
          ))}
        </GS.Container>
      </GS.Container>
    </MaxWidthWrapper>
  );
};
