import arrow from 'assets/images/arrow-right.svg';
import * as GS from 'styles/globalStyles';

const ScrollArrow = ({ lastItem }) => (
  <GS.Container
    width={GS.spacing.sm}
    height={GS.spacing.sm}
    background={GS.colors.darkGreen}
    borderRadius={GS.radius.full}
    alignItems="center"
    justifyContent="center"
    cursor="pointer"
    onClick={() => lastItem.current.scrollIntoView({ behavior: 'smooth', block: 'end' })}
  >
    <GS.Image
      width={GS.spacing.xs2}
      filter="brightness(0) invert(1)"
      transform="rotate(90deg)"
      src={arrow}
    />
  </GS.Container>
);

export default ScrollArrow;
