import { useEffect, useState } from 'react';
import {
  ImageSelector,
  NavigationWrapper,
  Page,
  TargetsUtilsWrapper,
  UiWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';
import moment from 'moment';

import useTarget from 'hooks/useTarget';
import { formatValueNumber } from 'utils/formatNumber';

import { CameraComposed } from './CameraComposed';
import HeaderChat from 'components/common/Header/HeaderChat';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/CustomInput/Input';
import { CalendarModal } from 'components/common/CalendarModal/CalendarModal';

import { ReactComponent as Edit } from 'assets/svgs/pen.svg';
import { ReactComponent as Euro } from 'assets/svgs/euro.svg';
import { ReactComponent as Calendar } from 'assets/svgs/calendar.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const Start = () => {
  const { params, query } = Page.use();

  const { useHeader, useLoading } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const { saveTargetData, saveTargetProperty } = TargetsUtilsWrapper.use();

  const [name, setName] = useState('');
  const [amount, setAmount] = useState(0);
  const [date, setDate] = useState('');
  const [image, setImage] = useState('');
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const [canContinue, setCanContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProps, setLoadingProps] = useState({
    message: 'Guardando datos',
  });

  const target = useTarget(params.targetId);

  const handleChangeAmount = (value) => {
    const selectedAmount = parseInt(value.replace(/\D/g, '')) || 0;
    setAmount(selectedAmount);
  };

  const handleChangeDate = (d) => {
    if (moment(d).isAfter(moment().format('YYYY-MM-DD'))) {
      setDate(moment(d).format('YYYY-MM-DD'));
      setIsCalendarModalOpen(false);
    }
  };

  useEffect(() => {
    if (target) {
      target?.name && setName(target?.name);
      target?.creationData &&
        (setAmount(target?.creationData.price) ||
          setDate(target?.creationData.date) ||
          setImage(target?.image));
    }
  }, [target]);

  useEffect(() => {
    setCanContinue(name && parseInt(amount) && date);
  }, [name, amount, date]);

  useLoading(loading, loadingProps);

  const saveTarget = async () => {
    try {
      setLoading(true);
      await saveTargetData(params.targetId, { name, image });
      await saveTargetProperty(params.targetId, 'creationData', {
        price: amount,
        date,
      });
      setLoading(false);

      if (query.from) {
        goBack();
      } else {
        navigateTo(routes.createTarget, {
          routeParams: {
            section: 'initial',
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useHeader(
    <>
      {query?.from ? (
        <HeaderChat
          text="Resumen"
          onBack={() =>
            navigateTo(routes.target, {
              routeParams: {
                targetId: params.targetId,
                section: 'edit',
              },
              clearItems: ['from'],
            })
          }
        />
      ) : (
        <HeaderChat
          text="Resumen"
          onBack={() => navigateTo(routes.targets, { clearItems: ['from'] })}
        />
      )}
    </>,
    [query]
  );

  return (
    <>
      <GS.Container justifyContent="start" alignItems="center" gap={GS.spacing.xs} grow={1}>
        <Input
          label="¿Cuál es el nombre de tu objetivo?"
          placeholder="Indica un nombre"
          value={name}
          RightAdornment={() => (
            <GS.Container width={GS.spacing.md} alignItems="center" justifyContent="center">
              <SVGIcon Icon={Edit} width="15px" height="auto" color={GS.colors.green} />
            </GS.Container>
          )}
          onChange={(e) => {
            setName(e.target?.value);
          }}
        />
        <Input
          label="¿Cuánto dinero quieres ahorrar?"
          value={formatValueNumber(amount) === '0' ? '' : formatValueNumber(amount)}
          type="text"
          inputMode="numeric"
          placeholder="0"
          RightAdornment={() => (
            <GS.Container width={GS.spacing.md} alignItems="center" justifyContent="center">
              <SVGIcon Icon={Euro} width="12px" height="auto" color={GS.colors.green} />
            </GS.Container>
          )}
          onChange={(e) => handleChangeAmount(e.target.value)}
        />
        <GS.Container alignItems="center" onClick={() => setIsCalendarModalOpen(true)}>
          <Input
            label="¿Cuándo lo quieres?"
            value={date}
            type="date"
            disabled
            placeholder="Añade meses"
            RightAdornment={() => (
              <GS.Container width={GS.spacing.md} alignItems="center" justifyContent="center">
                <SVGIcon Icon={Calendar} width="15px" height="auto" color={GS.colors.green} />
              </GS.Container>
            )}
          />
        </GS.Container>
        {isCalendarModalOpen && (
          <CalendarModal
            value={date === '' ? new Date() : new Date(date)}
            //minDate={new Date(limitDate)}
            onClickDay={handleChangeDate}
            close={() => setIsCalendarModalOpen(false)}
          />
        )}
        <GS.Container gap={GS.spacing.xs2}>
          <GS.Text textLeft fontBold>
            ¿Añadimos una imagen?
          </GS.Text>
          <GS.Container borderRadius={GS.radius.sm} overflow="hidden">
            <ImageSelector
              imageSelectorSt
              value={image || ''}
              captions={{
                buttonLabel: image ? 'Cambiar imagen' : 'Añade una imagen',
                defaultSearch: name,
              }}
              openerComponent={({ open }) => <CameraComposed value={image} onClick={open} />}
              onChange={(value) => {
                setImage(value);
              }}
            />
          </GS.Container>
        </GS.Container>
      </GS.Container>
      <GS.Container alignItems="center">
        <Button
          text={query.from ? 'Editar Datos' : 'Siguiente'}
          maxWidth={GS.screenSizes.xs}
          disabled={!canContinue}
          onClick={() => saveTarget()}
        />
      </GS.Container>
    </>
  );
};

export default Start;
