import { useState } from 'react';
import { UiWrapper, NavigationWrapper } from '@coinscrap/webapp-core';

import faqsData from 'data/faqsData';
import routes from 'config/routes';

import Header from './Header';
import Footer from './Footer';
import Collapse from 'components/common/Collapse';
import FaqItemCollapse from './FaqItemCollapse';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const Faqs = ({ title, section }) => {
  const [collapseOpened, setCollapseOpened] = useState();
  const { useHeader, useFooter } = UiWrapper.use();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  const { navigateTo } = NavigationWrapper.use();

  useHeader(
    <Header title={title}>
      {isDesktop && <Footer backHandler={() => navigateTo(routes.settings)} section={section} />}
    </Header>,
    [isDesktop]
  );

  useFooter(!isDesktop && <Footer section={section} />);

  return (
    <MaxWidthWrapper>
      <GS.Container
        justifyContent="start"
        alignItems="center"
        padding={isDesktop ? `0 0 ${GS.spacing.xs} 0` : `0 ${GS.spacing.xs}`}
        gap={GS.spacing.xs2}
      >
        {faqsData.map((group) => (
          <Collapse
            key={group.id}
            title={group.name}
            setOpened={() => setCollapseOpened(group.id)}
            isOpened={collapseOpened === group.id}
          >
            {group.faqs.map((faq, index) => (
              <FaqItemCollapse key={faq.id} title={faq.name} last={index === group.faqs.length - 1}>
                {faq?.text}
              </FaqItemCollapse>
            ))}
          </Collapse>
        ))}
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default Faqs;
