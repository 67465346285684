import { NavigationWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import getCurrencyDecimals from 'utils/getCurrencyDecimals';
import { getRemainingMonths } from 'utils/getMonths';

import { ReactComponent as Gift } from 'assets/svgs/gift-solid.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { Button } from 'components/common/Button/Button';

import * as GS from 'styles/globalStyles';

const Target = ({ target }) => {
  const { navigateTo } = NavigationWrapper.use();
  return (
    <>
      <GS.Container
        alignItems="center"
        marginTop={GS.spacing.xs}
        padding={GS.spacing.xs}
        background={GS.colors.lightGrey}
        borderRadius={GS.radius.sm}
        gap={GS.spacing.xs}
      >
        <GS.Container
          width="fit-content"
          padding={GS.spacing.xs}
          borderRadius={GS.radius.full}
          background={GS.colors.green}
        >
          <SVGIcon Icon={Gift} width={GS.spacing.sm} height="auto" color={GS.colors.white} />
        </GS.Container>
        <GS.Container gap={GS.spacing.xs2}>
          <GS.Container>
            <GS.Text fontBold>Nombre del objetivo</GS.Text>
            <GS.Text>{target?.name}</GS.Text>
          </GS.Container>
          <GS.Container>
            <GS.Text fontBold>Importe</GS.Text>
            <GS.Text>{getCurrencyDecimals(target?.progress.total)} €</GS.Text>
          </GS.Container>
          <GS.Container>
            <GS.Text fontBold>Tiempo</GS.Text>
            <GS.Text>{getRemainingMonths(target) + ' meses'}</GS.Text>
          </GS.Container>
        </GS.Container>
      </GS.Container>
      <GS.Container alignItems="center" paddingBottom={GS.spacing.xs}>
        <Button
          text="Editar Datos"
          buttonStyle="minimal"
          width={GS.spacing.xl7}
          onClick={() =>
            navigateTo(routes.createTarget, {
              routeParams: {
                section: 'start',
                targetId: target?.id,
                step: undefined,
              },
              queryParams: {
                from: {
                  name: routes.target,
                },
              },
            })
          }
        />
      </GS.Container>
    </>
  );
};

export default Target;
