import { NavigationWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import { ReactComponent as Edit } from 'assets/svgs/pen.svg';
import { ReactComponent as Bank } from 'assets/svgs/bank.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { FooterGridItem } from 'components/common/FooterGridItem/FooterGridItem';

import * as GS from 'styles/globalStyles';

const Footer = ({ targetId, section }) => {
  const { navigateTo } = NavigationWrapper.use();

  return (
    <GS.Container alignItems="center" background={GS.colors.green} padding={GS.spacing.xs3}>
      <GS.Container maxWidth={GS.screenSizes.sm} height="100%">
        <GS.Grid numberOfColumns={2} alignItems="center" height="100%">
          <FooterGridItem
            text="Editar"
            active={section === 'edit'}
            onClick={() =>
              navigateTo(routes.target, {
                routeParams: {
                  targetId,
                  section: 'edit',
                },
              })
            }
          >
            <SVGIcon Icon={Edit} width="20px" height="auto" color={GS.colors.white} />
          </FooterGridItem>
          <FooterGridItem
            text="Bancos"
            active={section === 'editBank'}
            onClick={() =>
              navigateTo(routes.target, {
                routeParams: {
                  targetId,
                  section: 'editBank',
                },
              })
            }
          >
            <SVGIcon Icon={Bank} width="20px" height="auto" color={GS.colors.white} />
          </FooterGridItem>
        </GS.Grid>
      </GS.Container>
    </GS.Container>
  );
};

export default Footer;
