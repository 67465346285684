import styled, { css } from 'styled-components/macro';
import * as GS from '../../../styles/globalStyles';

const filled = css`
  color: transparent;
`;

export const Input = styled.input`
  border: none;
  color: ${({ color }) => color || GS.colors.grey};
  font-family: inherit;
  font-size: ${GS.fontSizes.md};
  outline: none;
  text-align: ${({ textLeft }) => (textLeft ? 'left' : 'center')};
  transition: all 0.3s;
  width: 100%;
  ${(props) => props.value && GS.colors.white}
  &::placeholder {
    color: ${GS.colors.greyMidTransparent};
    font-family: inherit;
    font-size: ${GS.fontSizes.md};
    font-weight: ${GS.fontWeights.medium};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${GS.colors.white} inset !important;
    -webkit-text-fill-color: ${GS.colors.grey} !important;
  }
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

export const LabelText = styled(GS.Text)`
  color: ${({ color }) => color || GS.colors.whiteMidTransparent};
  font-size: ${GS.fontSizes.xs};
  padding-top: ${GS.spacing.xxxs};
`;

export const InputWrapper = styled(GS.Container)`
  align-items: center;
  background: ${GS.colors.white};
  border-radius: ${GS.radius.sm};
  flex-direction: row;
  ${({ border }) => border && border}
  padding: ${({ padding }) => (padding ? `${padding}` : '0')};
  transition: all 0.3s;
  ${(props) => props.value && filled}
`;

export const Image = styled(GS.Image)`
  filter: ${({ filter }) => (filter ? 'brightness(100%) invert(0)' : 'brightness(0) invert(1)')};
`;
