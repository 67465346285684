import styled from 'styled-components/macro';
import * as GS from 'styles/globalStyles';

export const ModalContainer = styled.div`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor || GS.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: ${({ justifyContent }) => justifyContent || 'center'};
  max-width: calc(100% - ${GS.spacing.xs});
  padding: 0.5em 0 0.5em 0;
  width: 100%;

  @media (min-width: 640px) {
    max-width: calc(100% - ${GS.spacing.sm});
    padding: 1em 0 1em 0;
  }

  @media (min-width: 768px) {
    max-width: calc(100% - ${GS.spacing.md});
    padding: 1.5em 0 1.5em 0;
  }

  @media (min-width: 1024px) {
    max-width: calc(100% - ${GS.spacing.lg});
    padding: 2em 0 2em 0;
  }
`;
