import { Page } from '@coinscrap/webapp-core';
import { rulesData } from 'data/rulesData';

import Initial from './components/Initial';
import Start from './components/Start';
import Rule from './components/Rule';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const CreateTarget = () => {
  const { params } = Page.use();

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="start"
        padding={GS.spacing.xs}
        gap={GS.spacing.xs}
      >
        {params.section === 'start' && <Start />}
        {params.section === 'initial' && <Initial />}
        {rulesData.map(
          (r, i) =>
            params.section === 'rule' &&
            params.step === r.identifier && <Rule key={r.identifier} ruleNumber={i} />
        )}
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default CreateTarget;
