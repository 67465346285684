import styled, { css, keyframes } from 'styled-components/macro';
import { TextField } from '@material-ui/core';
import * as GS from 'styles/globalStyles';

const slideIn = keyframes`
 from {
    max-height: 0px;
    transform: scale(0);
    opacity: 0;
  }
  to {
    max-height: 50px; 
    transform: scale(1);
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  align-items: center;
  animation: ${(props) =>
    props.animated
      ? css`
          ${slideIn} 0.32s forwards
        `
      : 'none'};
  border: 1px solid ${GS.colors.green};
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const Input = styled(TextField)`
  & label {
    color: ${GS.colors.green};
    font-family: 'Mutualidad', sans-serif;
  }
  & .MuiInputBase-input {
    color: ${GS.colors.darkGreen};
    font-family: 'Mutualidad', sans-serif;
  }
  &.MuiFormControl-root {
    background-color: ${GS.colors.white};
    width: 100%;
  }
  & .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    width: 100%;
    border: 0px;
  }
  & .MuiOutlinedInput-input {
    border-radius: 4px;
    color: ${GS.colors.green};
    font-family: 'Mutualidad', sans-serif;
    font-weight: bold;
    margin-right: ${(props) => (props.eye ? '50px ' : 'initial')};
    padding: ${GS.spacing.xs} ${GS.spacing.sm};
    padding-left: ${(props) => (props.type === 'search' ? '50px ' : '20px')};
  }
  & .MuiInputLabel-underline:after {
    border-bottom: 2px solid ${GS.colors.green};
  }
`;

export const SearchIcon = styled.img`
  left: 13px;
  position: absolute;
`;

export const EyeIcon = styled.img`
  fill: ${GS.colors.green};
  position: absolute;
  right: 15px;
  width: 20px;
`;
export const CurrencyIcon = styled.span`
  position: absolute;
  right: 15px;
  width: 20px;
`;
