import { NavigationWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import { ReactComponent as Chart } from 'assets/svgs/chart.svg';
import { ReactComponent as File } from 'assets/svgs/file.svg';
import { ReactComponent as Ruler } from 'assets/svgs/ruler.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { FooterGridItem } from '../../../common/FooterGridItem/FooterGridItem';

import * as GS from 'styles/globalStyles';

const Footer = ({ section, targetId }) => {
  const { navigateTo } = NavigationWrapper.use();

  return (
    <GS.Container alignItems="center" background={GS.colors.green} padding={GS.spacing.xs3}>
      <GS.Container maxWidth={GS.screenSizes.sm} height="100%">
        <GS.Grid numberOfColumns={3} alignItems="center" height="100%">
          <FooterGridItem
            text="Gráficas"
            active={section === 'graph'}
            onClick={() =>
              section !== 'graph'
                ? navigateTo(routes.target, { routeParams: { targetId, section: 'graph' } })
                : null
            }
          >
            <SVGIcon Icon={Chart} width="20px" height="auto" color={GS.colors.white} />
          </FooterGridItem>
          <FooterGridItem
            text="Movimientos"
            active={section === 'movements'}
            onClick={() =>
              section !== 'movements'
                ? navigateTo(routes.target, { routeParams: { targetId, section: 'movements' } })
                : null
            }
          >
            <SVGIcon Icon={File} width="18px" height="auto" color={GS.colors.white} />
          </FooterGridItem>
          <FooterGridItem
            text="Reglas activas"
            active={section === 'rules'}
            onClick={() =>
              section !== 'rules'
                ? navigateTo(routes.target, { routeParams: { targetId, section: 'rules' } })
                : null
            }
          >
            <SVGIcon Icon={Ruler} width="24px" height="auto" color={GS.colors.white} />
          </FooterGridItem>
        </GS.Grid>
      </GS.Container>
    </GS.Container>
  );
};

export default Footer;
