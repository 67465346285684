import * as GS from 'styles/globalStyles';

const showDots = (id, lenContentPage) => {
  const a = [];
  for (let index = 1; index <= lenContentPage; index++) {
    a.push(
      <GS.Container
        key={index}
        width={GS.spacing.xs}
        height={GS.spacing.xs}
        borderRadius={GS.radius.full}
        background={index <= id ? GS.colors.green : GS.colors.lightGrey}
      />
    );
  }
  return a;
};

export const TourContent = ({ ItemContentPage, lenContentPage, children }) => (
  <GS.Container alignItems="center" grow={1} padding={GS.spacing.xs} gap={GS.spacing.xs}>
    <GS.Container grow={1} gap={GS.spacing.xs}>
      <GS.RowContainer justifyContent="center" gap={GS.spacing.xs3}>
        {showDots(ItemContentPage.id, lenContentPage)}
      </GS.RowContainer>
      <GS.Title Rufina color={GS.colors.green}>
        {ItemContentPage.pretitle}
      </GS.Title>
      <GS.Text>{ItemContentPage.text}</GS.Text>
    </GS.Container>
    <GS.Container>{children}</GS.Container>
  </GS.Container>
);
