import { Slider as CoreSlider, PickerHOC, TargetsWrapper } from '@coinscrap/webapp-core';
import { useState } from 'react';

import getCurrencyDecimals from 'utils/getCurrencyDecimals';

import { ReactComponent as Caret } from 'assets/svgs/caret.svg';
import { ReactComponent as Euro } from 'assets/svgs/euro.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';
import { Dots } from 'components/common/Dots/Dots';

const Slide = PickerHOC(
  {
    userTargets: {
      sourceWrapper: TargetsWrapper,
      sourcePath: 'targets',
      arrayItemFilter: {
        itemPropertyPath: 'level',
        type: 'value',
        value: 1,
      },
    },
  },
  () => (
    <GS.Container alignItems="center" gap={GS.spacing.xs}>
      <GS.Container
        alignItems="center"
        justifyContent="center"
        background={GS.colors.green}
        width={GS.spacing.lg}
        height={GS.spacing.lg}
        borderRadius={GS.radius.full}
      >
        <SVGIcon
          Icon={Euro}
          width={GS.spacing.sm}
          height={GS.spacing.sm}
          color={GS.colors.white}
          marginRight={GS.spacing.xs3}
        />
      </GS.Container>
      <GS.Text>Obteniendo datos</GS.Text>
    </GS.Container>
  )
)(({ info }) => {
  const { userTargets } = PickerHOC.use();
  console.log('userTargets', userTargets);
  const value = userTargets.reduce(
    (acc, act) =>
      acc + (info === 'accum' ? act.progress?.completed || 0 : act.nextSaving?.amount || 0),
    0
  );
  return (
    <GS.Container alignItems="center" gap={GS.spacing.xs}>
      <GS.Container
        alignItems="center"
        justifyContent="center"
        background={GS.colors.green}
        width={GS.spacing.lg}
        height={GS.spacing.lg}
        borderRadius={GS.radius.full}
      >
        <SVGIcon
          Icon={Euro}
          width={GS.spacing.sm}
          height={GS.spacing.sm}
          color={GS.colors.white}
          marginRight={GS.spacing.xs3}
        />
      </GS.Container>
      <GS.Container alignItems="center">
        <GS.Title Rufina fontSize={GS.fontSizes.md}>
          {info === 'accum' ? 'Ahorro total acumulado' : 'Cifra pendiente'}
        </GS.Title>
        <GS.Title Rufina fontSize={GS.fontSizes.xl4}>
          {getCurrencyDecimals(value, 2, true)}
        </GS.Title>
      </GS.Container>
    </GS.Container>
  );
});

const Slider = () => {
  const [index, setIndex] = useState(0);

  const handleChangeIndex = (i) => {
    setIndex(i);
  };

  return (
    <GS.RowContainer
      justifyContent="center"
      alignItems="center"
      background={GS.colors.lightGrey}
      borderRadius={GS.radius.sm}
      grow={1}
      padding={GS.spacing.xs}
    >
      <GS.Container
        width="fit-content"
        padding={GS.spacing.xs}
        onClick={() => index > 0 && setIndex((i) => i - 1)}
        cursor={index > 0 ? 'pointer' : 'default'}
      >
        <SVGIcon
          Icon={Caret}
          width={GS.spacing.xs2}
          height="auto"
          color={index === 0 ? GS.colors.lightGreen : GS.colors.green}
          transform="rotate(180deg)"
        />
      </GS.Container>
      <GS.Container width={`calc(100% - ${GS.spacing.md})`} height="100%" gap={GS.spacing.xs}>
        <GS.Container alignItems="center" justifyContent="center" grow={1}>
          <CoreSlider index={index} onChangeIndex={handleChangeIndex}>
            <Slide info="accum" />
            <Slide info="pending" />
          </CoreSlider>
        </GS.Container>
        <Dots number={2} active={index} width={GS.spacing.lg} height={GS.spacing.xs3} />
      </GS.Container>
      <GS.Container
        width="fit-content"
        padding={GS.spacing.xs}
        onClick={() => index < 1 && setIndex((i) => i + 1)}
        cursor={index < 1 ? 'pointer' : 'default'}
      >
        <SVGIcon
          Icon={Caret}
          width={GS.spacing.xs2}
          height="auto"
          color={index > 0 ? GS.colors.lightGreen : GS.colors.green}
        />
      </GS.Container>
    </GS.RowContainer>
  );
};

export default Slider;
