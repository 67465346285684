import { NavigationWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

export const SettingsItem = ({ data }) => {
  const { navigateTo } = NavigationWrapper.use();
  return (
    <GS.RowContainer
      alignItems="center"
      justifyContent="space-between"
      background={GS.colors.lightGrey}
      borderRadius={GS.radius.sm}
      padding={GS.spacing.xs}
      cursor="pointer"
      onClick={() => navigateTo(routes.setting, { routeParams: { section: data.section } })}
    >
      <GS.Text textLeft fontBold>
        {data.name}
      </GS.Text>
      <SVGIcon
        Icon={Angle}
        width={GS.spacing.xs}
        height="auto"
        color={GS.colors.green}
        transform="rotate(270deg)"
      />
    </GS.RowContainer>
  );
};
