import { UiWrapper } from '@coinscrap/webapp-core';
import colors from 'config/colors';
import fonts from 'config/fonts';
import commonStyles from './commonStyles';

UiWrapper.defineComponent('button', {
  styles: {
    default: {
      padding: '10px 0',
      textComponent: {
        color: colors.white,
        fontSize: 16,
        fontFamily: fonts.GothamBook,
      },
      disabledMode: {
        opacity: 0.5,
      },
    },
    onBoardingSt: {
      border: 0,
      backgroundColor: colors.red,
      width: '200px',
      clipPath: 'polygon(0 0, 90% 0, 100% 100%, 10% 100%)',
      padding: 16,
      textComponent: {
        color: colors.white,
        fontSize: 16,
        fontStyle: 'italic',
        fontFamily: fonts.GothamBold,
      },
      disabledMode: {
        opacity: 0.5,
      },
    },
    noClipPathWithShadowSt: {
      clipPath: 'none',
      boxShadow: '-3px 3px 8px #999',
    },

    // TODO: reemplazar mainButtonSt por primarySt
    mainButtonSt: {
      width: 230,
      padding: '13px',
      backgroundColor: colors.red,
      boxShadow: `-5px 5px 7px ${colors.grey}`,
      border: 'none',
      marginTop: 8,
      marginBottom: 4,
    },

    modalButtonSt: {
      width: '100%',
      padding: '13px',
      backgroundColor: colors.red,
      border: 'none',
    },

    primarySt: {
      width: 230,
      padding: '13px',
      backgroundColor: colors.red,
      boxShadow: `#9e9e9e 0px 4px 7px`,
      border: 'none',
      marginTop: 8,
      marginBottom: 4,
      textComponent: {
        color: colors.white,
        fontSize: 16,
        fontStyle: 'italic',
        fontFamily: fonts.GothamBold,
      },
    },

    secondarySt: {
      width: 230,
      padding: '13px',
      backgroundColor: colors.green,
      boxShadow: `#9e9e9e 0px 4px 7px`,
      border: 'none',
      textComponent: {
        color: colors.white,
        fontSize: 16,
        fontStyle: 'italic',
        fontFamily: fonts.GothamBold,
      },
    },

    redButtonNextSt: {
      border: 0,
      width: '100%',
      backgroundColor: colors.red,
      padding: '20px 0',
      textComponent: {
        color: colors.white,
        fontFamily: fonts.GothamBold,
        fontStyle: 'italic',
        fontSize: 16,
      },
      disabledMode: {
        backgroundColor: '#d4866b',
      },
    },

    nicotineCafeineButtonSt: {
      border: `2px solid ${colors.red}`,
      padding: 5,
      backgroundColor: colors.white,
      textComponent: {
        fontSize: 13,
        fontFamily: fonts.GothamBold,
        color: colors.red,
      },
    },
    ...commonStyles.styles,
  },
});
