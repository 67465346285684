import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('inputSlider', {
  styles: {
    default: {
      containerComponent: {
        width: 'calc(100% - 15px)',
      },
      railComponent: {
        overflow: 'hidden',
      },
      trackComponent: {
        height: 20,
        overflow: 'hidden',
      },
      thumbComponent: {
        width: 12,
        height: 12,
        marginTop: 4,
      },
      markComponent: {
        overflow: 'hidden',
        height: 20,
      },
    },
  },
});
