/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import * as GS from '../../../styles/globalStyles';
import * as S from './styles';

import { ReactComponent as CloseIcon } from './close.svg';
import { SVGIcon } from '../SVGIcon/SVGIcon';

export const CloseButton = ({ color, onClick }) => (
  <S.CloseContainer onClick={onClick} color={color}>
    <SVGIcon Icon={CloseIcon} width="10px" height="auto" color={GS.colors.white} />
  </S.CloseContainer>
);
