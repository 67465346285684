import {
  Page,
  UiWrapper,
  TargetsWrapper,
  RulesWrapper,
  RuleDefinitionsWrapper,
} from '@coinscrap/webapp-core';

import { rulesData } from 'data/rulesData';
import getCurrencyDecimals from 'utils/getCurrencyDecimals';

import Header from './components/Header';
import TargetItem from './components/TargetItem';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';
import Footer from 'components/common/Footer/Footer';

const Rule = () => {
  const { params } = Page.use();
  const { useHeader, useFooter } = UiWrapper.use();
  const { matched } = UiWrapper.use();
  const { targets } = TargetsWrapper.use();
  const { getRule } = RulesWrapper.use();
  const { ruleDefinitions } = RuleDefinitionsWrapper.use();

  const isDesktop = matched.isDesktopMq;

  const currentRuleData = rulesData?.find((r) => r.identifier === params.ruleName);
  const currentRuleDefinition = ruleDefinitions?.find(
    (r) => r.identifier.toLowerCase() === params.ruleName
  );
  const currentRule = { ...currentRuleData, ...currentRuleDefinition };

  const currentTargets = targets?.filter((t) => t.level === 1 && t.status === 'ACTIVE');
  const currentTargetsWithRule = currentTargets?.map((t) => ({
    ...t,
    rule: { ...getRule(params.ruleName.toUpperCase(), t.id), ...currentRule },
  }));

  //Para calcular el total ahorrado por la regla concreta que estamos viendo
  const totalRuleAmount = currentTargetsWithRule?.reduce(
    (acc, t) => (t.nextSaving?.ruleDetails[params.ruleName.toUpperCase()]?.amount || 0) + acc,
    0
  );

  useHeader(<Header title={currentRule?.name}>{isDesktop && <Footer location="rules" />}</Header>, [
    isDesktop,
  ]);
  useFooter(!isDesktop && <Footer location="rules" />, [isDesktop]);

  return (
    <MaxWidthWrapper>
      {currentRule?.imageBg && (
        <GS.Container
          position="relative"
          width={isDesktop ? '100%' : `calc(100% - ${GS.spacing.sm})`}
          height={isDesktop ? '30%' : '40%'}
          maxHeight={isDesktop ? '270px' : '300px'}
          margin={isDesktop ? 0 : `0 ${GS.spacing.xs}`}
          borderRadius={GS.radius.sm}
          overflow="hidden"
        >
          <GS.Container
            position="absolute"
            width="100%"
            height="100%"
            background={GS.colors.darkGreen}
            opacity={0.5}
            zIndex={1}
          />
          <GS.Image
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            src={isDesktop ? currentRule.imageBgDesktop : currentRule.imageBg}
            alt="hero"
          />
        </GS.Container>
      )}
      <GS.Container
        maxWidth={isDesktop ? '100%' : `calc(100% - ${GS.spacing.sm})`}
        margin={
          isDesktop ? `${GS.spacing.xs} 0` : `${GS.spacing.xs} ${GS.spacing.xs} 0 ${GS.spacing.xs}`
        }
        padding={GS.spacing.xs}
        background={GS.colors.lightGrey}
        borderRadius={GS.radius.sm}
      >
        <GS.Container gap={GS.spacing.xs}>
          <GS.Container gap={GS.spacing.xs}>
            {currentRule?.description?.map((item) => (
              <GS.Text key={item.id} textLeft>
                {item}
              </GS.Text>
            ))}
          </GS.Container>
          <GS.Title Rufina fontBold fontSize={GS.fontSizes.xl4} paddingBottom={GS.spacing.xs}>
            {getCurrencyDecimals(totalRuleAmount || 0)} €
          </GS.Title>
        </GS.Container>
        {currentTargetsWithRule?.map((target, index) => (
          <TargetItem
            key={target?.id}
            target={target}
            last={index === currentTargetsWithRule.length - 1}
          />
        ))}
      </GS.Container>
      <GS.Container paddingBottom={GS.spacing.xs} />
    </MaxWidthWrapper>
  );
};

export default Rule;
