import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';

UiWrapper.defineComponent('loading', {
  styles: {
    default: {
      loaderColor: colors.main,
    },
  },
});
