import { RuleDefinitionsWrapper, RulesWrapper } from '@coinscrap/webapp-core';
import { rulesData } from 'data/rulesData';
import { mergeRulesById, mergeRulesByIdentifier } from 'utils/mergeRules';

const useTargetRules = (targetId) => {
  const { rules } = RulesWrapper.use();
  const { ruleDefinitions } = RuleDefinitionsWrapper.use();
  const targetRules = rules?.filter((rule) => rule.targetId === targetId);

  const mergedRules = mergeRulesById(ruleDefinitions, targetRules);

  const result = mergeRulesByIdentifier(rulesData, mergedRules);
  return result;
};

export default useTargetRules;
