import { UiWrapper } from '@coinscrap/webapp-core';
import Footer from 'components/common/Footer/Footer';
import Header from 'components/common/Header/Header';
import { rulesData } from 'data/rulesData';
import ItemRule from './components/RulesItem';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const Rules = () => {
  const { useHeader, useFooter } = UiWrapper.use();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  useHeader(isDesktop ? <Footer location="rules" /> : <Header title="Mis reglas de ahorro" />, [
    isDesktop,
  ]);
  useFooter(!isDesktop && <Footer location="rules" />, [isDesktop]);

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="start"
        alignItems="center"
        padding={isDesktop ? `${GS.spacing.xs} 0 0 0` : `0 ${GS.spacing.xs}`}
      >
        <GS.Grid width="100%" numberOfColumns={2} gap={GS.spacing.xs2}>
          {rulesData.map((rule) => (
            <ItemRule key={rule.name} name={rule.name} icon={rule.icon} rule={rule} />
          ))}
        </GS.Grid>
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default Rules;
