import { UiWrapper } from '@coinscrap/webapp-core';
import * as GS from 'styles/globalStyles';

UiWrapper.defineComponent('modal', {
  styles: {
    default: {
      backgroundComponent: {
        backgroundColor: GS.colors.blackMidTransparent,
      },
      contentComponent: {
        // TODO: Maquetar modales
        backgroundColor: 'transparent', //colors.grey,
        padding: GS.spacing.xs,
      },
    },
  },
});
