import React, { useEffect, useState } from 'react';

import {
  UiWrapper,
  Slider,
  PickerHOC,
  NavigationWrapper,
  InstitutionsWrapper,
  InstitutionAccountsWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import HeaderChat from 'components/common/Header/HeaderChat';
import { FooterBackNext } from 'components/common/FooterBackNext';
import FooterNext from 'components/common/FooterNext';
import { BankCard } from './components/BankCard';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import { Input } from 'components/common/MainInput/styles';

import * as GS from 'styles/globalStyles';

export const BanksSelect = PickerHOC(
  {
    institutions: {
      sourcePath: 'institutions',
      sourceWrapper: InstitutionsWrapper,
    },
    userBanks: {
      sourcePath: 'institutionAccounts',
      sourceWrapper: InstitutionAccountsWrapper,
    },
  },
  () => 'Loading institutions ...'
)(() => {
  const { institutions } = PickerHOC.use();
  const [permitedInstitutions, setPermitedInstitutions] = useState(false);
  const [filteredListInstitutions, setFilteredListInstitutions] = useState(false)
  const defaultBanksIdentifiers = [
    'santander',
    'bbva',
    'sabadell',
    'caixa',
    'bankinter',
    'abanca',
    'evobanco',
  ];
  // console.log('----institutions ---->', institutions, userBanks);

  const { navigateTo } = NavigationWrapper.use();
  const { useBackground, useHeader, useFooter } = UiWrapper.use();

  const [selectedBank, setSelectedBank] = useState('');
  const [more, setMore] = useState(false);
  const [search, setSearch] = useState('');
  //const { matched } = UiWrapper.use();
  //const isDesktop = matched.isDesktopMq;

  const backToBanksStart = () => {
    setSearch('');
    setMore(false);
  };

  useEffect(() => {
    if (institutions) {
      const filterPermitedInstitutions = institutions.filter(
        (itemFilter) =>
          itemFilter.identifier !== 'mabogacia' && itemFilter.identifier !== 'mock-institution'
      );
      setPermitedInstitutions(filterPermitedInstitutions);
    }
  }, [institutions]);

  useEffect(() => {
    if (search.length > 2) {
      const filteredInstitutions =
        permitedInstitutions &&
        permitedInstitutions.filter((institution) =>
          institution.name.toLowerCase().includes(search.toLowerCase())
        );
      setFilteredListInstitutions(filteredInstitutions);
    } else {
      const filteredInstitutions =
        permitedInstitutions &&
        permitedInstitutions.filter((institution) =>
          defaultBanksIdentifiers.includes(institution.identifier)
        );
      setFilteredListInstitutions(filteredInstitutions);
    }
  }, [permitedInstitutions, search]);

  useBackground('bgDark');
  useHeader(
    <HeaderChat text="Bancos" onBack={() => navigateTo(routes.banksStart, { mode: 'replace' })} />
  );
  useFooter(
    <>
      {more ? (
        <FooterBackNext
          onBack={backToBanksStart}
          onClick={() => {
            navigateTo(routes.banksConnect, {
              routeParams: {
                idBank: selectedBank,
              },
            });
          }}
          disabled={selectedBank === ''}
        />
      ) : (
        <FooterNext
          onClick={() => {
            navigateTo(routes.banksConnect, {
              routeParams: {
                idBank: selectedBank,
              },
            });
          }}
          disabled={selectedBank === ''}
        />
      )}
    </>,
    [more, selectedBank]
  );

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="start"
        alignItems="center"
        padding={GS.spacing.xs}
        overflow="hidden"
      >
        <GS.Container grow={1} gap={GS.spacing.xs}>
          <GS.Text fontBold fontSize={GS.fontSizes.lg}>
            Selecciona tu banco
          </GS.Text>
          <Slider disabled index={more ? 1 : 0}>
            <GS.Container height={`calc(100vh - ${GS.spacing.xl8})`} overflowY position="relative">
              <GS.Grid numberOfColumns={2} alignItems="unset">
                {filteredListInstitutions &&
                  filteredListInstitutions.map((item) => (
                    <BankCard
                      key={item.identifier}
                      institution={item}
                      selected={item.identifier === selectedBank}
                      onClick={() => setSelectedBank(item.identifier)}
                    />
                  ))}

                <GS.Container
                  alignItems="center"
                  justifyContent="center"
                  background={GS.colors.lightGreen}
                  onClick={() => setMore(true)}
                >
                  <GS.Text fontBold color={GS.colors.white}>
                    + Más bancos
                  </GS.Text>
                </GS.Container>
              </GS.Grid>
            </GS.Container>

            <>
              <GS.Container paddingBottom={GS.spacing.xs}>
                <Input
                  label="Buscar banco"
                  value={search}
                  type="text"
                  placeholder="Escribe al menos 3 caracteres"
                  onChange={(e) => setSearch(e.target?.value)}
                />
              </GS.Container>

              <GS.Container
                height={`calc(100vh - ${GS.spacing.xl12})`}
                overflowY
                position="relative"
              >
                <GS.Grid numberOfColumns={2}>
                  {search.length > 2 &&
                    filteredListInstitutions &&
                    filteredListInstitutions.map((item) => (
                      <BankCard
                        key={item.identifier}
                        institution={item}
                        selected={item.identifier === selectedBank}
                        onClick={() => setSelectedBank(item.identifier)}
                      />
                    ))}
                </GS.Grid>
              </GS.Container>
            </>
          </Slider>
        </GS.Container>
      </GS.Container>
    </MaxWidthWrapper>
  );
});
