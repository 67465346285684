import { UiWrapper, NavigationWrapper } from '@coinscrap/webapp-core';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import routes from 'config/routes';
import useInstitutionAccounts from 'hooks/useInstitutionAccounts';
import { useInstitutions } from 'hooks/useInstitutions';

import Footer from './Footer';
import Header from './Header';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import BanksCollapse from 'components/common/BanksCollapse';
import BankItem from 'components/common/BankItem/BankItem';
import Scrollable from 'components/common/Scrollable/Scrollable';
import { Spinner } from 'components/common/Spinner/Spinner';
import { Button } from 'components/common/Button/Button';

import * as GS from 'styles/globalStyles';

export const BanksAndAccounts = ({ title, section }) => {
  const { useHeader, useFooter, useLoading } = UiWrapper.use();
  const { data: institutions } = useInstitutions();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  const { navigateTo } = NavigationWrapper.use();

  const [isLoading, setIsLoading] = useState(true);

  const banks = useInstitutionAccounts();
  const [bankList, setBanksList] = useState(undefined);

  useEffect(() => {
    if (!bankList && banks?.length > 0) {
      setBanksList(banks);
      setIsLoading(false);
    } else if (banks?.length === 0) {
      setBanksList([]);
      setIsLoading(false);
    }
  }, [banks]);

  const getInstitution = useCallback(
    (id) => institutions?.find((i) => i.identifier === id),
    [institutions?.length]
  );

  useLoading(false);

  useHeader(
    <Header title={title}>
      {isDesktop && (
        <Footer
          backHandler={() => navigateTo(routes.settings)}
          section={section}
        />
      )}
    </Header>,
    [isDesktop]
  );

  useFooter(!isDesktop && <Footer section={section} />);

  return (
    <MaxWidthWrapper>
      <GS.Container
        position="relative"
        height="100%"
        justifyContent="start"
        alignItems="center"
        padding={isDesktop ? 0 : `0 ${GS.spacing.xs}`}
        gap={GS.spacing.xs}
      >
        <GS.Container
          grow={1}
          height={isLoading ? '100%' : 'auto'}
          gap={GS.spacing.xs2}
        >
          {isLoading && (
            <GS.Container
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner size={GS.spacing.xsm} />
            </GS.Container>
          )}
          {!isLoading && bankList?.length === 0 && (
            <GS.Container
              height="100%"
              justifyContent="center"
              alignItems="center"
              padding={GS.spacing.xs}
            >
              <GS.Container grow={1} justifyContent="center">
                <GS.Text>No has añadido bancos.</GS.Text>
              </GS.Container>
            </GS.Container>
          )}
          {!isLoading && bankList?.length > 0 && (
            <Scrollable arrowBottom={isDesktop}>
              <GS.Container gap={GS.spacing.xs2}>
                {bankList.map((bank) => (
                  <BanksCollapse
                    key={bank?.name}
                    logo={
                      bank.institutionIdentifier
                        ? getInstitution(bank.institutionIdentifier)?.logo ||
                          bank?.image
                        : bank?.image
                    }
                    title={bank?.name}
                    updated={`Actualizado ${moment(
                      (bank.products || [])[0]?.lastImportDate
                    )
                      .locale('es')
                      .fromNow()}`}
                  >
                    {bank?.products
                      ?.filter((account) => account.isOwner === true)
                      .map((account) => (
                        <BankItem
                          key={account?.name}
                          type={account?.type}
                          hideSwitch
                          name={account?.name}
                          number={`**** ${account?.identifier.slice(-4)}`}
                          isActive
                        />
                      ))}
                  </BanksCollapse>
                ))}
              </GS.Container>
            </Scrollable>
          )}
        </GS.Container>
        {!isLoading && (
          <GS.Container alignItems="center" paddingBottom={GS.spacing.xs}>
            <Button
              text="Añadir nuevo banco"
              buttonStyle="minimal"
              width={GS.spacing.xl7}
              onClick={() => {
                navigateTo(routes.banksStart, {
                  queryParams: {
                    from: {
                      name: routes.setting,
                      routeParams: {
                        section: 'banksAndAccounts',
                      },
                    },
                  },
                });
              }}
            />
          </GS.Container>
        )}
      </GS.Container>
    </MaxWidthWrapper>
  );
};
