import { UiWrapper } from '@coinscrap/webapp-core';
import Footer from 'components/common/Footer/Footer';
import Header from 'components/common/Header/Header';

import { SettingsItem } from './SettingsItem';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const settingsData = [
  {
    name: 'Bancos y cuentas',
    section: 'banksAndAccounts',
  },
  {
    name: 'Histórico',
    section: 'historic',
  },
  {
    name: "FAQ'S",
    section: 'faqs',
  },
];

const Settings = () => {
  const { useHeader, useFooter } = UiWrapper.use();

  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  useHeader(isDesktop ? <Footer location="settings" /> : <Header title="Mis ajustes" />, [
    isDesktop,
  ]);
  useFooter(!isDesktop && <Footer location="settings" />, [isDesktop]);

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="start"
        alignItems="center"
        padding={isDesktop ? `${GS.spacing.xs} 0` : `0 ${GS.spacing.xs}`}
        gap={GS.spacing.xs3}
      >
        {settingsData.map((item) => (
          <SettingsItem key={item.name} data={item} />
        ))}
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default Settings;
