import React from 'react';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from '../../../styles/globalStyles';

const TokenError = () => (
  <MaxWidthWrapper>
    <GS.Container height="100%" justifyContent="center" alignItems="center" padding={GS.spacing.xs}>
      <GS.Container gap={GS.spacing.xs}>
        <GS.Title Rufina color={GS.colors.green}>
          ¡No sabemos quien eres!
        </GS.Title>
        <GS.Text>
          Por alguna razón no hemos podido validar tus datos de sesión. Por favor inténtelo de nuevo
          más tarde.
        </GS.Text>
      </GS.Container>
    </GS.Container>
  </MaxWidthWrapper>
);

export default TokenError;
