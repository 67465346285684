export const teams = [
  { label: 'Athletic', id: 'Athletic Club', active: true },
  { label: 'Atleti', id: 'Club Atlético de Madrid', active: true },
  { label: 'Osasuna', id: 'CA Osasuna', active: true },
  { label: 'Espanyol', id: 'RCD Espanyol de Barcelona', active: true },
  { label: 'Barça', id: 'FC Barcelona', active: true },
  { label: 'Getafe', id: 'Getafe CF', active: true },
  { label: 'Real Madrid', id: 'Real Madrid CF', active: true },
  { label: 'Rayo Vallecano', id: 'Rayo Vallecano de Madrid', active: true },
  { label: 'Mallorca', id: 'RCD Mallorca', active: true },
  { label: 'Real Betis', id: 'Real Betis Balompié', active: true },
  { label: 'Real Sociedad', id: 'Real Sociedad de Fútbol', active: true },
  { label: 'Villarreal', id: 'Villarreal CF', active: true },
  { label: 'Valencia', id: 'Valencia CF', active: true },
  { label: 'Real Valladolid', id: 'Real Valladolid CF', active: true },
  { label: 'Alavés', id: 'Deportivo Alavés', active: true },
  { label: 'Las Palmas', id: 'UD Las Palmas', active: true },
  { label: 'Girona', id: 'Girona FC', active: true },
  { label: 'Celta', id: 'RC Celta de Vigo', active: true },
  { label: 'Sevilla FC', id: 'Sevilla FC', active: true },
  { label: 'CD Leganés', id: 'CD Leganés', active: true }
];
