import { NavigationWrapper, UiWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import { ReactComponent as Euro } from 'assets/svgs/euro-circle.svg';
import { ReactComponent as Clock } from 'assets/svgs/clock-circle.svg';
import { ReactComponent as Sack } from 'assets/svgs/sack-circle.svg';
import { ReactComponent as Mug } from 'assets/svgs/mug-circle.svg';
import { ReactComponent as Smoking } from 'assets/svgs/smoking-circle.svg';
import { ReactComponent as Football } from 'assets/svgs/football-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const ItemRule = ({ name, icon, rule }) => {
  const { navigateTo } = NavigationWrapper.use();

  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  const Icon = () => {
    switch (icon.replace('/static/media/', '').split('.')[0]) {
      case 'euro':
        return <Euro />;
      case 'rules-periodic':
        return <Clock />;
      case 'rules_income':
        return <Sack />;
      case 'rules-coffee':
        return <Mug />;
      case 'rules-smoking':
        return <Smoking />;
      case 'rules-football':
        return <Football />;
      default:
        return <Euro />;
    }
  };

  return (
    <GS.Container
      alignItems="center"
      justifyContent="center"
      background={GS.colors.electricGreen}
      borderRadius={GS.radius.sm}
      padding={GS.spacing.xs2}
      aspectRatio={isDesktop ? '16/9' : '1/1'}
      gap={GS.spacing.xs2}
      cursor="pointer"
      onClick={() => {
        navigateTo(routes.rule, { routeParams: { ruleName: rule.identifier } });
      }}
    >
      <SVGIcon Icon={Icon} width={GS.spacing.sm} height="auto" color={GS.colors.green} />
      <GS.Text fontBold>{name}</GS.Text>
    </GS.Container>
  );
};

export default ItemRule;
