import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import * as GS from 'styles/globalStyles';

const CircularProgress = ({ value, counterClockwise }) => {
  let progress = value;
  if (progress >= 100 || progress === 'infinity') {
    progress = 100;
  }
  if (progress <= 0) {
    progress = 0;
  }
  if (progress > 0 && progress < 100) {
    progress = progress.toFixed(1);
  }

  return (
    <GS.Container width={GS.spacing.xl}>
      <CircularProgressbarWithChildren
        //https://www.npmjs.com/package/react-circular-progressbar
        value={value}
        counterClockwise={!counterClockwise}
        strokeWidth={11}
        styles={{
          // Customize the root svg element
          root: {},
          // Customize the path, i.e. the "completed progress"
          path: {
            // Path color
            stroke: GS.colors.electricGreen,
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // Customize transition animation
            transition: 'stroke-dashoffset 0.5s ease 0s',
          },
          // Customize the circle behind the path, i.e. the "total progress"
          trail: {
            // Trail color
            stroke: '#ffffff',
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
          },
        }}
      >
        <GS.RowContainer justifyContent="center" alignItems="center">
          <GS.Text fontBold Rufina fontSize={GS.fontSizes.xl}>
            {progress}
            <GS.Span>%</GS.Span>
          </GS.Text>
        </GS.RowContainer>
      </CircularProgressbarWithChildren>
    </GS.Container>
  )
}

export default CircularProgress;
