import { NavigationWrapper, UiWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import Scrollable from 'components/common/Scrollable/Scrollable';
import TargetItem from './TargetItem';

import * as GS from 'styles/globalStyles';

const TargetList = ({ targets }) => {
  const { navigateTo } = NavigationWrapper.use();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  const userTargets = targets?.filter((t) => t.status !== 'FINISHED' && t.level === 1);

  return (
    <Scrollable>
      <GS.Grid gap={GS.spacing.xs2} numberOfColumns={isDesktop ? 2 : 1}>
        {userTargets?.map((target) => (
          <TargetItem
            key={target?.id}
            target={target}
            click={() => {
              if (target?.status === 'ACTIVE' || target?.status === 'PAUSED') {
                navigateTo(routes.target, {
                  routeParams: { targetId: target?.id, section: 'graph' },
                });
              } else {
                navigateTo(routes.createTarget, {
                  routeParams: { targetId: target?.id, section: 'start', step: undefined },
                });
              }
            }}
          />
        ))}
      </GS.Grid>
    </Scrollable>
  );
};

export default TargetList;
