import { useState, useEffect } from 'react';
/* eslint-disable no-nested-ternary */
import {
  NavigationWrapper,
  Page,
  UiWrapper,
  RulesWrapper,
  UserWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { rulesData } from 'data/rulesData';
import { teams } from 'data/teamsData';

import useTargetRule from 'hooks/useTargetRule';
import useRuleValue from 'hooks/useRuleValue';
import useInstitutionAccounts from 'hooks/useInstitutionAccounts';

import { formatValueNumber } from 'utils/formatNumber';

import RulesTooltip from 'components/common/RulesTooltip/RulesTooltip';
import HeaderChat from 'components/common/Header/HeaderChat';
import { AcceptDecline } from 'components/common/AcceptDecline/AcceptDecline';
import { Input } from 'components/common/Input/Input';

import { ReactComponent as MinusCircle } from 'assets/svgs/minus-circle.svg';
import { ReactComponent as PlusCircle } from 'assets/svgs/plus-circle.svg';
import { ReactComponent as Euro } from 'assets/svgs/euro.svg';
import { ReactComponent as Mug } from 'assets/svgs/mug-circle.svg';
import { ReactComponent as Smoking } from 'assets/svgs/smoking-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const Rule = ({ ruleNumber }) => {
  const { params } = Page.use();
  const identifier = params.step.toUpperCase();
  const rule = useTargetRule(params.targetId, identifier);
  const { useHeader, useLoading } = UiWrapper.use();
  const { user } = UserWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const { desactivateRule, activateRule, upsertRule } = RulesWrapper.use();
  const banks = useInstitutionAccounts();

  const [canActive, setCanActive] = useState(false);
  const [value, setValue] = useRuleValue(rule);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [openSuggest, setOpenSuggest] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingProps, setLoadingProps] = useState({
    message: 'Guardando datos',
  });

  useEffect(() => {
    if (rule.type === 'suggest') {
      const allTeams = teams.filter((t) => t.active === true);
      const filtered = allTeams.filter((team) =>
        team.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTeams(filtered);
      if (value.length > 2 && filtered.length > 0) {
        if (value !== filtered[0].label) {
          setOpenSuggest(true);
        } else {
          setOpenSuggest(false);
        }
      } else {
        setOpenSuggest(false);
      }
    }
  }, [value]);

  const headerBackHandler = () => {
    setLoading(true);
    navigateTo(routes.createTarget, {
      routeParams: {
        section: ruleNumber === 0 ? 'initial' : 'rule',
        targetId: params.targetId,
        step: ruleNumber === 0 ? '' : rulesData[ruleNumber - 1].identifier,
      },
    });
  };

  const Icon = () => {
    if (rule.type === 'image') {
      switch (rule.image.replace('/static/media/', '').split('.')[0]) {
        case 'cafeina':
          return <Mug />;
        case 'nicotina':
          return <Smoking />;
        default:
          return <Euro />;
      }
    }
  };

  const handleChangeValue = (val) => {
    const selectedValue = parseInt(val.replace(/\D/g, '')) || 0;
    setValue(selectedValue > 10000 ? 1000 : selectedValue);
  };

  const nextStepHandler = (isActive) => {
    setLoading(true);
    if (isActive) {
      let ruleData = [
        {
          identifier: rule.fieldDefinitions[0].identifier,
          data: { value },
        },
      ];

      if (rule.fieldDefinitions[1]?.identifier) {
        ruleData = [
          {
            identifier: rule.fieldDefinitions[0].identifier,
            data: { value },
          },
          {
            identifier: rule.fieldDefinitions[1].identifier,
            data: { value: rule.defaultAltValue },
          },
        ];
      }

      try {
        if (rule && !rule.isActive) {
          activateRule(identifier, params.targetId);
        }
        upsertRule(identifier, user.id, params.targetId, ruleData);
      } catch (e) {
        console.error('## ERROR ACTIVANDO', e);
        return;
      }
    } else {
      try {
        if (rule) {
          desactivateRule(identifier, params.targetId);
        }
      } catch (e) {
        console.error('## ERROR DESACTIVANDO', e);
        return;
      }
    }
    const lastRule = ruleNumber === rulesData.length - 1;
    if (params.from) {
      goBack();
    } else if (lastRule) {
      navigateTo(routes.summary, { routeParams: { targetId: params.targetId } });
    } else if (
      (rulesData[ruleNumber].identifier === 'coinscrap_rounds' ||
        rulesData[ruleNumber].identifier === 'earnings_saving') &&
      isActive
    ) {
      if (banks?.length > 0) {
        navigateTo(routes.rulesAccounts, {
          queryParams: {
            from: {
              name: routes.createTarget,
              routeParams: {
                targetId: params.targetId,
                rule: rulesData[ruleNumber].identifier,
                nextRule: rulesData[ruleNumber + 1].identifier,
              },
            },
          },
        });
      } else {
        navigateTo(routes.banksStart, {
          queryParams: {
            from: {
              name: routes.createTarget,
              routeParams: {
                targetId: params.targetId,
                rule: rulesData[ruleNumber].identifier,
                nextRule: rulesData[ruleNumber + 1].identifier,
              },
            },
          },
        });
      }
    } else {
      navigateTo(routes.createTarget, {
        routeParams: {
          section: 'rule',
          targetId: params.targetId,
          step: rulesData[ruleNumber + 1].identifier,
        },
      });
    }
  };

  useEffect(() => {
    if (value) {
      setCanActive(true);
    } else {
      setCanActive(false);
    }
  }, [value]);

  useHeader(<HeaderChat text={rule.name} onBack={headerBackHandler} />, [params]);

  useLoading(loading, loadingProps);

  return (
    <GS.Container justifyContent="start" alignItems="center" gap={GS.spacing.xs} grow={1}>
      <GS.Container alignItems="center">
        <GS.Text fontBold>{rule.question}</GS.Text>
        <RulesTooltip
          content={
            <GS.Container gap={GS.spacing.xs}>
              {rule.tooltip.map((text) => (
                <GS.Text key={text.substring(3, 9)} textLeft fontSize={GS.fontSizes.sm}>
                  {text}
                </GS.Text>
              ))}
            </GS.Container>
          }
        />
      </GS.Container>

      <GS.Container alignItems="center">
        {rule.type === 'image' && (
          <SVGIcon Icon={Icon} width={GS.spacing.xl} height="auto" color={GS.colors.green} />
        )}

        {rule.type === 'increment' && (
          <GS.RowContainer justifyContent="center" alignItems="center" gap={GS.spacing.xs}>
            <GS.Container
              width="fit-content"
              cursor="pointer"
              onClick={() => setValue(value > 1 ? value - 1 : 1)}
            >
              <SVGIcon
                Icon={MinusCircle}
                width={GS.spacing.sm}
                height="auto"
                color={GS.colors.green}
              />
            </GS.Container>
            <GS.Container width={GS.spacing.xl4}>
              <GS.Title fontBold Rufina>
                {value + (rule.subtype === 'currency' ? '€' : '%  ')}
              </GS.Title>
            </GS.Container>
            <GS.Container width="fit-content" cursor="pointer" onClick={() => setValue(value + 1)}>
              <SVGIcon
                Icon={PlusCircle}
                width={GS.spacing.sm}
                height="auto"
                color={GS.colors.green}
              />
            </GS.Container>
          </GS.RowContainer>
        )}

        {rule.type === 'currency' && (
          <GS.Container alignItems="center">
            <Input
              value={formatValueNumber(value) === '0' ? '' : formatValueNumber(value)}
              type="text"
              inputMode="numeric"
              //unit="€"
              placeholder="Cantidad"
              RightAdornment={() => (
                <GS.Container width="fit-content" marginLeft={GS.spacing.xs}>
                  <SVGIcon Icon={Euro} width="18px" height="18px" color={GS.colors.green} />
                </GS.Container>
              )}
              maxWidth={GS.spacing.xl6}
              border={`1px solid ${GS.colors.lightGreen}`}
              onChange={(e) => handleChangeValue(e.target?.value)}
            />
          </GS.Container>
        )}

        {rule.type === 'suggest' && (
          <GS.Container position="relative" alignItems="center">
            <Input
              value={value}
              type="text"
              textCenter
              placeholder="Escribe tu equipo"
              maxWidth={GS.spacing.xl10}
              border={`1px solid ${GS.colors.lightGreen}`}
              onChange={(e) => setValue(e.target?.value)}
            />
            {openSuggest && (
              <GS.Container
                position="absolute"
                top={GS.spacing.lg}
                gap={GS.spacing.xs2}
                width="fit-content"
                padding={GS.spacing.xs}
                zIndex={1}
                background={GS.colors.lightGrey}
              >
                {filteredTeams.map((team) => (
                  <GS.Container
                    key={team.id}
                    cursor="pointer"
                    onClick={() => {
                      setValue(team.label);
                      setOpenSuggest(false);
                    }}
                  >
                    <GS.Text>{team.label}</GS.Text>
                  </GS.Container>
                ))}
              </GS.Container>
            )}
          </GS.Container>
        )}
      </GS.Container>

      <GS.Container gap={GS.spacing.xs2}>
        {rule?.description
          ? rule.description.map((line) => <GS.Text key={line.id}>{line}</GS.Text>)
          : ''}
      </GS.Container>

      <GS.Container gap={GS.spacing.xs}>
        <GS.Container>
          <GS.Text fontBold>
            Esta aportación es {rule.mandatory ? 'obligatoria' : 'opcional'}
          </GS.Text>
          <GS.Text targetMovementItemAmountSt>¿Quieres aplicar la regla?</GS.Text>
        </GS.Container>
      </GS.Container>

      <AcceptDecline
        onDecline={() => nextStepHandler(false)}
        onAccept={canActive ? () => nextStepHandler(true) : undefined}
      />
    </GS.Container>
  );
};

export default Rule;
