import { UiWrapper } from '@coinscrap/webapp-core';
import colors from 'config/colors';
import fonts from 'config/fonts';
import commonStyles from './commonStyles';

UiWrapper.defineComponent('textField', {
  styles: {
    default: {
      inputComponent: {},
      containerComponent: {},
      inputContainerComponent: {},
    },

    createTargetSt: {
      containerComponent: { width: '100%', background: colors.white, textAlign: 'center' },

      inputComponent: {
        padding: '15px',
        fontFamily: fonts.GothamBold,
        background: colors.white,
        textAlign: 'center',
      },
      inputContainerComponent: { background: colors.white, textAlign: 'center' },
    },

    inputRuleSt: {
      containerComponent: {
        width: '100%',
        textAlign: 'center',
        background: '#fff',
      },
      inputComponent: {
        margin: 0,
        padding: '10px 0',
        textAlign: 'center',
        fontSize: 18,
        background: colors.white,
        fontFamily: fonts.GothamBold,
      },
      inputContainerComponent: {
        textAlign: 'center',
        background: colors.white,
      },
      currencyMode: {
        inputComponent: { textAlign: 'center' },
      },
    },

    afterBanksInputSt: {
      containerComponent: {
        borderBottom: `1px solid #d7d7d7`,
        boxSizing: 'border-box',
        width: '100%',
        height: '43px',
        borderRadius: 0,
        marginBottom: 0,
      },
      inputContainerComponent: {
        backgroundColor: colors.white,
        borderRadius: 0,
        height: '100%',
      },
      inputComponent: {
        height: '100%',
        padding: '6px !important',
        fontSize: 16,
        color: colors.dark,
        textAlign: 'left',
      },
      labelComponent: {
        fontSize: 14,
        color: colors.dark,
        textAlign: 'left',
      },
      noLabelMode: {
        inputComponent: {
          paddingTop: 7,
        },
      },
      focusedMode: {
        containerComponent: {
          /* borderBottom: `2px solid #ff4081`, */
          backgroundColor: colors.white,
        },
        inputContainerComponent: {
          backgroundColor: colors.white,
        },
      },
    },

    ...commonStyles.styles,
  },
});
