import { useState } from 'react';
import { TargetsUtilsWrapper } from '@coinscrap/webapp-core';

import ItemData from 'components/views/Targets/components/ItemData';
import CircularProgress from 'components/common/CircularProgress';

import { ReactComponent as CircleQuestion } from 'assets/svgs/circle-question.svg';
import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { ReactComponent as Check } from 'assets/svgs/check-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { Modal } from 'components/common/Modal/Modal';
import { Button } from 'components/common/Button/Button';
import { Spinner } from 'components/common/Spinner/Spinner';

import * as GS from 'styles/globalStyles';

const TargetItem = ({ target, click, refProp }) => {
  //const { useLoading } = UiWrapper.use();
  const { cancel, finalize } = TargetsUtilsWrapper.use();
  const [loading, setLoading] = useState(false);
  const progress = Math.round((target?.progress.completed / target?.progress.total) * 100) || 0;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);

  //useLoading(loading);

  return (
    <>
      <GS.Container
        position="relative"
        background={GS.colors.lightGrey}
        borderRadius={GS.radius.sm}
        padding={GS.spacing.xs2}
        gap={GS.spacing.xs2}
      >
        <GS.RowContainer
          ref={refProp}
          alignItems="center"
          gap={GS.spacing.xs2}
          filter={target?.status === 'PAUSED' ? 'grayscale(100%)' : 'initial'}
          cursor="pointer"
          onClick={click}
        >
          <GS.Container width="fit-content">
            <CircularProgress value={progress > 100 ? 100 : progress} />
          </GS.Container>
          <GS.Container width="auto" grow={1}>
            <ItemData target={target} rufina />
          </GS.Container>
          <GS.Container width="fit-content">
            <SVGIcon
              Icon={Angle}
              width={GS.spacing.xs}
              height="auto"
              color={GS.colors.green}
              transform="rotate(270deg)"
            />
          </GS.Container>
        </GS.RowContainer>
        <GS.RowContainer justifyContent="space-between" alignItems="center">
          <GS.Container width="fit-content" height="100%">
            {target.status === 'PAUSED' && (
              <GS.RowContainer
                width="fit-content"
                alignItems="center"
                gap={GS.spacing.xs3}
                height="100%"
                padding={`0 ${GS.spacing.xs2}`}
                borderRadius={GS.radius.full}
                background={GS.colors.lightGreen}
              >
                <GS.Text fontBold fontSize={GS.fontSizes.xs}>
                  Completado
                </GS.Text>
                <SVGIcon Icon={Check} width="10px" height="auto" color={GS.colors.green} />
              </GS.RowContainer>
            )}
          </GS.Container>

          {target?.status !== 'ACTIVE' && target?.status !== 'PAUSED' && (
            <Button
              text="Eliminar"
              buttonStyle="minimal"
              onClick={() => setIsDeleteModalOpen(true)}
            />
          )}
          {(target?.status === 'PAUSED' || target?.status === 'ACTIVE') && (
            <Button
              text="Archivar"
              buttonStyle="minimal"
              onClick={() => setIsArchiveModalOpen(true)}
            />
          )}
        </GS.RowContainer>
      </GS.Container>

      <Modal isOpen={isDeleteModalOpen} close={() => setIsDeleteModalOpen(false)}>
        {loading && (
          <GS.Container height={GS.spacing.xl8} alignItems="center" justifyContent="center">
            <GS.Container alignItems="center" gap={GS.spacing.xs2}>
              <Spinner size={GS.spacing.xsm} />
              <GS.Text>Eliminando...</GS.Text>
            </GS.Container>
          </GS.Container>
        )}
        {!loading && (
          <GS.Container alignItems="center" gap={GS.spacing.sm} maxWidth={GS.screenSizes.sm}>
            <SVGIcon
              Icon={CircleQuestion}
              width={GS.spacing.lg}
              height="auto"
              color={GS.colors.green}
            />
            <GS.Container gap={GS.spacing.xs2}>
              <GS.Text>Confirmar la eliminación de este objetivo</GS.Text>
            </GS.Container>
            <GS.Grid numberOfColumns={2} width={GS.screenSizes.xs}>
              <Button
                buttonStyle="invertedWithLine"
                text="No"
                minWidth="none"
                onClick={() => setIsDeleteModalOpen(false)}
              />
              <Button
                text="Sí"
                minWidth="none"
                onClick={async () => {
                  setLoading(true);
                  await cancel(target?.id)
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.error(error);
                    })
                    .finally(() => {
                      setIsDeleteModalOpen(false);
                    });
                }}
              />
            </GS.Grid>
          </GS.Container>
        )}
      </Modal>

      <Modal isOpen={isArchiveModalOpen} close={() => setIsArchiveModalOpen(false)}>
        {loading && (
          <GS.Container height={GS.spacing.xl8} alignItems="center" justifyContent="center">
            <GS.Container alignItems="center" gap={GS.spacing.xs2}>
              <Spinner size={GS.spacing.xsm} />
              <GS.Text>Archivando...</GS.Text>
            </GS.Container>
          </GS.Container>
        )}
        {!loading && (
          <GS.Container alignItems="center" gap={GS.spacing.sm}>
            <SVGIcon
              Icon={CircleQuestion}
              width={GS.spacing.lg}
              height="auto"
              color={GS.colors.green}
            />
            <GS.Container gap={GS.spacing.xs2}>
              <GS.Text>Confirmar el archivo de este objetivo</GS.Text>
            </GS.Container>
            <GS.Grid numberOfColumns={2} width={GS.screenSizes.xs}>
              <Button
                buttonStyle="invertedWithLine"
                text="No"
                minWidth="none"
                onClick={() => setIsArchiveModalOpen(false)}
              />
              <Button
                text="Sí"
                minWidth="none"
                onClick={async () => {
                  setLoading(true);
                  await finalize(target?.id)
                    .then(() => {
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.error(error);
                    })
                    .finally(() => {
                      setIsArchiveModalOpen(false);
                    });
                }}
              />
            </GS.Grid>
          </GS.Container>
        )}
      </Modal>
    </>
  );
};

export default TargetItem;
