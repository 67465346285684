import * as GS from 'styles/globalStyles';
import * as S from './styles';

export const Modal = ({ isOpen, close, children, ...props }) =>
  isOpen ? (
    <GS.Container
      position="fixed"
      alignItems="center"
      justifyContent="center"
      top="0"
      left="0"
      zIndex="10"
      height="100%"
      background={GS.colors.blackMidTransparent}
    >
      <S.Modal {...props}>{children}</S.Modal>
    </GS.Container>
  ) : null;
