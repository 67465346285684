/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import Collapse from '../Collapse/Collapse';
import { Modal } from '../Modal/Modal';
import Scrollable from '../Scrollable/Scrollable';
import Linkify from 'react-linkify';

import { Button } from '../Button/Button';
import * as GS from 'styles/globalStyles';
import * as S from './styles';

const componentDecorator = (href, text, key) => (
  <a
    href={href}
    key={key}
    target="_blank"
    rel="noopener noreferrer"
    style={{ color: GS.colors.darkGreen, fontWeight: GS.fontWeights.bold }}
  >
    {text}
  </a>
);

export const LegalModal = ({ data, isOpen, close, onAccept, title, required, HtmlData }) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);

  return (
    <Modal isOpen={isOpen} close={close}>
      <Scrollable showScroller={required} onScrollBottom={() => setButtonDisabled(false)}>
        <GS.Container>
          <S.CloseButon
            onClick={() => {
              close();
            }}
          >
            X
          </S.CloseButon>
          {HtmlData ? (
            <GS.Container>{HtmlData}</GS.Container>
          ) : (
            <>
              <GS.Title>{title}</GS.Title>
              {data.map((item, index) => (
                <Collapse
                  key={index}
                  title={item?.title || ''}
                  isOpened
                  showArrow={false}
                  titlePadding={`${GS.spacing.xs} 0`}
                  textBackground="transparent"
                  textPadding="0"
                >
                  <Linkify componentDecorator={componentDecorator}>
                    {item?.text && <GS.Text textLeft>{item.text}</GS.Text>}
                    {item?.description && <GS.Text textLeft>{item.description}</GS.Text>}
                  </Linkify>
                </Collapse>
              ))}
            </>
          )}
        </GS.Container>
        <GS.Container alignItems="center" paddingTop={GS.spacing.xs}>
          <Button
            text="Aceptar"
            disabled={required && buttonDisabled}
            maxWidth={GS.screenSizes.xs}
            onClick={() => {
              onAccept();
              close();
            }}
          />
        </GS.Container>
      </Scrollable>
    </Modal>
  );
};
