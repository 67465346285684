import * as GS from 'styles/globalStyles';

export const SummaryLineItem = ({ title, text }) => (
  <GS.Container
    padding={`${GS.spacing.xs2} ${GS.spacing.xs}`}
    background={GS.colors.lightGrey}
    borderRadius={GS.radius.sm}
  >
    <GS.Text textLeft fontBold fontSize={GS.fontSizes.xs}>
      {title}
    </GS.Text>
    <GS.Text textLeft fontSize={GS.fontSizes.md}>
      {text}
    </GS.Text>
  </GS.Container>
);
