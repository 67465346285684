import React, { useEffect } from 'react';

import {
  UiWrapper,
  NavigationWrapper,
  FinancialWrapper,
  InstitutionsWrapper,
  Page,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import { Spinner } from 'components/common/Spinner/Spinner';
import GenericModal from 'components/common/GenericModal';
import HeaderChat from 'components/common/Header/HeaderChat';
import { Input } from 'components/common/MainInput/styles';
import { Button } from 'components/common/Button/Button';

import * as GS from 'styles/globalStyles';

export const BankAwait = () => {
  const { institutions } = InstitutionsWrapper.use();
  const { useHeader, openModal } = UiWrapper.use();
  const { navigateTo, goBack } = NavigationWrapper.use();
  const { updateInstitutionProducts } = FinancialWrapper.use();

  const { params, query } = Page.use();
  const identifier = params.idBank;

  useHeader(<HeaderChat text="Bancos" onBack={() => { }} />);

  useEffect(() => {
    if (institutions) {
      updateInstitutionProducts(
        identifier,
        { mode: 'AWAIT_FULL_PROCESS' },
        (message, otpSetter, discard) => {
          openModal(
            (close, context, setContext) => (
              <GenericModal>
                <GS.Container
                  background={GS.colors.white}
                  padding={GS.spacing.xs}
                  alignItems="center"
                  gap={GS.spacing.xs}
                >
                  <GS.Container gap={GS.spacing.xs2} alignItems="center" justifyContent="center">
                    <GS.Text fontBold fontSize={GS.fontSizes.lg} color={GS.colors.green}>
                      {message}
                    </GS.Text>
                    <Input
                      label="OTP"
                      value={context.value}
                      onChange={(e) => setContext({ value: e.target?.value })}
                    />
                  </GS.Container>
                  <GS.RowContainer gap={GS.spacing.xs2}>
                    <Button
                      text="Cancelar"
                      buttonStyle="invertedWithLine"
                      minWidth="unset"
                      height={GS.spacing.sm}
                      onClick={() => {
                        discard();
                        close();
                      }}
                    />
                    <Button
                      text="Aceptar"
                      minWidth="unset"
                      height={GS.spacing.sm}
                      onClick={() => {
                        otpSetter(context.value);
                        close();
                      }}
                    />
                  </GS.RowContainer>
                </GS.Container>
              </GenericModal>
            ),
            { disableClickAway: true }
          );
        },
        {
          onSuccess: () => {
            if (query?.from?.routeParams?.targetId) {
              const previousRoute = query?.from?.name || '';
              if (previousRoute === 'target') {
                navigateTo(routes.target, {
                  routeParams: {
                    section: query?.from?.routeParams?.section || 'ruleBanks',
                    targetId: query?.from?.routeParams?.targetId,
                  },
                  queryParams: {
                    rule: query?.from?.routeParams?.rule,
                  },
                  clearItems: ['from'],
                });
              } else {
                navigateTo(routes.rulesAccounts, {
                  routeParams: {
                    section: 'rule',
                    targetId: query?.from?.routeParams?.targetId,
                    step: query?.from?.routeParams?.nextRule,
                  },
                });
              }
            } else {
              navigateTo(routes.banksStart, { handleRedirection: true, mode: 'replace' });
            }
          },
          onFailure: () => {
            goBack();
          },
          onDiscard: () => {
            goBack();
          },
        }
      );
    }
  }, []);

  return (
    <MaxWidthWrapper background={GS.colors.green}>
      <GS.Container
        height="100%"
        justifyContent="center"
        alignItems="center"
        padding={GS.spacing.xs}
      >
        <GS.Container padding={GS.spacing.xs} gap={GS.spacing.xs}>
          <GS.Text color={GS.colors.white}>Estamos recuperando los datos de sus cuentas.</GS.Text>
          <GS.Text color={GS.colors.white}>
            Es posible que durante el proceso se le pidan credenciales de acceso.
          </GS.Text>
          <GS.Container alignItems="center">
            <Spinner />
          </GS.Container>
        </GS.Container>
      </GS.Container>
    </MaxWidthWrapper>
  );
};
