import * as GS from 'styles/globalStyles';
import { Button } from './Button/Button';

const FooterNext = ({ text = 'Siguiente', disabled, onClick }) => (
  <GS.Container alignItems="center" padding={`${GS.spacing.xs} 0`}>
    <Button maxWidth={GS.screenSizes.xs} text={text} disabled={disabled} onClick={onClick} />
  </GS.Container>
);

export default FooterNext;
