import { UiWrapper } from '@coinscrap/webapp-core';
import * as GS from 'styles/globalStyles';

UiWrapper.defineComponent('inputSwitch', {
  styles: {
    default: {
      containerComponent: {
        margin: 0,
        padding: 0,
        width: GS.spacing.md,
        height: GS.spacing.xsm,
      },
      switchContainerComponent: {
        margin: 0,
        padding: 0,
        width: GS.spacing.md,
        height: GS.spacing.xsm,
      },
      switchComponent: {
        margin: 0,
        padding: 0,
        width: GS.spacing.md,
        height: GS.spacing.xsm,
      },
      thumbComponent: {
        width: GS.spacing.xs,
        height: GS.spacing.xs,
        marginLeft: '25px',
        backgroundColor: GS.colors.grey,
        opacity: 0.5,
      },
      trackComponent: {
        backgroundColor: GS.colors.blackMidTransparent,
        width: GS.spacing.md,
        height: GS.spacing.xsm,
        borderRadius: GS.radius.full,
      },
      checkedMode: {
        thumbComponent: {
          backgroundColor: GS.colors.electricGreen,
          marginLeft: '-65px',
          opacity: 1,
        },
      },
    },
  },
});
