import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('inputDate', {
  styles: {
    default: {
      containerComponent: {},
      inputContainerComponent: {},
      labelComponent: {},
      toolbarComponent: {},
      daySelectedComponent: {},
      dayComponent: {},
      currentDayComponent: {},
      actionTextComponent: {},
      inputComponent: {},
      iconContainerComponent: {},
    },
  },
});
