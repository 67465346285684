import woman1 from 'assets/images/woman-1.jpg';
import woman2 from 'assets/images/woman-2.jpg';
import man1 from 'assets/images/man-1.jpg';
import man2 from 'assets/images/man-2.jpg';
import woman1Desktop from 'assets/images/woman-1-desktop.jpg';
import woman2Desktop from 'assets/images/woman-2-desktop.jpg';
import man1Desktop from 'assets/images/man-1-desktop.jpg';
import man2Desktop from 'assets/images/man-2-desktop.jpg';

export const tourData = [
  {
    id: 1,
    image: woman1,
    imageDesktop: woman1Desktop,
    pretitle: 'Bienvenido',
    text: `Una nueva forma de ahorrar te está esperando. Descubre cómo la Mutualidad puede ayudarte a conseguir tus deseos.`,
  },
  {
    id: 2,
    image: man1,
    imageDesktop: man1Desktop,
    pretitle: '¿Cómo funciona?',
    text:
      'Eliges un objetivo para el que ahorrar, configuras los parámetros del mismo... y ya tienes tu primer plan de ahorro en marcha. Fácil, ¿no?',
  },
  {
    id: 3,
    image: man2,
    imageDesktop: man2Desktop,
    pretitle: 'Reglas de ahorro',
    text:
      'Hemos creado un montón de reglas que te ayudarán a ahorrar aún más rápido. Puedes ahorrar redondeando tus compras, si tu equipo de fútbol gana, si quieres dejar de fumar...',
  },
  {
    id: 4,
    image: woman2,
    imageDesktop: woman2Desktop,
    pretitle: '¿Es seguro?',
    text:
      'Cumplimos con la normativa de protección de datos personales (RGPD) y servicios de pago (PSD2), para que todas tus operaciones sean seguras',
  },
];
