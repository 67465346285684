import getCurrencyDecimals from 'utils/getCurrencyDecimals';
import * as GS from 'styles/globalStyles';

const ProgressBar = ({ completed, total }) => {
  const percentage = Math.round((completed / total) * 100);

  return (
    <GS.Container
      position="relative"
      justifyContent="center"
      height={GS.spacing.sm2}
      background={GS.colors.lightGrey}
      borderRadius={GS.radius.full}
      overflow="hidden"
    >
      <GS.Container position="absolute" top={0} left={0} width="100%">
        <GS.Text fontBold>{`${getCurrencyDecimals(completed)} de ${getCurrencyDecimals(
          total
        )} €`}</GS.Text>
      </GS.Container>
      <GS.Container
        alignItems="center"
        justifyContent="center"
        width={`${percentage > 100 ? 100 : percentage}%`}
        height="100%"
        background={GS.colors.electricGreen}
        borderRadius={`0 ${GS.radius.full} ${GS.radius.full} 0`}
      />
    </GS.Container>
  );
};

export default ProgressBar;
