import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from '../SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const Collapse = ({
  title,
  children,
  isOpened = false,
  setOpened,
  showArrow = true,
  titleBackground = GS.colors.lightGrey,
  titlePadding = `${GS.spacing.xs} ${GS.spacing.xs2}`,
  textBackground = GS.colors.lighterGrey,
  textPadding = GS.spacing.xs,
}) => {
  const capitalizedTitle = title !== '' ? title[0].toUpperCase() + title.substring(1) : '';

  return (
    <GS.Container cursor="pointer" onClick={setOpened}>
      <GS.RowContainer
        justifyContent="space-between"
        alignItems="center"
        padding={titlePadding}
        gap={GS.spacing.xs2}
        background={titleBackground}
      >
        {title !== '' && (
          <GS.Text fontBold textLeft>
            {capitalizedTitle}
          </GS.Text>
        )}
        {showArrow && (
          <SVGIcon
            Icon={Angle}
            width={GS.spacing.xs}
            height="auto"
            color={GS.colors.green}
            transform={isOpened ? 'rotate(180deg)' : 'rotate(0deg)'}
          />
        )}
      </GS.RowContainer>
      <GS.Container
        isOpened={isOpened ? 1 : 0}
        padding={textPadding}
        background={textBackground}
        display={isOpened ? 'flex' : 'none'}
      >
        {children}
      </GS.Container>
    </GS.Container>
  );
};

export default Collapse;
