// import colors from '../colors';
// import fonts from '../fonts';

const commonStyles = {
  styles: {
    paddingSt: {
      padding: 10,
    },
    paddingBottomSt: {
      paddingBottom: 15,
    },
    noPaddingSt: {
      paddingBottom: 0,
    },

    marginSt: {
      margin: 15,
    },
    marginBottomSt: {
      marginBottom: 15,
    },
    marginTopSt: {
      marginTop: 15,
    },
    leftMarginSt: {
      marginLeft: 15,
    },
    rightMarginSt: {
      marginRight: 15,
    },
    fullWidthSt: {
      width: '100%',
    },
    fullWidthPaddingSt: {
      width: 'calc(100% - 20px)',
    },
  },
};

export default commonStyles;
