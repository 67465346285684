import styled from 'styled-components/macro';
import * as GS from '../../../styles/globalStyles';

export const Button = styled.button`
  align-items: center;
  background: ${({ background }) => background || GS.colors.green};
  border: ${({ border }) => border || 'none'};
  border-radius: ${GS.radius.full};
  cursor: pointer;
  display: flex;
  height: ${({ height }) => height || GS.spacing.md};
  justify-content: center;
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  min-width: ${({ minWidth }) => minWidth || '180px'};
  transition: all 0.3s;
  white-space: nowrap;
  width: ${({ width }) => width || '100%'};
  &:disabled {
    cursor: default;
    opacity: 0.3;
  }
  &:active {
    opacity: 0.8;
  }
  &:hover {
    opacity: ${({ disabled }) => (disabled ? '0.3;' : '0.8')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }
  &:focus {
    outline: none;
  }
`;
