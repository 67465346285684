import React from 'react';

import { ModalContainer } from '../ModalContainer/ModalContainer';
import { CloseButton } from './CloseButton';
import { Button } from '../Button/Button';

import * as GS from '../../../styles/globalStyles';
import * as S from './styles';

export const BasicModal = ({
  title,
  text,
  withCancelButton = false,
  withButton = true,
  onAccept,
  closeOnAccept = true,
  close,
  closeButton = true,
  bgColor,
  children,
}) => {
  const handleClickAccept = () => {
    onAccept && onAccept();
    if (closeOnAccept) {
      close();
    }
  };

  return (
    <ModalContainer bgColor={bgColor || 'transparent'}>
      <GS.Container>
        <S.ModalContent>
          {closeButton && (
            <S.CloseButtonContainer>
              <CloseButton
                color={GS.colors.pink}
                onClick={() => {
                  close();
                }}
              />
            </S.CloseButtonContainer>
          )}
          {title && <S.ModalTitle>{title}</S.ModalTitle>}
          {text !== '' && <S.ModalText>{text}</S.ModalText>}
          {children}
          {withButton || withCancelButton ? (
            <S.ButtonContainer>
              {withCancelButton && (
                <Button text="Cancelar" withIcon={false} minWidth="auto" onClick={() => close()} />
              )}
              {withButton && (
                <Button
                  text="Ok"
                  withIcon={false}
                  minWidth="auto"
                  onClick={() => handleClickAccept()}
                />
              )}
            </S.ButtonContainer>
          ) : (
            <GS.Container paddingBottom={GS.spacing.sm} />
          )}
        </S.ModalContent>
      </GS.Container>
    </ModalContainer>
  );
};
