import './components/button';
import './components/layout';
import './components/view';
import './components/text';
import './components/textCurrency';
import './components/textField';
import './components/image';
import './components/input';
import './components/inputCheck';
import './components/inputImage';
import './components/imageSelector';
import './components/inputDate';
import './components/inputSelect';
import './components/inputSlider';
import './components/inputSwitch';
import './components/selectItem';
import './components/radioOption';
import './components/loading';
import './components/slider';
import './components/modal';
import './components/enfatize';
import './components/tooltip';

import colors from './colors';
import fonts from './fonts';

const config = {
  fontFamily: fonts.GothamBook,
  colors: {
    defaultFontColor: colors.black,
  },
};

export default config;
