import React from 'react';
import * as GS from '../styles/globalStyles';

export const MaxWidthWrapper = ({ background, maxWidth, children }) => (
  <GS.Container height="100%" alignItems="center" background={background || GS.colors.white}>
    <GS.Container height="100%" maxWidth={maxWidth || GS.screenSizes.sm}>
      {children}
    </GS.Container>
  </GS.Container>
);
