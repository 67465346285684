import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';

UiWrapper.defineComponent('layout', {
  styles: {
    default: {
      headerComponent: { height: 'auto', flexShrink: 0, minHeight: 0 },
      footerComponent: { height: 'auto', minHeight: 0, overflow: 'visible', flexShrink: 0 },
      bodyComponent: {
        justifyContent: 'flex-start',
        boxSizing: 'border-box',
        // background: 'white',
      },
      bodyContentComponent: {
        flexBasis: 'initial',
        flexGrow: 1,
        //maxWidth: 500,
      },
      bodyScrollerComponent: {
        maxHeight: '100%',
      },
      loadingComponent: {
        backgroundColor: colors.main + '99', // Opacity
        loaderColor: colors.white,
      },
      sidebarComponent: {
        contentContainerComponent: {
          // background: 'url("/images/bg-dark.png")',
          //backgroundColor: '#f1f1f1',
        },
      },
      modalComponent: {
        backgroundComponent: {
          backgroundColor: '#333',
          opacity: '70% !important',
          backdropFilter: 'blur(3px)',
        },
        bodyComponent: {},
        contentComponent: {
          background: 'none',
          boxShadow: 'none',
          borderRadius: 12,
          width: '100%',
          height: '100%',
          maxWidth: 500,
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  },
});
