import { UiWrapper } from '@coinscrap/webapp-core';
import * as GS from 'styles/globalStyles';

export const HeroImage = ({ ItemContentPage }) => {
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  const bgImage = isDesktop ? ItemContentPage.imageDesktop : ItemContentPage.image;

  return (
    <GS.Container
      height={isDesktop ? '30%' : '50%'}
      maxHeight={isDesktop ? '270px' : '400px'}
      background={`url(${bgImage}) no-repeat center center / cover`}
    />
  );
};
