import React, { useEffect, useState } from 'react';

import {
  FinancialWrapper,
  InstitutionsWrapper,
  NavigationWrapper,
  Page,
  PickerHOC,
  SoftSwitcher,
  UiWrapper,
  UserWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { Spinner } from 'components/common/Spinner/Spinner';

import * as GS from 'styles/globalStyles';

export const FetchPolicies = PickerHOC(
  {
    mAbogaciaInstitution: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        // TODO: Cambiar identificar con el user
        value: 'mabogacia',
      },
      sourcePath: 'institutions',
      sourceWrapper: InstitutionsWrapper,
    },
    user: {
      sourcePath: 'user',
      sourceWrapper: UserWrapper,
    },
    mAbogaciaUserIdentityProvider: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        value: 'mutua-abogacia',
      },
      sourcePath: 'user.identityProviders',
    },
  },
  () => {
    UiWrapper.use().useLoading(true);
    return null;
  }
)(() => {
  const { useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const {
    createOrUpdateCredentialsInstitution,
    updateInstitutionAccountProducts,
  } = FinancialWrapper.use();
  const { query } = Page.use();
  const { mAbogaciaInstitution, mAbogaciaUserIdentityProvider } = PickerHOC.use();
  const token = mAbogaciaUserIdentityProvider.externalTokens[0].sessionData.headerToken;
  const aii = mAbogaciaUserIdentityProvider.externalTokens[0].sessionData.aiiDato;
  const [error, setError] = useState(false);

  const [institutionUpserted, setInstitutionUpserted] = useState();

  useEffect(() => {
    (async () => {
      try {
        const response = await createOrUpdateCredentialsInstitution(
          mAbogaciaInstitution.identifier,
          {
            method: mAbogaciaInstitution.authenticationMethods[0].identifier,
            values: {
              aii,
              token,
            },
          },
          { mode: 'AWAIT_FULL_PROCESS' }
        );
        if (response.status !== 'SUCCESS' || !response.institutionAccountId) {
          throw new Error('Error ocurred validating credentials');
        }
        setInstitutionUpserted(response.institutionAccountId);
      } catch (e) {
        console.error('Error', e);
        setError(true);
      }
    })();
  }, [mAbogaciaInstitution?.identifier]);

  useEffect(() => {
    (async () => {
      if (institutionUpserted) {
        try {
          // TODO: Enviar pocilyId real
          await updateInstitutionAccountProducts(institutionUpserted, {
            mode: 'AWAIT_FULL_PROCESS',
            filters: {
              policyId: query.cod_pol,
            },
          });
          navigateTo(routes.autoConfigure);
        } catch (e) {
          console.error('Error', e);
          setError(true);
        }
      }
    })();
  }, [institutionUpserted]);

  useLoading(false);

  return (
    <GS.Container
      alignItems="center"
      justifyContent="center"
      height="100%"
      gap={GS.spacing.sm}
      background={GS.colors.electricGreen}
      padding={GS.spacing.xs}
    >
      <SoftSwitcher evaluate={error}>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Title Rufina>Recuperando datos</GS.Title>
          <GS.Text>Estamos obteniendo los datos de tus pólizas para poder continuar.</GS.Text>
          <Spinner />
        </GS.Container>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Title Rufina fontBold>
            Error
          </GS.Title>
          <GS.Text>
            No hemos podido recuperar los datos de sus pólizas. Por favor inténtelo de nuevo más
            tarde.
          </GS.Text>
        </GS.Container>
      </SoftSwitcher>
    </GS.Container>
  );
});
