import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('inputImage', {
  styles: {
    default: {
      textComponent: {},
      containerComponent: {},
    },
  },
});
