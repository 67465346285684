import styled, { keyframes } from 'styled-components';
import * as GS from '../../../styles/globalStyles';

const load8 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  animation: ${load8} 1.1s infinite linear;
  border-bottom: 1.1em solid ${({ lightColor }) => lightColor || GS.colors.lightGreen};
  border-left: 1.1em solid ${({ darkColor }) => darkColor || GS.colors.green};
  border-radius: 50%;
  border-right: 1.1em solid ${({ lightColor }) => lightColor || GS.colors.lightGreen};
  border-top: 1.1em solid ${({ lightColor }) => lightColor || GS.colors.lightGreen};
  font-size: 4px;
  height: ${({ size }) => size || '10em'};
  position: relative;
  transform: translateZ(0);
  width: ${({ size }) => size || '10em'};

  &:after {
    border-radius: 50%;
    height: ${({ size }) => size || '10em'};
    width: ${({ size }) => size || '10em'};
  }
`;
