import { useState } from 'react';
import { NavigationWrapper } from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { ReactComponent as MABLogo } from 'assets/mab-logo.svg';
import { ReactComponent as Piggy } from 'assets/svgs/piggy.svg';
import { ReactComponent as Ruler } from 'assets/svgs/ruler.svg';
import { ReactComponent as User } from 'assets/svgs/user.svg';
import { ReactComponent as Gear } from 'assets/svgs/gear.svg';
import { ReactComponent as CircleQuestion } from 'assets/svgs/circle-question.svg';
import { SVGIcon } from '../SVGIcon/SVGIcon';

import { Modal } from '../Modal/Modal';
import { Button } from 'components/common/Button/Button';
import { FooterGridItem } from '../FooterGridItem/FooterGridItem';

import * as GS from 'styles/globalStyles';

const Footer = ({ location }) => {
  const { navigateTo } = NavigationWrapper.use();

  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  return (
    <>
      <GS.Container alignItems="center" background={GS.colors.green} padding={GS.spacing.xs3}>
        <GS.Container maxWidth={GS.screenSizes.sm} height="100%">
          <GS.Grid numberOfColumns={5} alignItems="center" height="100%">
            <FooterGridItem
              text="Mi ahorro"
              active={location === 'targets'}
              onClick={() => navigateTo(routes.targets)}
            >
              <SVGIcon Icon={Piggy} width="24px" height="auto" color={GS.colors.white} />
            </FooterGridItem>
            <FooterGridItem
              text="Mis reglas"
              active={location === 'rules'}
              onClick={() => navigateTo(routes.rules)}
            >
              <SVGIcon Icon={Ruler} width="20px" height="auto" color={GS.colors.white} />
            </FooterGridItem>
            <FooterGridItem
              justifyContent="center"
              active={location === 'home'}
              onClick={() => navigateTo(routes.home)}
            >
              <SVGIcon Icon={MABLogo} width={GS.spacing.sm} height="auto" color={GS.colors.pink} />
            </FooterGridItem>
            <FooterGridItem
              text="Ayuda"
              active={location === 'chat'}
              onClick={() => setIsTermsModalOpen(true)}
            >
              <SVGIcon Icon={User} width="20px" height="auto" color={GS.colors.white} />
            </FooterGridItem>
            <FooterGridItem
              text="Mis ajustes"
              active={location === 'settings'}
              onClick={() => navigateTo(routes.settings)}
            >
              <SVGIcon Icon={Gear} width="20px" height="auto" color={GS.colors.white} />
            </FooterGridItem>
          </GS.Grid>
        </GS.Container>
      </GS.Container>
      <Modal isOpen={isTermsModalOpen} close={() => setIsTermsModalOpen(false)}>
        <GS.Container alignItems="center" gap={GS.spacing.sm} maxWidth={GS.screenSizes.sm}>
          <SVGIcon
            Icon={CircleQuestion}
            width={GS.spacing.lg}
            height="auto"
            color={GS.colors.green}
          />
          <GS.Container gap={GS.spacing.xs2}>
            <GS.Text>
              Si necesitas ayuda llámanos al teléfono{' '}
              <GS.Link fontBold href="tel:914352486">
                914 35 24 86
              </GS.Link>{' '}
              o bien envíanos un correo a la dirección{' '}
              <GS.Link fontBold href="mailto:yosiahorro@mutualidadabogacia.com">
                yosiahorro@mutualidadabogacia.com
              </GS.Link>{' '}
              y te responderemos lo antes posible.
            </GS.Text>
            <GS.Text>
              <GS.Span fontBold>De lunes a jueves</GS.Span> de 8:30 a 18:30.
            </GS.Text>
            <GS.Text>
              <GS.Span fontBold>Viernes</GS.Span> de 8:30 a 14:30.
            </GS.Text>
            <GS.Text>
              <GS.Span fontBold>Desde el 01 de Julio hasta el 07 de septiembre</GS.Span> de 8:30 a
              14:30
            </GS.Text>
          </GS.Container>
          <Button text="Ok" maxWidth={GS.spacing.xl} onClick={() => setIsTermsModalOpen(false)} />
        </GS.Container>
      </Modal>
    </>
  );
};

export default Footer;
