import { InputSwitch, RulesWrapper } from '@coinscrap/webapp-core';
import { useEffect, useState } from 'react';

import getCurrencyDecimals from 'utils/getCurrencyDecimals';

import ItemData from 'components/views/Targets/components/ItemData';
import { Modal } from 'components/common/Modal/Modal';
import RuleModal from 'components/common/RuleModal';

import * as GS from 'styles/globalStyles';

const TargetItem = ({ target, last }) => {
  const { desactivateRule } = RulesWrapper.use();

  console.log('targettarget', target);

  const [isActive, setIsActive] = useState(target?.rule?.isActive);
  const [rejected, setRejected] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const identifier = target?.rule.identifier.toUpperCase();

  const [ruleValue, setRuleValue] = useState(undefined);

  useEffect(() => {
    rejected && setIsActive(false);
    setRejected(false);
  }, [rejected]);

  useEffect(() => {
    if (target?.rule?.type === 'image') {
      setRuleValue('');
    }
    if (target?.rule?.type === 'increment') {
      setRuleValue(
        `${getCurrencyDecimals(target?.rule?.value?.fieldValues[0]?.data?.value || 0)} €`
      );
    }
    if (target?.rule?.type === 'currency') {
      setRuleValue(
        `${getCurrencyDecimals(target?.rule?.value?.fieldValues[0]?.data?.value || 0)} €`
      );
    }
    if (target?.rule?.type === 'suggest') {
      setRuleValue(target?.rule?.value?.fieldValues[0]?.data?.value);
    }
  }, [target]);

  return (
    <GS.RowContainer
      justifyContent="space-between"
      paddingTop={GS.spacing.xs}
      paddingBottom={!last ? GS.spacing.xs : 0}
      borderTop={`1px solid ${GS.colors.white}`}
    >
      <GS.Container width="auto" grow={1} opacity={isActive ? 1 : 0.7}>
        <ItemData target={target} />
      </GS.Container>

      <GS.Container width="fit-content" justifyContent="space-between" alignItems="end">
        <GS.Text
          fontBold
          fontSize={target?.rule?.type === 'suggest' ? GS.fontSizes.sm : GS.fontSizes.lg}
        >
          {/* {getCurrencyDecimals(
            target?.nextSaving?.ruleDetails[target?.rule?.identifier]?.amount || 0
          )}
          € */}
          {ruleValue}
        </GS.Text>
        <GS.Container width="fit-content">
          <InputSwitch
            value={isActive}
            onChange={() => {
              setIsActive((a) => !a);
              !isActive && setIsOpenModal(true);
              isActive && desactivateRule(identifier, target?.id);
            }}
          />
        </GS.Container>
      </GS.Container>
      <Modal
        isOpen={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
      >
        <RuleModal
          setIsOpenModal={setIsOpenModal}
          rule={target?.rule}
          close={() => setIsOpenModal(false)}
          setRejected={setRejected}
          targetId={target?.id}
        />
      </Modal>
    </GS.RowContainer>
  );
};

export default TargetItem;
