import getCurrencyDecimals from 'utils/getCurrencyDecimals';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const MovementsItem = ({ date, text, amount, onClick }) => (
  <GS.RowContainer
    justifyContent="space-between"
    alignItems="center"
    background={GS.colors.lighterGrey}
    padding={GS.spacing.xs}
    onClick={onClick}
    cursor={onClick ? 'pointer' : 'default'}
  >
    <GS.Container width="auto" grow={1} gap={GS.spacing.xs3}>
      <GS.Text textLeft fontBold>
        {text}
      </GS.Text>
      <GS.Text textLeft fontSize={GS.fontSizes.xs}>
        {date}
      </GS.Text>
    </GS.Container>
    <GS.RowContainer width="fit-content" alignItems="center" gap={GS.spacing.xs2}>
      <GS.Text textRight fontBold>
        {amount && getCurrencyDecimals(amount)}€
      </GS.Text>
      {onClick && (
        <SVGIcon
          Icon={Angle}
          width={GS.spacing.xs}
          height="auto"
          color={GS.colors.lightGreen}
          transform="rotate(-90deg)"
        />
      )}
    </GS.RowContainer>
  </GS.RowContainer>
);

export default MovementsItem;
