import styled from 'styled-components/macro';
import * as GS from '../../../styles/globalStyles';

export const ModalWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

export const ModalContent = styled(GS.Container)`
  background: ${GS.colors.white};
  border-radius: ${GS.radius.sm};
  padding: ${GS.spacing.xs};
  position: relative;
`;

export const ModalTitle = styled(GS.Title)`
  color: ${GS.colors.grey};
  font-size: ${GS.fontSizes.lg};
  margin-top: ${GS.spacing.xs};
`;

export const ModalText = styled(GS.Text)`
  color: ${GS.colors.grey};
  padding: ${GS.spacing.sm} 0 ${GS.spacing.xs} 0;
`;

export const ButtonContainer = styled(GS.Container)`
  margin: 0;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: ${GS.spacing.xs2};
  top: ${GS.spacing.xs2};
`;

export const CloseContainer = styled.div`
  align-items: center;
  background: ${GS.colors.green};
  border-radius: ${GS.radius.full};
  cursor: pointer;
  display: flex;
  height: ${GS.spacing.sm};
  justify-content: center;
  margin: 0;
  padding: 0;
  width: ${GS.spacing.sm};
`;

export const CloseIcon = styled.img`
  height: auto;
  width: 15px;
`;
