import styled from 'styled-components/macro';

export const ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  padding: 10px;
  width: 100%;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ItemLeftContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  overflow: hidden;
`;

export const ItemRightContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Name = styled.h2`
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;
`;

export const Number = styled.p`
  font-size: 10px;
`;

export const Amount = styled.h2`
  font-size: 20px;
  margin-left: auto;
  margin-right: 5px;
  text-align: right;
  text-transform: capitalize;
  width: 100%;
`;

export const Icon = styled.img`
  margin-right: 10px;
  width: 20px;
`;
