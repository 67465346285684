import styled, { css } from 'styled-components/macro';
import * as GS from '../../../styles/globalStyles';

const filled = css`
  border-bottom: ${({ borderBottom }) => borderBottom || `1px solid ${GS.colors.white}`};
  color: white;
`;

export const InputContainer = styled(GS.Container)`
  align-items: center;
  border: ${({ border }) => border || `1px solid ${GS.colors.green}`};
  border-radius: ${({ borderRadius }) => borderRadius || GS.radius.sm};
  flex-direction: row;
  margin-top: ${({ marginTop }) => marginTop || '0px'};
`;

export const Input = styled.input`
  background: transparent;
  border: none;
  color: ${({ color }) => color || GS.colors.green};
  font-family: 'Mutualidad', sans-serif;
  font-size: ${({ fontSize }) => fontSize || GS.fontSizes.md};
  outline: none;
  padding: ${({ padding }) => padding || GS.spacing.xs};
  width: 100%;
  &::placeholder {
    color: ${GS.colors.greenMidTransparent};
    font-family: 'Mutualidad', sans-serif;
    font-size: ${({ fontSize }) => fontSize || GS.fontSizes.md};
    font-weight: ${GS.fontWeights.medium};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

export const LabelText = styled(GS.Text)`
  color: ${GS.colors.green};
  font-family: 'Mutualidad', sans-serif;
  font-size: ${GS.fontSizes.sm};
  font-weight: ${GS.fontWeights.bold};
`;
