import React, { useState } from 'react';

import * as GS from '../../../styles/globalStyles';
import * as S from './styles';

import eye from '../../../assets/svgs/eye.svg';
import eyeSlash from '../../../assets/svgs/eyeSlash.svg';

export const Input = ({
  show = true,
  label,
  value,
  disabled = false,
  type,
  inputMode,
  unit,
  LeftAdornment,
  RightAdornment,
  placeholder,
  password,
  max,
  min,
  step,
  paddingTop,
  paddingBottom,
  color,
  padding,
  fontSize,
  border,
  pointer,
  onChange,
}) => {
  const [hidePassword, setHidePassword] = useState(true);

  return (
    <GS.Container
      display={show ? 'flex' : 'none'}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      {label && <S.LabelText textLeft>{label}</S.LabelText>}
      <S.InputContainer
        position="relative"
        value={value}
        border={border}
        marginTop={label ? GS.spacing.xs2 : '0'}
      >
        {LeftAdornment && <LeftAdornment />}
        <S.Input
          value={value}
          placeholder={placeholder}
          inputMode={type === 'number' || inputMode === 'numeric' ? 'numeric' : 'text'}
          type={hidePassword && password ? 'password' : type || 'text'}
          max={max}
          min={min}
          step={step}
          disabled={disabled}
          color={color}
          padding={padding}
          fontSize={fontSize}
          onChange={onChange}
        />
        {RightAdornment && <RightAdornment />}
        <GS.Container
          position="absolute"
          right="0"
          top="50%"
          transform="translateY(-50%)"
          width="auto"
        >
          {unit && (
            <GS.Text fontBold fontSize={GS.fontSizes.sm}>
              {unit}
            </GS.Text>
          )}
          {password && (
            <GS.Image
              src={hidePassword ? eye : eyeSlash}
              width="20px"
              onClick={() => setHidePassword(!hidePassword)}
            />
          )}
        </GS.Container>
      </S.InputContainer>
    </GS.Container>
  );
};
