import React, { useState, useEffect } from 'react';

import * as GS from '../../../styles/globalStyles';
import * as S from './styles';

import eye from './eye.svg';
import eyeSlash from './eyeSlash.svg';

export const Input = ({
  show = true,
  label,
  value,
  disabled = false,
  type,
  inputMode,
  unit,
  LeftAdornment,
  RightAdornment,
  placeholder,
  password,
  max,
  min,
  step,
  border,
  maxWidth,
  textCenter,
  onChange,
}) => {
  const [hidePassword, setHidePassword] = useState(true);
  const [inputType, setInputType] = useState('text');

  useEffect(() => {
    if (password) {
      setInputType(hidePassword ? 'password' : 'text');
    } else {
      setInputType(type || 'text');
    }
  }, [type, hidePassword, password]);

  return (
    <GS.Container display={show ? 'flex' : 'none'} maxWidth={maxWidth}>
      {label && (
        <S.LabelText
          color={value === '' ? GS.colors.whiteMidTransparent : GS.colors.blackMidTransparent}
        >
          {label}
        </S.LabelText>
      )}
      <S.InputWrapper
        position="relative"
        border={border}
        padding={
          label
            ? `${GS.spacing.xxxs} ${GS.spacing.xxs} ${GS.spacing.xxs} ${GS.spacing.xxs}`
            : `${GS.spacing.xs}`
        }
      >
        {LeftAdornment && <LeftAdornment />}
        <S.Input
          textLeft={!textCenter}
          label={label}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          inputMode={type === 'number' || inputMode === 'numeric' ? 'numeric' : 'text'}
          type={inputType}
          max={max}
          min={min}
          step={step}
          disabled={disabled}
          color={value === '' ? GS.colors.green : GS.colors.green}
        />
        {RightAdornment && <RightAdornment />}
        <GS.Container alignItems="center" justifyContent="center" width="fit-content">
          {unit && (
            <GS.Text fontBold fontSize={GS.fontSizes.sm}>
              {unit}
            </GS.Text>
          )}
          {password && (
            <S.Image
              src={hidePassword ? eye : eyeSlash}
              filter
              width="20px"
              value={value}
              onClick={() => setHidePassword(!hidePassword)}
            />
          )}
        </GS.Container>
      </S.InputWrapper>
    </GS.Container>
  );
};
