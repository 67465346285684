const colors = {
  black: '#333333',
  white: '#FbFbFb',
  realWhite: '#ffffff',
  green: '#59ac26',
  red: '#e33d21',
  darkBlue: '#1A2E3A',
  lightBlue: '#016E91' /* rgb(62,110,145) */,
  grey: '#DBDBDB',
  lightGrey: '#f9f9f9',
};

export default colors;
