import React, { useEffect, useState } from 'react';

import {
  NavigationWrapper,
  BackendWrapper,
  SessionWrapper,
  SoftSwitcher,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { Spinner } from 'components/common/Spinner/Spinner';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import { startupParamsProviderUtility } from '../../common/GlobalWrapper';

import * as GS from '../../../styles/globalStyles';

// Debido a que no sta clara todavia su API dejo esto por aqui para que sea facil añadir o quitar parametros de la condicion de conexion
const sendValues = [/*'token',*/ 'idDato' /*, 'numMutualista' , 'sva'*/];

const TokenEntry = () => {
  const { getInstanceApi } = BackendWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { startSession, clearSession } = SessionWrapper.use();

  const [invalidMutualista, setInvalidMutualista] = useState(false);

  const { params: rawParams } = startupParamsProviderUtility;
  const params = sendValues.reduce((acc, act) => ({ ...acc, [act]: rawParams[act] }), {});

  const allRequiredValuesArePresent = !sendValues.find((rV) => !params[rV]);

  useEffect(() => {
    clearSession();
  }, []);

  //TODO custom hook candidate
  useEffect(() => {
    const connect = async () => {
      let response;
      const allData = {
        headerToken: params.token,
        aiiDato: params.idDato,
        nMut: params.nMut,
        sva: params.sva,
      };
      const dataToSend = Object.keys(allData)
        .filter((x) => allData[x])
        .reduce((acc, act) => ({ ...acc, [act]: allData[act] }), {});

      try {
        response = await getInstanceApi().loginUser(dataToSend, 'mutua-abogacia');
        await startSession({ token: response.accessToken });
        navigateTo(routes.onBoarding, {
          routeParams: { section: 'tour' },
          handleRedirection: true,
          clearItems: ['token', 'idDato', 'nMut', 'sva'],
        });
      } catch (e) {
        console.error(' ERROR ', e, e.status);
        if (e.status === 409) {
          setInvalidMutualista(true);
        } else {
          navigateTo(routes.errorToken);
        }
      }
    };
    if (allRequiredValuesArePresent) {
      connect();
    }
  }, [allRequiredValuesArePresent]);

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="center"
        alignItems="center"
        padding={GS.spacing.xs}
      >
        <GS.Container gap={GS.spacing.xs}>
          <GS.Title Rufina color={GS.colors.green}>
            Identificación
          </GS.Title>
          <SoftSwitcher evaluate={allRequiredValuesArePresent ? invalidMutualista : 2}>
            <GS.Container alignItems="center" gap={GS.spacing.xs}>
              <GS.Text>Estamos obteniendo tus datos de usuario, espere por favor.</GS.Text>
              <Spinner />
            </GS.Container>
            <GS.Text>
              Los datos de sesión proporcionados no son válidos o consistentes. Por favor inténtelo
              de nuevo mas tarde.
            </GS.Text>
            <GS.Text>
              No se han encontrado datos de inicio de sesión. Asegurese que correctamente a la
              plataforma.
            </GS.Text>
          </SoftSwitcher>
        </GS.Container>
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default TokenEntry;
