/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import {
  NavigationWrapper,
  Page,
  UiWrapper,
  TargetsUtilsWrapper,
  TargetsWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';
import { formatValueNumber } from 'utils/formatNumber';

import { ReactComponent as Euro } from 'assets/svgs/euro.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import RulesTooltip from 'components/common/RulesTooltip/RulesTooltip';
import HeaderChat from 'components/common/Header/HeaderChat';
import { AcceptDecline } from 'components/common/AcceptDecline/AcceptDecline';
import { Input } from 'components/common/Input/Input';

import * as GS from 'styles/globalStyles';

const rule = {
  name: 'Aportación inicial',
  question: '¿Con cuánto dinero quieres empezar a ahorrar?',
  description: [
    'Elige un aportación mínima de 30€',
    'Puedes realizar una aportación inicial que impulsará tu ahorro desde el primer momento',
    'Recuerda que la cuenta de cargo será siempre la asociada a tu producto de Mutualidad.',
  ],
  tooltip: [
    'Esta aportación no es obligatoria pero es una manera fantástica de empezar tu objetivo y conseguirlo cuanto antes. Puedes elegir el importe que desees para tu primera aportación y se sumará al ahorro que acumules en el resto de reglas. Si la suma de todas las reglas es superior a 30€, pasaremos una aportación a tu póliza.',
    'Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulando hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.',
  ],
  placeHolder: 30,
  defaultValue: 30,
  mandatory: false,
};

const Initial = () => {
  const { params } = Page.use();
  const { navigateTo } = NavigationWrapper.use();
  const { useHeader, useLoading } = UiWrapper.use();
  const [value, setValue] = useState(rule.defaultValue);
  const { saveTargetProperty } = TargetsUtilsWrapper.use();
  const { targets } = TargetsWrapper.use();
  const target = targets?.find((t) => t.id === params.targetId);

  const [canActive, setCanActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingProps, setLoadingProps] = useState({
    message: 'Guardando datos',
  });

  const handleClickBack = () => {
    setLoading(true);
    saveTargetProperty(params.targetId, 'creationData', { initialContribution: 0 }) &&
      navigateTo(routes.createTarget, {
        routeParams: {
          section: 'rule',
          targetId: params.targetId,
          step: 'coinscrap_rounds',
        },
      });
  };

  const handleClickApplyRule = () => {
    setLoading(true);
    saveTargetProperty(params.targetId, 'creationData', { initialContribution: value }) &&
      navigateTo(routes.createTarget, {
        routeParams: {
          section: 'rule',
          targetId: params.targetId,
          step: 'coinscrap_rounds',
        },
      });
  };

  const handleChangeValue = (val) => {
    const selectedValue = parseInt(val.replace(/\D/g, '')) || 0;
    setValue(selectedValue > 10000 ? 1000 : selectedValue);
  };

  useEffect(() => {
    if (value >= 30) {
      setCanActive(true);
    } else {
      setCanActive(false);
    }
  }, [value]);

  useEffect(() => {
    target && setValue(target?.creationData.initialContribution || rule.defaultValue);
  }, [target]);

  useHeader(
    <HeaderChat
      text="Aportación inicial"
      onBack={() =>
        navigateTo(routes.createTarget, {
          routeParams: { section: 'start' },
        })
      }
    />,
    [params]
  );

  useLoading(loading, loadingProps);

  return (
    <GS.Container justifyContent="start" alignItems="center" gap={GS.spacing.xs} grow={1}>
      <GS.Container alignItems="center">
        <GS.Text fontBold>{rule.question}</GS.Text>
        <RulesTooltip
          content={
            <GS.Container gap={GS.spacing.xs}>
              {rule.tooltip.map((text) => (
                <GS.Text key={text.substring(3, 9)} textLeft fontSize={GS.fontSizes.sm}>
                  {text}
                </GS.Text>
              ))}
            </GS.Container>
          }
        />
      </GS.Container>
      <GS.Container alignItems="center">
        <Input
          value={formatValueNumber(value) === '0' ? '' : formatValueNumber(value)}
          type="text"
          inputMode="numeric"
          //unit="€"
          placeholder="Cantidad"
          RightAdornment={() => (
            <GS.Container width="fit-content" marginLeft={GS.spacing.xs}>
              <SVGIcon Icon={Euro} width="18px" height="18px" color={GS.colors.green} />
            </GS.Container>
          )}
          maxWidth={GS.spacing.xl6}
          border={`1px solid ${GS.colors.lightGreen}`}
          onChange={(e) => handleChangeValue(e.target?.value)}
        />
      </GS.Container>
      <GS.Container gap={GS.spacing.xs2}>
        {rule?.description
          ? rule.description.map((line) => <GS.Text key={line.id}>{line}</GS.Text>)
          : ''}
      </GS.Container>
      <GS.Container gap={GS.spacing.xs}>
        <GS.Container>
          <GS.Text fontBold>
            Esta aportación es {rule.mandatory ? 'obligatoria' : 'opcional'}
          </GS.Text>
          <GS.Text targetMovementItemAmountSt>¿Quieres efectuar la aportación?</GS.Text>
        </GS.Container>
      </GS.Container>

      <AcceptDecline
        onDecline={() => handleClickBack()}
        onAccept={canActive ? () => handleClickApplyRule() : undefined}
      />
    </GS.Container>
  );
};

export default Initial;
