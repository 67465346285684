import React from 'react';
import { Spinner } from '../Spinner/Spinner';

import { ReactComponent as RightArrow } from '../../../assets/svgs/arrow.svg';
import { SVGIcon } from '../SVGIcon/SVGIcon';

import * as S from './styles';
import * as GS from '../../../styles/globalStyles';

export const Button = ({
  text,
  onClick,
  disabled,
  withIcon = false,
  icon,
  width,
  height,
  maxWidth,
  minWidth,
  buttonStyle = 'standar',
  loading,
}) => {
  let background = '';
  let color = '';
  let border = 'none';
  let buttonWidth = width;
  let buttonHeight = height;
  let buttonMinWidth = minWidth;
  let fontSize = GS.fontSizes.sm;

  switch (buttonStyle) {
    case 'inverted':
      background = GS.colors.white;
      color = GS.colors.green;
      break;
    case 'invertedWithLine':
      background = GS.colors.white;
      color = GS.colors.green;
      border = `2px solid ${GS.colors.green}`;
      break;
    case 'electric':
      background = GS.colors.electricGreen;
      color = GS.colors.green;
      break;
    case 'pink':
      background = GS.colors.pink;
      color = GS.colors.green;
      break;
    case 'minimal':
      buttonWidth = width || GS.spacing.xl;
      buttonHeight = GS.spacing.xsm;
      buttonMinWidth = 'none';
      background = GS.colors.lightViolet;
      color = GS.colors.darkViolet;
      fontSize = GS.fontSizes.xs;
      break;
    default:
      background = GS.colors.green;
      color = GS.colors.electricGreen;
      break;
  }

  return (
    <S.Button
      onClick={onClick}
      disabled={disabled}
      width={buttonWidth}
      height={buttonHeight}
      maxWidth={maxWidth}
      minWidth={buttonMinWidth}
      border={border}
      background={background}
    >
      {loading && <Spinner size={GS.spacing.xsm} />}
      {!loading && text && (
        <GS.Text fontBold fontSize={fontSize} color={color}>
          {text}
        </GS.Text>
      )}
      {withIcon && (
        <>
          {icon ? (
            <SVGIcon
              Icon={icon}
              width="20px"
              height="auto"
              color={GS.colors.darkGrey}
              marginLeft={GS.spacing.xxs}
            />
          ) : (
            <SVGIcon
              Icon={RightArrow}
              width="15px"
              height="auto"
              color={GS.colors.darkGrey}
              marginLeft={GS.spacing.xxs}
            />
          )}
        </>
      )}
    </S.Button>
  );
};
