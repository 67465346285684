import React, { useEffect, useState } from 'react';
import {
  InstitutionAccountsWrapper,
  NavigationWrapper,
  PickerHOC,
  SoftSwitcher,
  TargetsUtilsWrapper,
  TargetsWrapper,
  TargetTypesWrapper,
  UiWrapper,
} from '@coinscrap/webapp-core';

import { useLocation } from 'react-router-dom';

import routes from 'config/routes';
import { isValidIBANNumber } from 'utils/Iban';

import { Spinner } from 'components/common/Spinner/Spinner';

import * as GS from 'styles/globalStyles';

export const AutoConfigure = PickerHOC(
  {
    targetType: {
      arrayItemMatcher: {
        itemPropertyPath: 'identifier',
        type: 'value',
        value: 'AHORRO_MABOGACIA',
      },
      sourcePath: 'targetTypes',
      sourceWrapper: TargetTypesWrapper,
    },
    parentTarget: {
      arrayItemMatcher: {
        allowNoExistence: true,
        itemPropertyPath: 'level',
        type: 'value',
        value: 0,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    userTargets: {
      arrayItemFilter: {
        itemPropertyPath: 'level',
        type: 'value',
        value: 1,
      },
      sourcePath: 'targets',
      sourceWrapper: TargetsWrapper,
    },
    // This awaits accounts for getDestProduct() in inner home
    userInstitutionsAccounts: {
      sourcePath: 'institutionAccounts',
      sourceWrapper: InstitutionAccountsWrapper,
    },
  },
  () => {
    UiWrapper.use().useLoading(true);
    return null;
  }
)(() => {
  const location = useLocation();
  const { useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const { instanciateNewTarget, activate, saveTargetProperty } = TargetsUtilsWrapper.use();
  const [error, setError] = useState(0);
  const [codigoPoliza, setCodigoPoliza] = useState(
    new URLSearchParams(location.search).get('cod_pol')
  );

  const {
    userInstitutionsAccounts,
    parentTarget,
    targetType: { id: idTargetType },
  } = PickerHOC.use();

  console.log('codigoPoliza', codigoPoliza);
  console.log('userInstitutionsAccounts', userInstitutionsAccounts);

  //TODO: IMPLEMENTING IBAN FLOW, PUT THIS TO TRUE
  const simulatesBadIban = false;

  const parentTargetProductsConfigurated =
    !simulatesBadIban &&
    parentTarget?.creationData?.destProduct?.combinedId &&
    parentTarget?.creationData?.sourceProduct?.iban;

  const parentTargetCorrectlyInicializated =
    parentTarget?.status === 'ACTIVE' && parentTargetProductsConfigurated;

  // Efectting for create a parent target if not exsits
  useEffect(() => {
    console.log(' DEBO CREAR TARGET?');
    if (!parentTarget?.id) {
      console.log(' INSTANCIATING NEW GLOBAR TARGET');
      (async () => {
        try {
          await instanciateNewTarget({
            targetType: idTargetType,
          });
        } catch (e) {
          setError(1);
        }
      })();
    }
  }, [parentTarget?.id]);

  // Efectting set destination charge account on target if does not done
  useEffect(() => {
    console.log(' DEBO ACTUALIZAR PRODUCT?', parentTargetProductsConfigurated);

    if (parentTarget?.id && !parentTargetProductsConfigurated) {
      console.log(' SETTING TARGET THE PRODUCT', userInstitutionsAccounts);
      (async () => {
        const institution = userInstitutionsAccounts.find(
          (iA) => iA.institutionIdentifier === 'mabogacia'
        );
        // En este proyecto solo puede tener un producto para MAbogacia
        // El producto que nos interesa es typeIdentifier: "PUA-SAF1"
        const productDestino = (institution?.products || []).find(
          (pr) => pr.identifier === codigoPoliza
        );

        console.log('productDestino', productDestino);

        if (productDestino) {
          console.log(
            ' SETTING FOUNDED TARGET THE PRODUCT',
            userInstitutionsAccounts,
            productDestino
          );

          const iban = (productDestino.additionalData.iban || '')
            .replace('0', '')
            .replace(' ', '')
            .trim()
            ? productDestino.additionalData.iban.replace(' ', '').trim()
            : undefined;

          if (simulatesBadIban || isValidIBANNumber(iban) !== 1) {
            // eslint-disable-next-line no-console
            console.log('NAVIGATE TO -> ', 'routes.chargeAccount');
            const from = { name: routes.chargeAccount };
            navigateTo(routes.banksStart, {
              queryParams: { from },
            });
            setError(3);
          }

          try {
            await saveTargetProperty(parentTarget?.id, 'creationData', {
              destProduct: {
                combinedId: productDestino.institutionAccountId + '-' + productDestino.id,
                identifier: productDestino.identifier,
                typeIdentifier: productDestino.typeIdentifier,
              },
              sourceProduct: {
                fromPolicy: true,
                iban,
              },
            });
          } catch (e) {
            setError(1);
          }
        } else {
          setError(2);
        }
      })();
    }
  }, [parentTarget?.id, parentTargetProductsConfigurated]);

  // Efectting activating target if is not done
  useEffect(() => {
    console.log(' DEBO ACTIVAR EL TARGET?');

    if (parentTarget?.status === 'TEMPORARY' && parentTargetProductsConfigurated) {
      console.log(' ACTIVATING THE TARGET');
      (async () => {
        try {
          await activate(parentTarget.id);
        } catch (e) {
          setError(1);
        }
      })();
    }
  }, [parentTarget?.status, parentTargetProductsConfigurated]);

  useEffect(() => {
    if (parentTargetCorrectlyInicializated) {
      console.log(' GOING TO HOME');
      navigateTo(routes.home, { clearItems: ['cod_pol'] });
    }
  }, [parentTargetCorrectlyInicializated]);

  useLoading(false);

  return (
    <GS.Container
      alignItems="center"
      justifyContent="center"
      height="100%"
      gap={GS.spacing.sm}
      background={GS.colors.green}
      padding={GS.spacing.xs}
    >
      <SoftSwitcher evaluate={error}>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Title Rufina color={GS.colors.white}>
            Tu ahorro
          </GS.Title>
          <GS.Text color={GS.colors.whiteMidTransparent}>Estamos inicializando tu ahorro.</GS.Text>
          <Spinner />
        </GS.Container>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Title Rufina color={GS.colors.white}>
            Error
          </GS.Title>
          <GS.Text color={GS.colors.whiteMidTransparent}>
            Algo ha sucedido mientras intentabamos inicializar tu ahorro. Por favor inténtelo de
            nuevo mas tarde.
          </GS.Text>
        </GS.Container>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Title Rufina color={GS.colors.white}>
            Póliza Incorrecta
          </GS.Title>
          <GS.Text color={GS.colors.whiteMidTransparent}>
            No dispone de una póliza válida sobre la que crear el objetivo de ahorro. Por favor
            inténtelo de nuevo más tarde.
          </GS.Text>
        </GS.Container>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Title Rufina color={GS.colors.white}>
            No se ha detectado cuenta bancaria
          </GS.Title>
          <GS.Text color={GS.colors.whiteMidTransparent}>
            Le estamos redirigendo a la agregación bancaria para que pueda añadir sus bancos y
            comenzar a ahorrar.
          </GS.Text>
        </GS.Container>
      </SoftSwitcher>
    </GS.Container>
  );
});
