export const ruleNameConverter = (identifier) => {
  let toret = identifier;
  switch (identifier) {
    case 'COINSCRAP_ROUNDS':
      toret = 'Redondeos';
      break;
    case 'PERIODIC_SAVING':
      toret = 'Ahorro periódico';
      break;
    case 'AHORRO_HOT':
      toret = 'Ahorro puntual';
      break;
    case 'EARNINGS_SAVING':
      toret = 'Ingresos';
      break;
    case 'NICOTINE':
      toret = 'Nicotina';
      break;
    case 'TEAM':
      toret = 'Equipo';
      break;
    case 'CAFEINE':
      toret = 'Cafeina';
      break;
    default:
      break;
  }
  return toret;
};
