import SectionTitle from 'components/common/SectionTitle/SectionTitle';

import useTargetRules from 'hooks/useTargetRules';
import useTarget from 'hooks/useTarget';
import useInstitutionAccounts from 'hooks/useInstitutionAccounts';

import { RulesItem } from './components/RulesItem';

import * as GS from 'styles/globalStyles';
import Scrollable from 'components/common/Scrollable/Scrollable';

const Rules = ({ targetId }) => {
  const rules = useTargetRules(targetId);
  const target = useTarget(targetId);

  const banks = useInstitutionAccounts();
  const hasBanks = banks.length > 0;
  const hasSelectedRulesAccounts =
    target?.targetRulesConfiguration?.metadata?.ruleAccounts.length > 0;

  return (
    <Scrollable restart>
      <SectionTitle text="Reglas activas/inactivas" padding={`${GS.spacing.xs} 0`} />
      <GS.Container gap={GS.spacing.xs2}>
        {rules?.map((rule) => (
          <RulesItem
            key={rule.name}
            paused={target?.status === 'PAUSED' || target?.status === 'FINISHED'}
            rule={rule}
            weekly={target?.nextSaving?.ruleDetails[rule.identifier.toUpperCase()]?.amount || 0}
            total={
              target?.movements?.reduce((acc, movement) => {
                let total = 0;
                movement?.movementLines
                  ?.filter((l) => l.title.toLowerCase() === rule.identifier)
                  .forEach((m) => {
                    total += m.amount;
                  });
                return acc + total;
              }, 0) ?? 0
            }
            hasBanks={hasBanks}
            hasSelectedRulesAccounts={hasSelectedRulesAccounts}
          />
        ))}
      </GS.Container>
    </Scrollable>
  );
};

export default Rules;
