import * as GS from 'styles/globalStyles';

export const FooterGridItem = ({ text, justifyContent, active, onClick, children }) => (
  <GS.Container
    alignItems="center"
    justifyContent={justifyContent || 'space-between'}
    height="100%"
    padding={`${GS.spacing.xs2} 0`}
    gap={GS.spacing.xs2}
    borderBottom={`2px solid ${active ? GS.colors.electricGreen : 'transparent'}`}
    cursor="pointer"
    onClick={onClick}
  >
    {children}
    {text && (
      <GS.Text color={GS.colors.white} fontSize={GS.fontSizes.xs2}>
        {text}
      </GS.Text>
    )}
  </GS.Container>
);
