import { useEffect, useMemo, useState } from 'react';
import { NavigationWrapper } from '@coinscrap/webapp-core';
import moment from 'moment';
import routes from 'config/routes';

import getCurrencyDecimals from 'utils/getCurrencyDecimals';

import Collapse from 'components/common/Collapse';
import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import MovementsItem from './components/MovementsItem';
import MovementsTotal from './components/MovementsTotal';

import * as GS from 'styles/globalStyles';

const Movements = ({ target }) => {
  const { navigateTo } = NavigationWrapper.use();

  const [collapseOpened, setCollapseOpened] = useState();

  const movements = target?.movements;

  //Cogemos todas las fechas diferentes usando un Set, y despues las ordenamos de mas reciente a mas antigua
  const dates = useMemo(
    () =>
      Array.from(
        new Set(
          movements
            ?.map((movement) => moment(movement.createDate).format('MMMM [de] YYYY'))
            .sort((a, b) => {
              const dateA = moment(a, 'MMMM [de] YYYY');
              const dateB = moment(b, 'MMMM [de] YYYY');

              return moment(dateB).isBefore(dateA) ? -1 : 1;
            })
        )
      ),
    [movements]
  );

  useEffect(() => {
    setCollapseOpened(dates[dates.length - 1]);
  }, [dates]);

  return (
    <GS.Container gap={GS.spacing.xs2}>
      <SectionTitle text="Movimientos" padding={`${GS.spacing.xs} 0 0 0`} />
      {target?.status === 'ACTIVE' && (
        <MovementsTotal
          date={moment().format('D MMM')}
          amount={getCurrencyDecimals(target?.nextSaving?.amount || 0)}
        />
      )}
      {dates?.map((date) => (
        <Collapse
          title={date}
          key={date}
          setOpened={() =>
            collapseOpened === date ? setCollapseOpened(false) : setCollapseOpened(date)
          }
          isOpened={collapseOpened === date}
        >
          {movements?.reverse().map(
            (movement) =>
              moment(movement?.valueDate).format('MMMM [de] YYYY') === date && (
                <MovementsItem
                  key={movement.id}
                  date={moment(movement?.valueDate).format('D MMM')}
                  amount={movement?.amount}
                  text={movement?.concept}
                  onClick={() =>
                    navigateTo(routes.details, {
                      routeParams: { movementId: movement.id },
                    })
                  }
                />
              )
          )}
        </Collapse>
      ))}
    </GS.Container>
  );
};

export default Movements;
