import { useEffect, useState } from 'react';

import * as GS from 'styles/globalStyles';
import * as S from './styles';

export const Spinner = ({ size, color }) => {
  const [lightColor, setLightColor] = useState(GS.colors.lightGreen);
  const [darkColor, setDarkColor] = useState(GS.colors.green);

  useEffect(() => {
    if (color === 'violet') {
      setLightColor(GS.colors.lightViolet);
      setDarkColor(GS.colors.violet);
    }
  }, [color]);

  return <S.SpinnerWrapper size={size} lightColor={lightColor} darkColor={darkColor} />;
};
