import { Page } from '@coinscrap/webapp-core';
import Target from './components/Target';
import Bank from './components/Bank';

const Edit = ({ target }) => {
  const { params } = Page.use();

  return (
    <>
      {params.section === 'edit' && <Target target={target} />}
      {params.section === 'editBank' && <Bank target={target} />}
    </>
  );
};

export default Edit;
