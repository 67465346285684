import { UiWrapper } from '@coinscrap/webapp-core';
import colors from 'config/colors';

UiWrapper.defineComponent('input', {
  styles: {
    default: {
      containerComponent: {
        backgroundColor: colors.white,
      },
      labelComponent: {},
      checkComponent: {
        padding: 4,
      },
      checkedMode: {
        checkComponent: {
          color: colors.lightBlue,
          backgroundColor: colors.white,
          padding: 4,
        },
      },
    },
  },
});
