import styled from 'styled-components/macro';
import * as GS from 'styles/globalStyles';

export const Modal = styled.div`
  align-items: center;
  background-color: ${GS.colors.modalGrey};
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  width: calc(100vw - ${GS.spacing.sm});
  max-width: ${GS.sizes.modal};  
  max-height: calc(100vh - ${GS.spacing.sm});
  padding: ${GS.spacing.xs}};
  margin: ${GS.spacing.xs}};
  border-radius: ${GS.radius.sm};
  overflow-y: scroll;
`;
