const filtered = ['mock-institution', 'mabogacia'];

export const filterInstitutions = (ins) => {
  const banks = (ins || [])
    .filter(
      (i) =>
        i.products?.length &&
        !filtered.includes(i.institutionIdentifier) &&
        !i.isDeleted &&
        !i.isDisabled
    )
    .map((b) => ({
      ...b,
      products: b.products.filter((i) => i.isOwner || i.type === 'card' || i.type === 'debit'),
    }));

  return banks;
};
