export const aiolosTerms = [
  {
    title: 'PRIMERA.- DEFINICIONES',
    description: `A los efectos de lo dispuesto en estas Condiciones Generales, se entenderá por:

    Usuario o Cliente Activo o Cliente final : En relación con el servicio de información sobre cuentas, persona física o jurídica titular de la/s cuenta/s sobre la/s que Morpheus Aiolos ofrece el servicio de información agregada sobre cuentas. 
    
    Multicontrato: Aquellos supuestos en los que las entidades financieras permitan a sus usuarios conectar con distintos perfiles que, a su vez, agrupan cuentas de distintos clientes. 
    
    Comercio electrónico : Es la entidad que (i) vende bienes u ofrece servicios a los usuarios a través de su página web, o bien dispone de una plataforma en la que los comercios electrónicos pueden ofertar sus bienes o servicios y los usuarios adquirirlos, y desea poner a disposición de sus clientes o usuarios la posibilidad de efectuar el pago mediante transferencia bancaria ordenada por el cliente final e iniciada a través de Morpheus Aiolos, o bien (ii) desea ofrecer a sus clientes o usuarios el servicio de información sobre cuentas o agregación financiera, a través de Morpheus Aiolos. 
    
    Servicio de información sobre cuentas: servicio en línea cuya finalidad consiste en facilitar información agregada sobre una o varias cuentas de pago de las que es titular el usuario del servicio de pago bien en otro proveedor de servicios de pago, bien en varios proveedores de servicios de pago. 
    
    Proveedor de servicios de pago gestor de cuenta: un proveedor de servicios de pago que facilita a un ordenante una o varias cuentas de pago y se encarga de su mantenimiento. Credenciales de seguridad personalizadas /Claves /Contraseñas: elementos personalizados que el proveedor de servicios de pago gestor de cuenta proporciona al usuario de servicios de pago a efectos de autenticación, para acceder a los datos de su cuenta de pago y/o iniciar una operación de pago en la banca online de la página web oficial del proveedor de servicios de pago gestor de cuenta. 
    
    Autenticación: procedimiento que permita al proveedor de servicios de pago gestor de la cuenta comprobar la identidad de usuario de un servicio de pago o la validez de la utilización de determinado instrumento de pago, incluida la utilización de credenciales de seguridad personalizadas del usuario. 
    
    Consumidor: una persona física que, en los contratos de servicios de pago, actúa con fines ajenos a su actividad económica, comercial o profesional. 
    
    Microempresa: una empresa, considerando como tal tanto a las personas físicas que realizan una actividad profesional o empresarial como a las personas jurídicas, que, en la fecha de celebración del contrato de servicios de pago ocupa a menos de diez personas y cuyo volumen de negocios anual o cuyo balance general anual no supera los dos millones de euros, de conformidad con lo establecido en los artículos 1 y 2, apartados 1 y 3, del anexo de la Recomendación de la Comisión, de 6 de mayo de 2003, sobre la definición de microempresas, pequeñas y medianas empresas.`,
  },
  {
    title: 'SEGUNDA.-DATOS DEL PRESTADOR DEL SERVICIO',
    description: `
    MORPHEUS AIOLOS, S.L. (en lo sucesivo “ Morpheus Aiolos ”), con CIF B-86556420, constituida en Madrid, en fecha 27 de septiembre de 2012, inscrita en el Registro Mercantil de Madrid, en el tomo 30408, folio 12, hoja número M-547305, inscripción 1ª. Está autorizada por el Banco de España para la prestación de los servicios de iniciación de pago e información sobre cuentas y sujeta a la supervisión del Banco de España y del Servicio Ejecutivo de la Comisión de Prevención del Blanqueo de Capitales e Infracciones Monetarias. Consta debidamente inscrita en el Registro Especial de Proveedores de Servicios de Iniciación de Pagos Entidades de Pago y Proveedores de Servicios de Información sobre Cuentas del Banco de España con el número de codificación 6901. El domicilio social se encuentra en la calle San Andrés, número 8, 28004 Madrid.`,
  },
  {
    title: 'TERCERA.- ÁMBITO DE ACTUACIÓN DEL USUARIO',
    description: `
    Cuando el Usuario no tenga la condición de consumidor ni de microempresa a los efectos previstos en la normativa de servicios de pago, no resultará de aplicación lo dispuesto en el Título II del Real Decreto-Ley 19/2018, de 23 de noviembre, de Servicios de Pago y otras medidas urgentes en materia financiera, así como sus disposiciones de desarrollo, de conformidad con lo previsto en el art. 28.2 del citado Real Decreto-Ley. Asimismo, de conformidad con lo dispuesto en el artículo 34.1 del Real Decreto-Ley 19/2018, de 23 de noviembre, se excluye de manera expresa la aplicación de los artículos 35.1, 36.3, 44, 46, 48, 49, 52, 60 y 61 del referido Real Decreto-Ley.`,
  },
  {
    title: 'CUARTA.- OBJETO',
    description: `Las presentes condiciones generales recogen la información que Morpheus Aiolos, en su condición de proveedor de servicios de información sobre cuentas debe facilitar a los usuarios de servicios de pago. Asimismo, en el presente documento también se regulan los derechos y obligaciones esenciales de los usuarios de estos servicios de pago. Todo ello, de conformidad con lo previsto en el Real Decreto-ley 19/2018, de 23 de noviembre, de servicios de pago y otras medidas urgentes en materia financiera y su normativa de desarrollo.`,
  },
  {
    title: 'QUINTA.- CONDICIONES ASOCIADAS AL ACCESO A LA INFORMACIÓN DE LAS CUENTAS DE PAGO',
    description: `MORPHEUS AIOLOS facilitará al Comercio Electrónico la información financiera derivada de las posiciones que mantengan en cada momento el Usuario en cualquier entidad de crédito o entidad financiera en general, siempre que se pueda acceder a la misma a través de Internet mediante claves personales facilitadas por el Usuario. Asimismo, MORPHEUS AIOLOS ofrecerá al Comercio Electrónico, previo consentimiento expreso del Usuario, la posición global de las cuentas corrientes, de los movimientos bancarios e información sobre las tarjetas del Usuario con el fin de que el Comercio Electrónico pueda proporcionar el servicio de microahorro de reglas automatizadas basadas en credenciales bancarias.`,
  },
  {
    title: 'SEXTA.- OBLIGACIONES DE MORPHEUS AIOLOS',
    description: `Obligaciones como proveedor de servicio de información sobre cuentas o agregación. Morpheus Aiolos, en su condición de proveedor de servicios de información sobre cuentas, cumplirá las siguientes obligaciones: 
    
    a) prestará sus servicios exclusivamente sobre la base del consentimiento explícito del usuario del servicio de pago; 
    
    b) garantizará que las credenciales de seguridad personalizadas del usuario de servicios de pago no sean accesibles a terceros, con excepción del usuario y del emisor de las credenciales de seguridad personalizadas, y que, cuando las transmita el proveedor de servicios de pago que preste el servicio de información sobre cuentas, la transmisión se realice a través de canales seguros y eficientes; 
    
    c) en cada comunicación, se identificará ante el proveedor o proveedores de servicios de pago gestores de cuenta del usuario de servicios de pago y se comunicará de manera segura con el proveedor o proveedores de servicios de pago gestores de cuenta y el usuario del servicio de pago, de conformidad con lo previsto en el Reglamento Delegado 2018/389 y a los criterios que, dentro de las disposiciones de la Autoridad Bancaria Europea que le resulten aplicables, determine el Banco de España; 
    
    d) accederá únicamente a la información de las cuentas de pago designadas por el usuario y las operaciones de pago correspondientes; 
    
    e) no solicitará datos de pago sensibles vinculados a las cuentas de pago; 
    
    f) no utilizará, almacenará o accederá a ningún dato, para fines distintos de la prestación del servicio de información sobre cuentas expresamente solicitado por el usuario del servicio de pago, de conformidad con las normas sobre protección de datos. 
`,
  },
  {
    title:
      'SÉPTIMA.- LIMITACIONES AL ACCESO A LAS CUENTAS DE PAGO POR PROVEEDORES DE SERVICIOS DE PAGO.',
    description: `El proveedor de servicios de pago gestor de cuenta podrá denegar el acceso a una cuenta de pago a Morpheus Aiolos por razones objetivamente justificadas y debidamente documentadas en el caso de acceso no autorizado o fraudulento a la cuenta de pago por parte de Morpheus Aiolos, en particular con la iniciación no autorizada o fraudulenta de una operación de pago. En tales casos, el proveedor de servicios de pago gestor de cuenta informará al usuario, de la manera convenida, de la denegación del acceso a la cuenta de pago y de los motivos para ello. Esa información será facilitada al usuario, de ser posible, antes de denegar el acceso y, a más tardar, inmediatamente después de la denegación, a menos que la comunicación de tal información ponga en peligro medidas de seguridad objetivamente justificadas o esté prohibida por otras disposiciones legales. 
    El proveedor de servicios de pago gestor de cuenta permitirá el acceso a la cuenta de pago una vez dejen de existir los motivos para denegar el acceso.
`,
  },
  {
    title: 'OCTAVA.- INFORMACIÓN',
    description: `Transmisión de información o notificaciones: 
    
    En su caso, si la transmisión de información o notificaciones al usuario se efectuase por medios telemáticos, será informado previamente de los requisitos técnicos aplicables al equipo y a los programas informáticos. También se le proporcionará el procedimiento seguro de notificación en caso de sospecha de fraude, fraude real o de amenazas para la seguridad, que se recoge a continuación: 
    
    Morpheus Aiolos notificará mediante correo electrónico en el momento en el que el sistema informático detecte sospecha de fraude, fraude real o amenazas de seguridad. 
    
    El usuario tiene derecho de recibir previamente la información y condiciones de la prestación del servicio de pago, con el objeto de dar su conformidad con las mismas. 
    
    Morpheus Aiolos facilitará al usuario toda la información legalmente exigible en castellano y al menos una vez al mes.
`,
  },
  {
    title: 'NOVENA.- MODIFICACIÓN DE LAS CONDICIONES',
    description: `Morpheus Aiolos se reserva el derecho a instar la modificación de las presentes condiciones. En caso de que el usuario tuviera la condición de microempresa o consumidor, Morpheus Aiolos deberá comunicar cualquier modificación en las condiciones con una antelación no inferior a dos meses respecto de la fecha en que entre en vigor la modificación propuesta. Transcurridos dichos plazos sin oposición del usuario se aplicará la modificación. Si el usuario se opone a las modificaciones, podrá resolver las mismas sin coste alguno antes de la fecha en la que las modificaciones vayan a surtir efecto. Si la modificación decidida fuera claramente beneficiosa para el usuario, podrá ser aplicada de inmediato. 
    
    En el caso de que el usuario tenga la consideración de microempresa o consumidor, Morpheus Aiolos deberá proponer cualquier modificación de las presentes condiciones de manera clara, individualizada, sin acumularla a otra información o a publicidad, en papel u otro soporte duradero, con una antelación no inferior a dos meses respecto de la fecha en que entre en vigor la modificación propuesta. El usuario podrá aceptar o rechazar las modificaciones de las condiciones antes de la fecha propuesta para su entrada en vigor por el mismo medio que le sean notificadas. 
    
    No obstante, Morpheus Aiolos podrá aplicar de manera inmediata todas aquellas modificaciones que, inequívocamente, resulten más favorables para el usuario. 
    Ambas partes están de acuerdo en que cabrá considerar que el usuario ha aceptado la modificación de las condiciones de que se trate en caso de no comunicar a Morpheus Aiolos su no aceptación con anterioridad a la fecha propuesta de entrada en vigor. En tal supuesto, el usuario tendrá el derecho a resolver las presentes condiciones sin coste alguno y con efecto a partir de cualquier momento anterior a la fecha en que se habría aplicado la modificación.
`,
  },
  {
    title: 'DÉCIMA.- DURACIÓN Y EXTINCIÓN DE LAS CONDICIONES',
    description: `La duración de estas condiciones será de un año natural desde su firma y será prorrogado de forma tácita por períodos anuales en ausencia de comunicación escrita en contrario por cualquiera de las partes intervinientes dirigido a la otra con una antelación mínima de dos (2) meses anteriores a su vencimiento inicial o a cualquiera de sus prórrogas.
     En caso de que el usuario tuviera la condición de microempresa o consumidor, podrá resolver estas condiciones en cualquier momento, sin necesidad de preaviso alguno, debiendo Morpheus Aiolos proceder al cumplimiento de la orden de resolución de estas condiciones antes de transcurridas 24 horas desde la recepción de la solicitud de resolución. La finalización de las presentes condiciones por el transcurso del tiempo no dará a derecho a indemnización para ninguna de las partes.
`,
  },
  {
    title: 'UNDÉCIMA.- PROCEDIMIENTOS DE RECLAMACIÓN A DISPOSICIÓN DEL CLIENTE',
    description: `
    i) Servicio de Atención al Cliente (SAC) 
    Morpheus Aiolos dispone de un Servicio de Atención al Cliente, de conformidad con las obligaciones previstas en la Orden ECO/734/2004, de 11 de marzo, sobre los Departamentos y Servicios de Atención al Cliente y el Defensor del Cliente de las Entidades Financieras, cuya finalidad es atender y resolver tanto las quejas como las reclamaciones que formulen los usuarios. Los datos de contacto del SAC son los siguientes: MORPHEUS AIOLOS, S.L. Servicio de Atención al Cliente Calle San Andrés, número 8, 28004 Madrid e-mail: sac@morpheusaiolos.com 
    
    El procedimiento para la resolución de quejas y reclamaciones se describe en el Reglamento de funcionamiento del Servicio de Atención al Cliente, que se encuentra a disposición de éstos tanto en todas las oficinas de Morpheus Aiolos, como en la página web (http://www.Morpheus Aiolos.com ) y del Banco de España ( www.bde.es ). 
    
    ii) Reclamación ante el Servicio de Reclamaciones del Banco de España 
    En el caso de que la reclamación presentada ante el Servicio de Atención del Cliente de la compañía fuese desestimada o bien hubiera transcurrido el plazo de un mes sin que el citado Servicio de Atención al Cliente hubiera contestado, el cliente podrá presentar su reclamación ante el Servicio de Reclamaciones del Banco de España: Presencialmente, en la siguiente dirección: 
    
    Banco de España Servicio de Reclamaciones C/ Alcalá 48, 28014 Madrid Telemáticamente, en el siguiente enlace: {{link}}
`,
    link: 'https://app.bde.es/psr_www/faces/psr_wwwias/jsp/op/InicioSesion/PantallaAsistenteForm.jsp',
  },
  {
    title: 'DUODÉCIMA.- PRECIO',
    description: `
    El servicio de información sobre cuentas prestado por Morpheus Aiolos tendrá carácter gratuito para el usuario, correspondiendo al comercio electrónico el pago del precio en los términos convenidos con Morpheus Aiolos.
`,
  },
  {
    title: 'DECIMOTERCERA.- GARANTÍAS DE MORPHEUS AIOLOS',
    description: `
    - Seguridad

    Morpheus Aiolos garantiza que la prestación del servicio se realizará con los mecanismos de seguridad que aseguran el cifrado de las claves facilitadas y que garantiza la imposibilidad de uso fraudulento de las mismas.

    - Información estructurada y normalizada 
    
    Morpheus Aiolos garantiza que proporcionará la información financiera y no financiera al usuario de una forma estructurada y normalizada. Por “estructurada” se entiende que la información está bajo un contenedor fácilmente tratable de forma electrónica (JSON o XML). Por “normalizada” se entiende que la información de diferentes entidades se presenta exactamente con el mismo formato y que, además, este formato está normalizado de acuerdo a los estándares del tipo de dato que se trate.
`,
  },
  {
    title: 'DECIMOCUARTA.- PROTECCIÓN DE DATOS PERSONALES',
    description: `
    En el caso de que para la prestación del servicio de información sobre cuentas sea necesario proceder al tratamiento de datos personales y en cumplimiento de lo dispuesto por el Artículo 28 del Reglamento General 2016/679 de Protección de Datos Personales, Morpheus Aiolos, como Encargado de Tratamiento, expresamente manifiesta y se obliga a: (i) utilizar y a tratar los datos con el único y exclusivo objeto de cumplir con las finalidades previstas en las presentes condiciones generales y siguiendo en todo caso las instrucciones recibidas del usuario, (ii) observar la máxima confidencialidad y reserva respecto de los datos de carácter personal que le sean facilitados por el usuario, (iii) devolver al usuario todos los documentos y archivos en los que se hallen reflejados los datos a la finalización de la relación contractual, (iv) restringir el acceso y el uso de los datos a aquellas personas que sea absolutamente imprescindible que tengan acceso y conocimiento de los mismos previa confirmación del usuario por escrito para el desarrollo de la prestación del servicio de información sobre cuentas y (v) adoptar las medidas de seguridad de protección de los datos que correspondan al nivel de seguridad necesario en función de los ficheros a los que tenga acceso. Morpheus Aiolos, para la prestación del servicio anteriormente descrito, precisará de la subcontratación de servicios de hosting y servicios de auditoría LOPD.
`,
  },
  {
    title: 'DECIMOQUINTA. NORMATIVA APLICABLE',
    description: `
 La prestación del servicio de información sobre cuentas al usuario por parte de Morpheus Aiolos se regirá por las condiciones establecidas en las presentes condiciones generales y en lo no previsto en ellas, por las normas contenidas en el Real Decreto-Ley 19/2018, de 23 de noviembre, de servicios de pago y otras medidas urgentes en materia financiera, su normativa de desarrollo, y por las demás disposiciones normativas españolas y de transparencia que resulten aplicables.
`,
  },
];
