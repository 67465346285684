import { ReactComponent as Check } from 'assets/svgs/check-circle-solid.svg';
import { ReactComponent as Close } from 'assets/svgs/close-circle-solid.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

export const AcceptDecline = ({ onDecline, onAccept }) => (
  <GS.RowContainer justifyContent="center" alignItems="center" gap={GS.spacing.xs}>
    <GS.Container width="justify-content" cursor="pointer" onClick={onDecline}>
      <SVGIcon Icon={Close} width={GS.spacing.lg} height="auto" color={GS.colors.darkViolet} />
    </GS.Container>
    <GS.Container
      width="justify-content"
      cursor="pointer"
      onClick={onAccept}
      opacity={!onAccept ? 0.5 : 1}
    >
      <SVGIcon Icon={Check} width={GS.spacing.lg} height="auto" color={GS.colors.green} />
    </GS.Container>
  </GS.RowContainer>
);
