import { ReactComponent as Coins } from 'assets/svgs/coins-circle.svg';
import { ReactComponent as Euro } from 'assets/svgs/euro-circle.svg';
import { ReactComponent as Clock } from 'assets/svgs/clock-circle.svg';
import { ReactComponent as Sack } from 'assets/svgs/sack-circle.svg';
import { ReactComponent as Mug } from 'assets/svgs/mug-circle.svg';
import { ReactComponent as Smoking } from 'assets/svgs/smoking-circle.svg';
import { ReactComponent as Football } from 'assets/svgs/football-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

export const SummaryItem = ({ icon, title, text }) => {
  const Icon = () => {
    switch (icon.replace('/static/media/', '').split('.')[0]) {
      case 'rules-initial':
        return <Coins />;
      case 'euro':
        return <Euro />;
      case 'rules-periodic':
        return <Clock />;
      case 'rules_income':
        return <Sack />;
      case 'rules-coffee':
        return <Mug />;
      case 'rules-smoking':
        return <Smoking />;
      case 'rules-football':
        return <Football />;
      default:
        return <Euro />;
    }
  };

  return (
    <GS.RowContainer alignItems="center" gap={GS.spacing.xs2}>
      <SVGIcon Icon={Icon} width={GS.spacing.xsm} height="auto" color={GS.colors.green} />
      <GS.Text>
        <GS.Span fontBold>{title}: </GS.Span>
        {text}
      </GS.Text>
    </GS.RowContainer>
  );
};
