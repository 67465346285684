import { useState } from 'react';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const BanksCollapse = ({ logo, title, updated, children, defaultVisible = true }) => {
  const [visible, setVisible] = useState(defaultVisible);

  return (
    <GS.Container>
      <GS.RowContainer
        onClick={() => setVisible((v) => !v)}
        background={!visible ? GS.colors.lightGrey : GS.colors.lightElectricGreen}
        borderRadius={!visible ? GS.radius.sm : `${GS.radius.sm} ${GS.radius.sm} 0 0`}
        alignItems="center"
        cursor="pointer"
      >
        <GS.Container
          position="relative"
          width={GS.spacing.lg}
          alignItems="center"
          justifyContent="center"
          aspectRatio="1"
          overflow="hidden"
        >
          <GS.Image position="absolute" width={`calc(100% - ${GS.spacing.xs})`} src={logo} />
        </GS.Container>
        <GS.Container alignItems="start" padding={GS.spacing.xs2} width="auto" grow={1}>
          <GS.Text fontBold>{title}</GS.Text>
          <GS.Text color={GS.colors.lightGreen} fontSize={GS.fontSizes.xs}>
            {updated}
          </GS.Text>
        </GS.Container>
        <GS.Container width="fit-content" padding={GS.spacing.xs2}>
          <SVGIcon
            Icon={Angle}
            width={GS.spacing.xs}
            height="auto"
            color={GS.colors.green}
            transform={visible ? 'rotate(180deg)' : 'rotate(0deg)'}
          />
        </GS.Container>
      </GS.RowContainer>
      <GS.Container marginTop="1px" hidden={!visible}>
        {children}
      </GS.Container>
    </GS.Container>
  );
};

export default BanksCollapse;
