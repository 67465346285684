import { UiWrapper } from '@coinscrap/webapp-core';

import EditIcon from 'components/common/EditIcon';
import MoreIcon from 'components/common/MoreIcon';

import { ReactComponent as Camera } from 'assets/svgs/camera.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

export const CameraComposed = ({ value, onClick }) => {
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  return (
    <GS.Container position="relative">
      <GS.Container
        width="100%"
        height={isDesktop ? GS.spacing.xl10 : GS.spacing.xl6}
        background={value ? `url(${value})` : GS.colors.greenStrongTransparent}
        backgroundSize="cover"
        backgroundPosition="center"
        alignItems="center"
        justifyContent="center"
      >
        {!value && (
          <SVGIcon Icon={Camera} width={GS.spacing.sm} height="auto" color={GS.colors.green} />
        )}
      </GS.Container>
      <GS.Container
        position="absolute"
        right={GS.spacing.xs2}
        bottom={GS.spacing.xs2}
        width={GS.spacing.sm}
        height={GS.spacing.sm}
        justifyContent="center"
        alignItems="center"
        background={GS.colors.green}
        borderRadius={GS.radius.full}
        zIndex={1}
        cursor="pointer"
        onClick={onClick}
      >
        {value ? <EditIcon /> : <MoreIcon />}
      </GS.Container>
    </GS.Container>
  );
};
