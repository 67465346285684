import { TargetsWrapper } from '@coinscrap/webapp-core';

const useTarget = (targetId) => {
  const { targets } = TargetsWrapper.use();

  const target = targets?.find((t) => t.id === targetId);

  return target;
};

export default useTarget;
