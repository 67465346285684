import React from 'react';

import * as GS from '../../../styles/globalStyles';
import * as S from './styles';

export const Select = ({
  show = true,
  label,
  value,
  options,
  disabled = false,
  onChange,
  border,
}) => (
  <GS.Container display={show ? 'flex' : 'none'}>
    {label && (
      <S.LabelText
        color={value === '' ? GS.colors.whiteMidTransparent : GS.colors.blackMidTransparent}
      >
        {label}
      </S.LabelText>
    )}
    <S.SelectContainer
      position="relative"
      value={value}
      border={border}
      padding={border ? GS.spacing.xs2 : 0}
    >
      <S.Select
        value={value}
        disabled={disabled}
        onChange={onChange}
        color={value === '' ? GS.colors.black : GS.colors.black}
      >
        {options.map((option, index) => (
          <S.Option key={index} value={option.identifier}>
            {option.label}
          </S.Option>
        ))}
      </S.Select>
    </S.SelectContainer>
  </GS.Container>
);
