import { RuleDefinitionsWrapper, RulesWrapper } from '@coinscrap/webapp-core';
import { rulesData } from 'data/rulesData';

const useTargetRule = (targetId, identifier) => {
  const { getRule } = RulesWrapper.use();
  const rule = getRule(identifier, targetId);
  const { ruleDefinitions } = RuleDefinitionsWrapper.use();
  const ruleData = rulesData.find((r) => r.identifier === identifier.toLowerCase());
  const ruleDefinition = ruleDefinitions?.find((r) => r.identifier === identifier);
  const result = { ...ruleData, ...ruleDefinition, ...rule };

  return result;
};

export default useTargetRule;
