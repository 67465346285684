import React from 'react';
import Calendar from 'react-calendar';
import { UiWrapper } from '@coinscrap/webapp-core';

import { ModalContainer } from '../ModalContainer/ModalContainer';
import { CloseButton } from '../BasicModal/CloseButton';

import '../../../styles/reactCalendar.css';
import * as GS from '../../../styles/globalStyles';
import * as S from './styles';

export const CalendarModal = ({ value, minDate, onClickDay, close }) => {
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  return (
    <ModalContainer bgColor={GS.colors.blackMidTransparent}>
      <S.ModalContent isDesktop={isDesktop}>
        <S.CloseButtonContainer isDesktop={isDesktop}>
          <CloseButton color={GS.colors.pink} onClick={close} />
        </S.CloseButtonContainer>
        <Calendar value={value} minDate={minDate} onClickDay={onClickDay} />
      </S.ModalContent>
    </ModalContainer>
  );
};
