import { NavigationWrapper, UiWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const Header = ({ title, children }) => {
  const { navigateTo } = NavigationWrapper.use();

  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  const backHandler = () => {
    navigateTo(routes.rules);
  };

  return (
    <GS.Container>
      {children}
      <MaxWidthWrapper>
        <GS.Container position="relative">
          <GS.RowContainer
            padding={isDesktop ? `${GS.spacing.xs} 0` : GS.spacing.xs}
            justifyContent="space-between"
            gap={GS.spacing.xs}
          >
            <GS.Container
              alignItems="start"
              justifyContent="center"
              width={GS.spacing.xs}
              cursor="pointer"
              onClick={backHandler}
            >
              <SVGIcon
                Icon={Angle}
                width={GS.spacing.xs}
                height="auto"
                color={GS.colors.green}
                transform="rotate(90deg)"
              />
            </GS.Container>
            <GS.Container justifyContent="center" width="auto" grow={1}>
              <GS.Text fontBold fontSize={GS.fontSizes.md}>
                {title}
              </GS.Text>
            </GS.Container>
            <GS.Container alignItems="end" justifyContent="center" width={GS.spacing.xs} />
          </GS.RowContainer>
        </GS.Container>
      </MaxWidthWrapper>
    </GS.Container>
  );
};

export default Header;
