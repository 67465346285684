const getCurrencyDecimals = (num, decimals, symbol) => {
  //console.log('Currency', num, typeof num);

  const number = typeof num === 'number' ? num : parseFloat(num);

  let result = '0';

  //console.log('Currency', num, decimales, exp, numFinal);
  if (number !== undefined && symbol) {
    result = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimals,
      style: 'currency',
      currency: 'EUR',
    }).format(number);
  } else if (number !== undefined) {
    result = new Intl.NumberFormat('de-DE', {
      minimumFractionDigits: decimals,
    }).format(number);
  }

  return result;
};

export default getCurrencyDecimals;
