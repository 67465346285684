import { NavigationWrapper } from '@coinscrap/webapp-core';
import hero from 'assets/images/home-hero.jpg';
import { Button } from 'components/common/Button/Button';
import routes from 'config/routes';

import * as GS from 'styles/globalStyles';

const BottomSection = () => {
  const { navigateTo } = NavigationWrapper.use();
  return (
    <GS.Container position="relative" borderRadius={GS.radius.sm} overflow="hidden">
      <GS.Container
        position="absolute"
        width="100%"
        height="100%"
        background={GS.colors.green}
        opacity={0.5}
      />
      <GS.Image src={hero} alt="hero" width="100%" height="auto" />
      <GS.Container position="absolute" height="100%" padding={GS.spacing.xs}>
        <GS.Container grow={1} width="50%">
          <GS.Title textLeft Rufina color={GS.colors.white}>
            Descubre cómo ahorrar aún más rápido.
          </GS.Title>
        </GS.Container>
        <GS.Container alignItems="center">
          <Button
            text="Empieza ahora"
            buttonStyle="pink"
            maxWidth={GS.screenSizes.xs}
            onClick={() => navigateTo(routes.targets)}
          />
        </GS.Container>
      </GS.Container>
    </GS.Container>
  );
};

export default BottomSection;
