import { createContext, useEffect, useState } from 'react';

const initialData = true;

export const MobileContext = createContext(initialData);

export const MobileProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(initialData);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 830px)');
    const changeMobile = () => {
      mediaQuery.matches ? setIsMobile(true) : setIsMobile(false);
    };
    mediaQuery.addEventListener('change', changeMobile);
    changeMobile();

    return () => mediaQuery.removeEventListener('change', changeMobile);
  }, []);

  return <MobileContext.Provider value={isMobile}>{children}</MobileContext.Provider>;
};
