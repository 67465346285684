import { NavigationWrapper } from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { ReactComponent as Bank } from 'assets/svgs/bank.svg';
import { ReactComponent as File } from 'assets/svgs/file-solid.svg';
import { ReactComponent as Info } from 'assets/svgs/info-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { FooterGridItem } from 'components/common/FooterGridItem/FooterGridItem';

import * as GS from 'styles/globalStyles';

const Footer = ({ section, children }) => {
  const { navigateTo } = NavigationWrapper.use();

  return (
    <GS.Container>
      <GS.Container alignItems="center" background={GS.colors.green} padding={GS.spacing.xs3}>
        <GS.Container maxWidth={GS.screenSizes.sm} height="100%">
          <GS.Grid numberOfColumns={3} alignItems="center" height="100%">
            <FooterGridItem
              text="Bancos"
              active={section === 'banksAndAccounts'}
              onClick={() =>
                navigateTo(routes.setting, {
                  mode: 'replace',
                  routeParams: {
                    section: 'banksAndAccounts',
                  },
                })
              }
            >
              <SVGIcon Icon={Bank} width="22px" height="auto" color={GS.colors.white} />
            </FooterGridItem>
            <FooterGridItem
              text="Histórico"
              active={section === 'historic'}
              onClick={() =>
                navigateTo(routes.setting, {
                  mode: 'replace',
                  routeParams: {
                    section: 'historic',
                  },
                })
              }
            >
              <SVGIcon Icon={File} width="16px" height="auto" color={GS.colors.white} />
            </FooterGridItem>
            <FooterGridItem
              text="FAQ's"
              active={section === 'faqs'}
              onClick={() =>
                navigateTo(routes.setting, {
                  mode: 'replace',
                  routeParams: {
                    section: 'faqs',
                  },
                })
              }
            >
              <SVGIcon Icon={Info} width="22px" height="auto" color={GS.colors.white} />
            </FooterGridItem>
          </GS.Grid>
        </GS.Container>
      </GS.Container>
      {children}
    </GS.Container>
  );
};

export default Footer;
