import { UiWrapper } from '@coinscrap/webapp-core';

import bgImage from 'assets/images/target-header-bg.png';

import { getPassedMonths, getRemainingMonths } from 'utils/getMonths';
import ProgressBar from './ProgressBar';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { ReactComponent as Edit } from 'assets/svgs/pen.svg';
import { ReactComponent as Calendar } from 'assets/svgs/calendar-days.svg';
import { ReactComponent as Check } from 'assets/svgs/check-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const Header = ({ target, section, onClickLeft, onClickRight }) => {
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  const isPaused = target?.status === 'PAUSED';

  return (
    <MaxWidthWrapper>
      <GS.Container
        position="relative"
        background={`url(${target?.image || bgImage})`}
        borderRadius={isDesktop ? GS.radius.sm : 0}
        marginTop={isDesktop ? GS.spacing.xs : 0}
        minHeight={GS.spacing.xl10}
        backgroundSize="cover"
        backgroundPosition="center"
        filter={
          target?.status === 'PAUSED' || target?.status === 'FINISHED'
            ? 'grayscale(100%)'
            : 'initial'
        }
        overflow="hidden"
      >
        {target?.image && (
          <GS.Container
            position="absolute"
            width="100%"
            height="100%"
            background={GS.colors.darkGreen}
            opacity={0.8}
            zIndex={1}
          />
        )}
        <GS.Container
          position="relative"
          alignItems="center"
          padding={isDesktop ? `${GS.spacing.xs} 0` : GS.spacing.xs}
          gap={GS.spacing.xs}
          zIndex={2}
        >
          <GS.RowContainer
            padding={isDesktop ? `0 ${GS.spacing.xs}` : 0}
            justifyContent="space-between"
            gap={GS.spacing.xs}
          >
            <GS.Container
              alignItems="start"
              justifyContent="center"
              width={GS.spacing.xs}
              cursor="pointer"
              onClick={onClickLeft}
            >
              <SVGIcon
                Icon={Angle}
                width={GS.spacing.xs}
                height="auto"
                color={GS.colors.white}
                transform="rotate(90deg)"
              />
            </GS.Container>
            <GS.Container justifyContent="center" width="auto" grow={1}>
              <GS.Text fontBold fontSize={GS.fontSizes.md} color={GS.colors.white}>
                {target?.name || 'Objetivo'}
              </GS.Text>
            </GS.Container>
            <GS.Container alignItems="end" justifyContent="center" width={GS.spacing.xs}>
              {section !== 'edit' &&
                section !== 'editBank' &&
                target?.status !== 'PAUSED' &&
                target?.status !== 'FINISHED' && (
                  <GS.Container
                    alignItems="start"
                    justifyContent="center"
                    width={GS.spacing.xs}
                    cursor="pointer"
                    onClick={onClickRight}
                  >
                    <SVGIcon
                      Icon={Edit}
                      width={GS.spacing.xs}
                      height="auto"
                      color={GS.colors.white}
                    />
                  </GS.Container>
                )}
            </GS.Container>
          </GS.RowContainer>
          <GS.Container padding={isDesktop ? `0 ${GS.spacing.xs}` : 0}>
            <ProgressBar
              target={target}
              total={target?.progress.total}
              completed={target?.progress.completed}
            />
          </GS.Container>
          <GS.RowContainer justifyContent="center" alignItems="center" gap={GS.spacing.xs2}>
            <GS.Container
              alignItems="start"
              justifyContent="center"
              width={GS.spacing.xs}
              cursor="pointer"
            >
              <SVGIcon
                Icon={Calendar}
                width={GS.spacing.xs}
                height="auto"
                color={GS.colors.white}
              />
            </GS.Container>
            <GS.Text fontBold fontSize={GS.fontSizes.xs} color={GS.colors.white}>
              {getPassedMonths(target)} de {getRemainingMonths(target) + ' meses'}
            </GS.Text>
          </GS.RowContainer>
          {isPaused && (
            <GS.Container alignItems="center">
              <GS.RowContainer
                width="fit-content"
                alignItems="center"
                justifyContent="center"
                padding={`${GS.spacing.xs2} ${GS.spacing.xs}`}
                background={GS.colors.green}
                borderRadius={GS.radius.full}
                gap={GS.spacing.xs3}
              >
                <GS.Text fontBold color={GS.colors.white}>
                  Completado
                </GS.Text>
                <GS.Container
                  alignItems="start"
                  justifyContent="center"
                  width={GS.spacing.xs}
                  cursor="pointer"
                >
                  <SVGIcon
                    Icon={Check}
                    width={GS.spacing.xs}
                    height="auto"
                    color={GS.colors.white}
                  />
                </GS.Container>
              </GS.RowContainer>
            </GS.Container>
          )}
        </GS.Container>
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default Header;
