import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('image', {
  styles: {
    default: {
      width: 'auto',
      height: 'auto',
    },

    //Atomic
    fullWidthSt: {
      width: '100%',
    },

    //Components
    targetMovementsCollapseIconSt: {
      height: '100%',
      filter:
        'invert(84%) sepia(0%) saturate(252%) hue-rotate(147deg) brightness(89%) contrast(85%)',
      transition: 'all 0.3s ease',
    },
    targetItemArrowSt: {
      width: '100%',
      filter: 'invert(93%) sepia(0%) saturate(0%) hue-rotate(221deg) brightness(96%) contrast(93%)',
    },

    summaryItemIconSt: {
      height: 19,
      width: 'auto',
      marginRight: 20,
    },
  },
});
