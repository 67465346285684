import { useState, useEffect, useRef, useCallback } from 'react';
import moment from 'moment';
import {
  NavigationWrapper,
  RulesWrapper,
  TargetsWrapper,
  UiWrapper,
  UserWrapper,
} from '@coinscrap/webapp-core';

import useInstitutionAccounts from 'hooks/useInstitutionAccounts';
import useTarget from 'hooks/useTarget';
import useTargetRules from 'hooks/useTargetRules';
import useRuleValue from 'hooks/useRuleValue';

import routes from 'config/routes';

import { isValidIBANNumber } from 'utils/Iban';

import { useInstitutions } from 'hooks/useInstitutions';

import { teams } from 'data/teamsData';
import { formatValueNumber } from 'utils/formatNumber';

import { ReactComponent as MinusCircle } from 'assets/svgs/minus-circle.svg';
import { ReactComponent as PlusCircle } from 'assets/svgs/plus-circle.svg';
import { ReactComponent as Euro } from 'assets/svgs/euro.svg';
import { ReactComponent as Mug } from 'assets/svgs/mug-circle.svg';
import { ReactComponent as Smoking } from 'assets/svgs/smoking-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import { AcceptDecline } from '../../../../common/AcceptDecline/AcceptDecline';
import RulesTooltip from 'components/common/RulesTooltip/RulesTooltip';
import Scrollable from 'components/common/Scrollable/Scrollable';
import BanksCollapse from 'components/common/BanksCollapse';
import BankItem from 'components/common/BankItem/BankItem';
import { Button } from 'components/common/Button/Button';
import { Input } from 'components/common/Input/Input';

import * as GS from 'styles/globalStyles';
import HeaderChat from 'components/common/Header/HeaderChat';

export const RuleBanks = ({ targetId, ruleId }) => {
  const { useHeader, matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  const { navigateTo } = NavigationWrapper.use();
  const { activateRule, upsertRule } = RulesWrapper.use();
  const { data: institutions } = useInstitutions();

  const containerRef = useRef(null);

  const target = useTarget(targetId);
  const rules = useTargetRules(targetId);
  const { saveTargetRulesConfiguration } = TargetsWrapper.use();
  const rule = rules.find((r) => r.identifier === ruleId);
  const identifier = rule.identifier.toUpperCase();
  const [value, setValue] = useRuleValue(rule);
  const [canActive, setCanActive] = useState(false);

  const { user } = UserWrapper.use();

  const needBank = ruleId === 'coinscrap_rounds' || ruleId === 'earnings_saving';
  const banks = useInstitutionAccounts();
  const hasBanks = banks.length > 0;
  const hasSelectedRulesAccounts =
    target?.targetRulesConfiguration?.metadata?.ruleAccounts?.length > 0;

  const [rulesAccounts, setRulesAccounts] = useState(undefined);
  useEffect(() => {
    if (target?.targetRulesConfiguration?.metadata?.ruleAccounts) {
      setRulesAccounts(
        target.targetRulesConfiguration.metadata.ruleAccounts.reduce(
          (acc, act) => ({ ...acc, [act]: true }),
          {}
        )
      );
    }
  }, [target]);

  const [selectedRulesAccounts, setSelectedRulesAccounts] = useState([]);

  useEffect(() => {
    if (rulesAccounts && banks.length > 0) {
      const filteredAccounts = [];
      banks.forEach((bank) => {
        bank?.products
          ?.filter(
            ruleId === 'coinscrap_rounds'
              ? (acc) => acc.isOwner === true
              : (acc) => acc.isOwner === true && acc.iban && isValidIBANNumber(acc.iban) === 1
          )
          ?.forEach((prod) => {
            const ruleAccount = rulesAccounts[bank.id + '-' + prod.id];
            if (ruleAccount) {
              filteredAccounts.push(prod.id);
            }
          });
      });
      setSelectedRulesAccounts(filteredAccounts);
    }
  }, [rulesAccounts]);

  const saveTargetRulesAccounts = async (newRules) => {
    await saveTargetRulesConfiguration(targetId, {
      ruleAccounts: Object.keys(newRules).filter((x) => newRules[x]),
      /* thisRuleAccounts: {
        ...target?.targetRulesConfiguration?.metadata?.thisRuleAccounts,
        [ruleId]: Object.keys(newRules).filter((x) => newRules[x]),
      }, */
    });
  };

  const onBankItemChange = (item, product, checked) => {
    const newRulesAccounts = {
      ...rulesAccounts,
      [item.id + '-' + product.id]: checked,
    };
    setRulesAccounts(newRulesAccounts);
    saveTargetRulesAccounts(newRulesAccounts);
  };

  const handleChangeValue = (val) => {
    const selectedValue = parseInt(val.replace(/\D/g, '')) || 0;
    setValue(selectedValue > 10000 ? 1000 : selectedValue);
  };

  const Icon = () => {
    if (rule.type === 'image') {
      switch (rule.image.replace('/static/media/', '').split('.')[0]) {
        case 'cafeina':
          return <Mug />;
        case 'nicotina':
          return <Smoking />;
        default:
          return <Euro />;
      }
    }
  };

  const [filteredTeams, setFilteredTeams] = useState([]);
  const [openSuggest, setOpenSuggest] = useState(false);

  useEffect(() => {
    if (rule.type === 'suggest') {
      const allTeams = teams.filter((t) => t.active === true);
      const filtered = allTeams.filter((team) =>
        team.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTeams(filtered);
      if (value.length > 2 && filtered.length > 0) {
        if (value !== filtered[0].label) {
          setOpenSuggest(true);
        } else {
          setOpenSuggest(false);
        }
      } else {
        setOpenSuggest(false);
      }
    }
  }, [value]);

  useEffect(() => {
    if (value && (!needBank || (hasBanks && hasSelectedRulesAccounts))) {
      //setCanActive(true);
      if (needBank) {
        setCanActive(selectedRulesAccounts.length > 0);
      } else if (rule.type === 'suggest') {
        const filtered = filteredTeams.filter((team) =>
          team.label.toLowerCase().includes(value.toLowerCase())
        );
        if (filtered.length > 0) {
          if (value !== filtered[0].label) {
            setCanActive(false);
          } else {
            setCanActive(true);
          }
        } else {
          setCanActive(false);
        }
      } else {
        setCanActive(true);
      }
    } else {
      setCanActive(false);
    }
  }, [value, needBank, hasBanks, hasSelectedRulesAccounts, selectedRulesAccounts]);

  const getInstitution = useCallback((id) => institutions?.find((i) => i.identifier === id), [
    institutions?.length,
  ]);

  const handleClickBack = () => {
    navigateTo(routes.target, {
      routeParams: {
        targetId,
        section: 'rules',
      },
      clearItems: ['rule'],
    });
  };

  const handleClickApplyRule = () => {
    let ruleData = [
      {
        identifier: rule.fieldDefinitions[0].identifier,
        data: { value },
      },
    ];

    if (rule.fieldDefinitions[1]?.identifier) {
      ruleData = [
        {
          identifier: rule.fieldDefinitions[0].identifier,
          data: { value },
        },
        {
          identifier: rule.fieldDefinitions[1].identifier,
          data: { value: rule.defaultAltValue },
        },
      ];
    }

    try {
      if (rule && !rule.isActive) {
        activateRule(identifier, targetId);
      }
      upsertRule(identifier, user.id, targetId, ruleData);
      handleClickBack();
    } catch (e) {
      console.error('## ERROR ACTIVANDO', e);
    }
  };

  useHeader(<HeaderChat text={rule?.name} withBack={false} />, []);

  return (
    <MaxWidthWrapper>
      <GS.Container
        ref={containerRef}
        justifyContent="start"
        alignItems="center"
        padding={GS.spacing.xs}
        gap={GS.spacing.xs}
        grow={1}
      >
        <GS.Container alignItems="center" paddingTop={GS.spacing.xs}>
          <GS.Text fontBold>{rule?.question}</GS.Text>
          <RulesTooltip
            content={
              <GS.Container gap={GS.spacing.xs}>
                {rule.tooltip.map((text) => (
                  <GS.Text key={text.substring(3, 9)} textLeft fontSize={GS.fontSizes.sm}>
                    {text}
                  </GS.Text>
                ))}
              </GS.Container>
            }
          />
        </GS.Container>

        <GS.Container alignItems="center">
          {rule.type === 'image' && (
            <SVGIcon Icon={Icon} width={GS.spacing.xl} height="auto" color={GS.colors.lightGreen} />
          )}

          {rule.type === 'increment' && (
            <GS.RowContainer justifyContent="center" alignItems="center" gap={GS.spacing.xs}>
              <GS.Container
                width="fit-content"
                cursor="pointer"
                onClick={() => setValue(value > 1 ? value - 1 : 1)}
              >
                <SVGIcon
                  Icon={MinusCircle}
                  width={GS.spacing.sm}
                  height="auto"
                  color={GS.colors.green}
                />
              </GS.Container>
              <GS.Container width={GS.spacing.xl4}>
                <GS.Title fontBold Rufina>
                  {value + (rule.subtype === 'currency' ? '€' : '%  ')}
                </GS.Title>
              </GS.Container>
              <GS.Container
                width="fit-content"
                cursor="pointer"
                onClick={() => setValue(value + 1)}
              >
                <SVGIcon
                  Icon={PlusCircle}
                  width={GS.spacing.sm}
                  height="auto"
                  color={GS.colors.green}
                />
              </GS.Container>
            </GS.RowContainer>
          )}

          {rule.type === 'currency' && (
            <GS.Container alignItems="center">
              <Input
                value={formatValueNumber(value) === '0' ? '' : formatValueNumber(value)}
                type="text"
                inputMode="numeric"
                //unit="€"
                placeholder="Cantidad"
                RightAdornment={() => (
                  <GS.Container width="fit-content" marginLeft={GS.spacing.xs}>
                    <SVGIcon Icon={Euro} width="18px" height="18px" color={GS.colors.green} />
                  </GS.Container>
                )}
                maxWidth={GS.spacing.xl6}
                border={`1px solid ${GS.colors.lightGreen}`}
                onChange={(e) => handleChangeValue(e.target?.value)}
              />
            </GS.Container>
          )}

          {rule.type === 'suggest' && (
            <GS.Container position="relative" alignItems="center">
              <Input
                value={value}
                type="text"
                textCenter
                placeholder="Escribe tu equipo"
                maxWidth={GS.spacing.xl10}
                border={`1px solid ${GS.colors.lightGreen}`}
                onChange={(e) => setValue(e.target?.value)}
              />
              {openSuggest && (
                <GS.Container
                  position="absolute"
                  top={GS.spacing.lg}
                  gap={GS.spacing.xs2}
                  width="fit-content"
                  padding={GS.spacing.xs}
                  zIndex={1}
                  background={GS.colors.lightGrey}
                >
                  {filteredTeams.map((team) => (
                    <GS.Container
                      key={team.id}
                      cursor="pointer"
                      onClick={() => {
                        setValue(team.label);
                        setOpenSuggest(false);
                      }}
                    >
                      <GS.Text>{team.label}</GS.Text>
                    </GS.Container>
                  ))}
                </GS.Container>
              )}
            </GS.Container>
          )}
        </GS.Container>

        <GS.Container gap={GS.spacing.xs2}>
          {rule?.description
            ? rule.description.map((line) => <GS.Text key={line.id}>{line}</GS.Text>)
            : ''}
        </GS.Container>

        <GS.Container gap={GS.spacing.xs}>
          <GS.Container>
            <GS.Text fontBold>
              Esta aportación es {rule.mandatory ? 'obligatoria' : 'opcional'}
            </GS.Text>
            <GS.Text targetMovementItemAmountSt>¿Quieres aplicar la regla?</GS.Text>
          </GS.Container>
        </GS.Container>

        {needBank && hasBanks && (
          <GS.Text>
            Antes de aplicar esta regla debes seleccionar la cuenta sobre la que la vas a aplicar
          </GS.Text>
        )}
        {needBank && !hasBanks && (
          <GS.Text>
            Antes de aplicar esta regla debes añadir un banco y seleccionar la cuenta sobre la que
            la vas a aplicar
          </GS.Text>
        )}

        <Scrollable arrowBottom={isDesktop}>
          {needBank && hasBanks && (
            <GS.Container alignItems="center" gap={GS.spacing.xs}>
              <GS.Container>
                {banks.map((bank) => (
                  <BanksCollapse
                    key={bank?.name}
                    logo={
                      bank.institutionIdentifier
                        ? getInstitution(bank.institutionIdentifier)?.logo || bank?.image
                        : bank?.image
                    }
                    title={bank?.name}
                    /* defaultVisible={banks.length === 1} */
                    updated={`Actualizado hace ${moment((bank.products || [])[0]?.lastImportDate)
                      .locale('es')
                      .fromNow()}`}
                  >
                    {bank?.products
                      ?.filter(
                        ruleId === 'coinscrap_rounds'
                          ? (account) => account.isOwner === true
                          : (account) =>
                            account.isOwner === true &&
                            account.iban &&
                            isValidIBANNumber(account.iban) === 1
                      )
                      ?.map((account) => (
                        <BankItem
                          key={bank?.name + account?.name + account?.identifier}
                          type={account?.type}
                          name={account?.name}
                          number={`**** ${account?.identifier.slice(-4)}`}
                          isActive={rulesAccounts?.[bank.id + '-' + account.id]}
                          onChange={(checked) => onBankItemChange(bank, account, checked)}
                        />
                      ))}
                  </BanksCollapse>
                ))}
              </GS.Container>
              <GS.Container alignItems="center" gap={GS.spacing.sm}>
                <AcceptDecline
                  onDecline={() => handleClickBack()}
                  onAccept={canActive ? () => handleClickApplyRule() : undefined}
                />
                <Button
                  text="Añadir nuevo banco"
                  buttonStyle="minimal"
                  width={GS.spacing.xl7}
                  onClick={() => {
                    navigateTo(routes.banksStart, {
                      queryParams: {
                        from: {
                          name: routes.target,
                          routeParams: {
                            section: 'ruleBanks',
                            targetId,
                            rule: ruleId,
                          },
                        },
                      },
                      clearItems: ['rule'],
                    });
                  }}
                />
              </GS.Container>
            </GS.Container>
          )}

          {needBank && !hasBanks && (
            <GS.Container alignItems="center" gap={GS.spacing.sm}>
              <Button
                text="Añadir nuevo banco"
                buttonStyle="minimal"
                width={GS.spacing.xl7}
                onClick={() => {
                  navigateTo(routes.banksStart, {
                    queryParams: {
                      from: {
                        name: routes.target,
                        routeParams: {
                          section: 'ruleBanks',
                          targetId,
                          rule: ruleId,
                        },
                      },
                    },
                    clearItems: ['rule'],
                  });
                }}
              />
              <AcceptDecline
                onDecline={() => handleClickBack()}
                onAccept={canActive ? () => handleClickApplyRule() : false}
              />
            </GS.Container>
          )}

          {!needBank && (
            <GS.Container alignItems="center" gap={GS.spacing.sm}>
              <AcceptDecline
                onDecline={() => handleClickBack()}
                onAccept={canActive ? () => handleClickApplyRule() : false}
              />
            </GS.Container>
          )}
        </Scrollable>
      </GS.Container>
    </MaxWidthWrapper>
  );
};
