import styled, { createGlobalStyle } from 'styled-components/macro';

export const spacing = {
  px: '1px',
  xs4: '0.05rem',
  xs3: '0.25rem',
  xs2: '0.5rem',
  xs: '1rem',
  xsm: '1.5rem',
  sm: '2rem',
  sm2: '2.5rem',
  md: '3rem',
  lg: '4rem',
  xl: '5rem',
  xl2: '6rem',
  xl3: '7rem',
  xl4: '8rem',
  xl5: '9rem',
  xl6: '10rem',
  xl7: '11rem',
  xl8: '12rem',
  xl9: '13rem',
  xl10: '14rem',
  xl11: '15rem',
  xl12: '16rem',
};

export const sizes = {
  walletCard: `calc(50% - ${spacing.xxs})`,
  modal: '600px',
};

export const screenSizes = {
  xs: '320px',
  sm: '640px',
  md: '768px',
  lg: '1024px',
  zur: '1166px',
  xl: '1280px',
  xl2: '1536px',
};

export const fontFamilies = {
  Mutualidad: "'Mutualidad', sans-serif",
  Rufina: "'Rufina', serif",
};

export const fontSizes = {
  xs3: '0.50rem',
  xs2: '0.60rem',
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xl2: '1.5rem',
  xl3: '1.75rem',
  xl4: '2rem',
  xl5: '3rem',
  xl6: '4rem',
};

export const fontWeights = {
  light: 300,
  regular: 400,
  bold: 600,
  black: 700,
};

export const colors = {
  white: '#ffffff',
  black: '#000000',
  grey: '#525558',
  modalGrey: '#efefef',
  lightGrey: '#eeeeee',
  lighterGrey: '#fbfbfb',
  darkGrey: '#191b1c',
  green: '#003828',
  lightGreen: '#7f9b93',
  midGreen: '#66887e',
  darkGreen: '#001a13',
  electricGreen: '#b9edaa',
  lightElectricGreen: '#e5fdde',
  violet: '#9d7b93',
  lightViolet: '#cebdc9',
  darkViolet: '#5a4153',
  red: '#e33d21',
  blue: '#00a2cf',
  darkBlue: '#004663',
  lightBlue: '#e5f9ff',
  pink: '#d1a4c4',
  yellow: '#d6c87e',
  whiteStrongTransparent: 'rgba(255, 255, 255, 0.2)',
  whiteMidTransparent: 'rgba(255, 255, 255, 0.6)',
  whiteLightTransparent: 'rgba(255, 255, 255, 0.8)',
  blackStrongTransparent: 'rgba(0, 0, 0, 0.2)',
  blackMidTransparent: 'rgba(0, 0, 0, 0.6)',
  blackLightTransparent: 'rgba(0, 0, 0, 0.8)',
  greenStrongTransparent: 'rgba(0, 56, 40, 0.2)',
  greenMidTransparent: 'rgba(0, 56, 40, 0.6)',
  greenLightTransparent: 'rgba(0, 56, 40, 0.8)',
  pinkStrongTransparent: 'rgba(209, 164, 196, 0.2)',
  trasparent: 'transparent',
};

export const gradientColors = {
  grey: `linear-gradient(200deg, ${colors.lightGrey} -18.99%, ${colors.grey} 122.15%)`,
  transparentGray: `linear-gradient(200deg,rgb(159 159 159 / 50%) -18.99%,rgb(82 85 88 / 50%) 122.15%)`,
  violet: `linear-gradient(200deg, ${colors.lightViolet} -18.99%, ${colors.violet} 122.15%)`,
  green: `linear-gradient(200deg, ${colors.lightGreen} -18.99%, ${colors.green} 122.15%)`,
};

export const borders = {
  dashedGrey: `2px dashed ${colors.blackMidTransparent}`,
};

export const radius = {
  xs: '2px',
  sm: '4px',
  md: '10px',
  lg: '15px',
  xl: '20px',
  full: '999px',
};

export const shadows = {
  xs: '0px 0px 7px -2px rgb(0 0 0 / 50%)',
  sm: '0px 0px 7px -2px rgb(0 0 0 / 50%)',
  md: '0px 0px 7px -2px rgb(0 0 0 / 50%)',
  lg: '0px 0px 7px -2px rgb(0 0 0 / 50%)',
  xl: '0px 0px 7px -2px rgb(0 0 0 / 50%)',
  full: '0px 0px 7px -2px rgb(0 0 0 / 50%)',
};

export const Container = styled.div`
  ${({ position }) => position && `position: ${position}`};
  ${({ top }) => top && `top: ${top}`};
  ${({ right }) => right && `right: ${right}`};
  ${({ bottom }) => bottom && `bottom: ${bottom}`};
  ${({ left }) => left && `left: ${left}`};
  ${({ width }) => (width ? `width: ${width}` : 'width: 100%')};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ fullHeight }) => fullHeight && `height: calc(100vh - 150px)`};
  display: ${({ display }) => display || 'flex'};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ grow }) => grow && `flex-grow: ${grow}`};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ wrap }) => wrap && `flex-wrap: ${wrap}`};
  ${({ gap }) => gap && `gap: ${gap}`};
  margin: ${({ margin }) => (margin ? `${margin}` : '0')};
  padding: ${({ padding }) => (padding ? `${padding}` : '0')};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
  ${({ paddingLeft }) => paddingLeft && `padding-left: ${paddingLeft}`};
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}`};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`};
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`};
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight}`};
  ${({ zIndex }) => zIndex && `z-index: ${zIndex}`};
  ${({ background }) => background && `background: ${background}`};
  ${({ backgroundSize }) => backgroundSize && `background-size: ${backgroundSize}`};
  ${({ backgroundPosition }) => backgroundPosition && `background-position: ${backgroundPosition}`};
  ${({ transform }) => transform && `transform: ${transform}`};
  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ overflowY }) => overflowY && `overflow-y: scroll`};
  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ border }) => border && `border: ${border}`};
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}`};
  ${({ borderTop }) => borderTop && `border-top: ${borderTop}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft}`};
  ${({ borderRight }) => borderRight && `border-right: ${borderRight}`};
  ${({ opacity }) => opacity && `opacity: ${opacity}`};
  ${({ filter }) => filter && `filter: ${filter}`};
  ${({ hidden }) => hidden && `display: none`};
  ${({ color }) => color && `color: ${color}`};
  ${({ transition }) => transition && `transition: ${transition}`};
  ${({ linearBg }) =>
    linearBg &&
    `background-image: repeating-linear-gradient( ${linearBg.direction === 'right' ? '-45deg' : '45deg'
    }, ${linearBg.base}, ${linearBg.base} ${linearBg.width}px, ${linearBg.color} ${linearBg.width
    }px, ${linearBg.color} ${linearBg.width * 2}px)`};
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`};
  ${({ boxShadow }) => boxShadow && `box-shadow: ${boxShadow};`}
`;

export const RowContainer = styled(Container)`
  flex-direction: row;
`;

export const Grid = styled.div`
  align-items: ${({ alignItems }) => alignItems || 'start'};
  display: grid;
  gap: ${({ gap }) => gap || '10px'};
  grid-template-columns: ${({ numberOfColumns, sizeOfColumns }) =>
    `repeat(${numberOfColumns || '2'}, ${sizeOfColumns || '1fr'})`};
  justify-items: center;
  ${({ width }) => width && `width: ${width}`};
  ${({ height }) => height && `height: ${height}`};
`;

export const WithFooter = styled.div`
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};
  ${({ height }) => height && `height: ${height}`};
  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight}` : 'min-height: calc(100% - 80px)')};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};
  ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}`};
`;

export const Text = styled.p`
  color: ${({ color }) => color || colors.green};
  font-family: ${({ fontFamily }) => fontFamily || fontFamilies.Mutualidad};
  font-size: ${({ fontSize }) => fontSize || fontSizes.sm};
  line-height: ${({ lineHeight }) => lineHeight || 1.2};
  ${({ fontBold }) => fontBold && `font-weight: ${fontWeights.bold}`};
  ${({ fontLight }) => fontLight && `font-weight: ${fontWeights.light}`};
  ${({ fontRegular }) => fontRegular && `font-weight: ${fontWeights.regular}`};
  ${({ fontMedium }) => fontMedium && `font-weight: ${fontWeights.regular}`};
  margin: 0px;
  ${({ Rufina }) => Rufina && `font-family: ${fontFamilies.Rufina}`};
  ${({ Mutualidad }) => Mutualidad && `font-family: ${fontFamilies.Mutualidad}`};
  opacity: ${({ opacity }) => opacity || 1};
  text-align: center;
  ${({ textLeft }) => textLeft && `text-align: left`};
  ${({ textRight }) => textRight && `text-align: right`};
  ${({ paddingBottom }) => (paddingBottom ? `padding-bottom: ${paddingBottom}` : '0')};
  ${({ paddingLeft }) => (paddingLeft ? `padding-left: ${paddingLeft}` : '0')};
  ${({ paddingRight }) => (paddingRight ? `padding-right: ${paddingRight}` : '0')};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop}` : '0')};
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}` : '0')};
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}` : '0')};
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight}` : '0')};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : '0')};
  ${({ underline }) => underline && 'text-decoration: underline'};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase'};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
  ${({ display }) => display && `display: ${display}`};
`;

export const Title = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || fontSizes.xl2};
  font-weight: ${({ fontWeight }) => fontWeight || fontWeights.bold};
`;

export const HeaderTitle = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || fontSizes.md};
  font-weight: ${({ fontWeight }) => fontWeight || fontWeights.bold};
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}` : '0')};
`;

export const LightTitle = styled(Text)`
  font-size: ${({ fontSize }) => fontSize || fontSizes.xl2};
  font-weight: ${({ fontWeight }) => fontWeight || fontWeights.light};
`;

export const SubTitle = styled(Text)`
  color: ${({ color }) => color || colors.blackMidTransparent};
  font-size: ${({ fontSize }) => fontSize || fontSizes.sm};
  font-weight: ${({ fontWeight }) => fontWeight || fontWeights.regular};
`;

export const ExplanatoryText = styled(Text)`
  color: ${({ color }) => color || colors.blackMidTransparent};
  font-size: ${fontSizes.xs};
`;

export const FooterText = styled(Text)`
  cursor: pointer;
  font-size: ${fontSizes.sm};
  font-weight: ${fontWeights.bold};
  text-decoration: underline;
`;

export const Span = styled.span`
  color: ${({ color }) => color || 'currentColor'};
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  ${({ fontRegular }) => fontRegular && `font-weight: ${fontWeights.regular}`};
  ${({ fontBold }) => fontBold && `font-weight: ${fontWeights.bold}`};
  ${({ fontLight }) => fontLight && `font-weight: ${fontWeights.light}`};
  ${({ underline }) => underline && 'text-decoration: underline'}
`;

export const Link = styled.a`
  color: ${({ color }) => color || 'currentColor'};
  font-size: ${({ fontSize }) => fontSize || fontSizes.sm};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')}
    ${({ fontRegular }) => fontRegular && `font-weight: ${fontWeights.regular}`};
  ${({ fontBold }) => fontBold && `font-weight: ${fontWeights.bold}`};
  ${({ fontLight }) => fontLight && `font-weight: ${fontWeights.light}`};
`;

export const Image = styled.img`
  ${({ position }) => position && `position: ${position}`};
  ${({ top }) => top && `top: ${top}`};
  ${({ left }) => left && `left: ${left}`};
  ${({ marginBottom }) => (marginBottom ? `margin-bottom: ${marginBottom}` : '0')};
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}` : '0')};
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight}` : '0')};
  ${({ marginTop }) => (marginTop ? `margin-top: ${marginTop}` : '0')};
  ${({ paddingBottom }) => (paddingBottom ? `padding-bottom: ${paddingBottom}` : '0')};
  ${({ paddingLeft }) => (paddingLeft ? `padding-left: ${paddingLeft}` : '0')};
  ${({ paddingRight }) => (paddingRight ? `padding-right: ${paddingRight}` : '0')};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop}` : '0')};
  ${({ width }) => width && `width: ${width}`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}`};
  ${({ height }) => height && `height: ${height}`};
  ${({ maxHeight }) => maxHeight && `max-height: ${maxHeight}`};
  ${({ borderRadius }) => (borderRadius ? `border-radius: ${borderRadius}` : '0')};
  ${({ objectFit }) => objectFit && `object-fit: ${objectFit}`};
  ${({ transform }) => transform && `transform: ${transform}`};
  ${({ transition }) => transition && `transition: ${transition}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ blackAndWhite }) => blackAndWhite && `filter: brightness(0%)`};
  ${({ pointer }) => pointer && `cursor: pointer`};
  ${({ opacity }) => opacity && `opacity: ${opacity}`};
  ${({ filter }) => filter && `filter: ${filter}`};
  ${({ cursor }) => cursor && `cursor: ${cursor}`};
`;

export const MainImage = styled(Image)`
  max-height: ${({ maxHeight }) => maxHeight || '30vh'};
  width: auto;
`;

export const DashedLine = styled.div`
  border-bottom-color: ${({ color }) => color || colors.darkGrey};
  border-bottom-style: dashed;
  border-bottom-width: ${({ height }) => (height ? `${height - 1}px` : '1px')};
  height: ${({ height }) => (height ? `${height}px` : '2px')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  ${({ paddingTop }) => (paddingTop ? `padding-top: ${paddingTop}` : '0')};
  ${({ paddingBottom }) => (paddingBottom ? `padding-bottom: ${paddingBottom}` : '0')};
`;

export const BackIcon = styled.img`
  cursor: pointer;
  line-height: 0;
`;

export const GlobalStyles = createGlobalStyle`
  body {
    font-family: ${fontFamilies.Mutualidad}};
    font-size: ${fontSizes.sm};
    font-weight: ${fontWeights.regular};
    background-color: ${colors.white};
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  .MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none !important;
  }
`;

/* export const GlobalStyle = createGlobalStyle`



body, [class^="MuiTypography-"] {
  margin: 0;
  font-family: "Montserrat", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-primary);

   --color-primary: #012B38;
   --color-secondary: #00E7CC;
   --color-white: #F9F9F9;
   --color-black: #333333;
   --color-lightGrey: #ebebee;
   --color-lightPurple: rgba(194,185,224,1);
   --color-yellow: rgb(245, 212, 77);
   --color-red: #E33D21;
   --color-blue: #8cdace;
   --color-darkBlue: #3b3667;
   --color-green: #006E94;
   
   --border-radius: 25px;

   --shadow: 0px 15px 40px 10px rgba(50, 44, 91, 0.40);

   --gradient-purple: linear-gradient(180deg, rgba(50, 44, 91, 1) 0%, rgba(133, 117, 224, 1) 100%);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}
`; */
