import React, { useState } from 'react';

import { Chart as ChartJS } from 'chart.js';

const Chart = (props) => {
  const [canvasRef, setCanvasRef] = useState();
  let mychart = null;

  if (canvasRef && props.data) {
    // eslint-disable-next-line no-unused-vars
    mychart = new ChartJS(canvasRef, {
      data: props.data,
      options: props.options,
    });
  }

  return (
    <canvas
      style={{
        height: props.height || '100%',
        width: props.width || '100%',
        ...props.style,
      }}
      ref={(ref) => setCanvasRef(ref)}
    />
  );
};

export default Chart;
