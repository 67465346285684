import React from 'react';
import * as GS from '../../../styles/globalStyles';

export const SVGIcon = ({
  Icon,
  color,
  width,
  height,
  marginLeft,
  marginRight,
  transform,
  transition,
}) => (
  <GS.Container
    width="auto"
    marginLeft={marginLeft || false}
    marginRight={marginRight || false}
    transform={transform || false}
    transition={transition || false}
  >
    <GS.Container
      color={color || GS.colors.black}
      width={width || '20px'}
      height={height || '20px'}
    >
      <Icon />
    </GS.Container>
  </GS.Container>
);
