import React, { useState, useEffect } from 'react';

import {
  FinancialWrapper,
  InstitutionsWrapper,
  NavigationWrapper,
  Page,
  UiWrapper,
} from '@coinscrap/webapp-core';

import FooterNext from 'components/common/FooterNext';
import HeaderChat from 'components/common/Header/HeaderChat';
import routes from 'config/routes';
import { mapFieldType } from 'utils/mapFieldType';

import { ReactComponent as Like } from 'assets/svgs/like.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { Input } from 'components/common/MainInput/styles';
import { Select } from 'components/common/Select/Select';
import { BasicModal } from 'components/common/BasicModal/BasicModal';
import { Button } from 'components/common/Button/Button';
import GenericModal from 'components/common/GenericModal';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from '../../../styles/globalStyles';

export const BankConnect = () => {
  const { institutions } = InstitutionsWrapper.use();
  const { createOrUpdateCredentialsInstitution } = FinancialWrapper.use();
  const { useHeader, useFooter, openModal } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const identifier = Page.use().params.idBank;

  const [allInstitutions, setAllInstitutions] = useState(false);
  useEffect(() => {
    if (institutions) {
      setAllInstitutions(institutions);
    }
  }, [institutions]);

  const [selectedInstitution, setSelectedInstitution] = useState(false);
  useEffect(() => {
    if (allInstitutions) {
      const selected = allInstitutions.find((i) => i.identifier === identifier);
      //console.log('selectedInstitution', selected);
      setSelectedInstitution(selected);
    }
  }, [identifier, allInstitutions]);

  const institution = (institutions || []).find((i) => i.identifier === identifier) || null;

  const [displayedFields, setDisplayedFields] = useState(false);
  const [showInitialFields, setShowInitialFields] = useState(true);

  const [notOptionalInputs, setNotOptionalInputs] = useState(null);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);

  const [loginMode, setLoginMode] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState(false);

  const [formValues, setFormValues] = useState({ 0: {} });

  const openBasicModal = (text) => {
    setModalErrorMessage(text);
    setIsModalOpen(true);
  };

  const startSessionHandler = async () => {
    try {
      const mappedValues = Object.keys(formValues[loginMode] || {}).reduce((acc, cur) => {
        const itemValue = formValues[loginMode][cur];
        return { ...acc, [cur]: itemValue === '0' ? 0 : itemValue };
      }, {});

      await createOrUpdateCredentialsInstitution(
        selectedInstitution.identifier,
        {
          method: selectedInstitution?.authenticationMethods[loginMode].identifier,
          values: mappedValues,
        },
        { mode: 'AWAIT_FULL_PROCESS' },
        (message, otpSetter, discard) => {
          openModal(
            (close, context, setContext) => (
              <GenericModal padding={0}>
                <GS.Container
                  background={GS.colors.white}
                  padding={GS.spacing.xs}
                  alignItems="center"
                  gap={GS.spacing.xs}
                >
                  <GS.Container gap={GS.spacing.xs2} alignItems="center" justifyContent="center">
                    <GS.Text fontBold fontSize={GS.fontSizes.lg} color={GS.colors.green}>
                      {message}
                    </GS.Text>
                    <Input
                      label="OTP"
                      value={context.value}
                      onChange={(e) => setContext({ value: e.target?.value })}
                    />
                  </GS.Container>
                  <GS.RowContainer gap={GS.spacing.xs2}>
                    <Button
                      text="Cancelar"
                      buttonStyle="invertedWithLine"
                      minWidth="unset"
                      height={GS.spacing.sm}
                      onClick={() => {
                        discard();
                        close();
                      }}
                    />
                    <Button
                      text="Aceptar"
                      minWidth="unset"
                      height={GS.spacing.sm}
                      onClick={() => {
                        otpSetter(context.value);
                        close();
                      }}
                    />
                  </GS.RowContainer>
                </GS.Container>
              </GenericModal>
            ),
            { disableClickAway: true }
          );
        }
      );

      openModal((close) => (
        <GenericModal padding={0}>
          <GS.Container
            background={GS.colors.white}
            borderRadius={GS.radius.sm}
            padding={GS.spacing.xs}
            alignItems="center"
            gap={GS.spacing.xs}
          >
            <GS.Container gap={GS.spacing.xs2} alignItems="center" justifyContent="center">
              <SVGIcon Icon={Like} width={GS.spacing.lg} height="auto" color={GS.colors.green} />
              <GS.Text fontBold fontSize={GS.fontSizes.lg} color={GS.colors.green}>
                ¡Enhorabuena, tu banco ha sido vinculado!
              </GS.Text>
              <Button
                text="Ok"
                width={GS.spacing.xl6}
                minWidth="unset"
                height={GS.spacing.sm}
                onClick={() => {
                  navigateTo(routes.banksAwait, {
                    routeParams: {
                      idBank: identifier,
                    },
                    mode: 'replace',
                  });
                  close();
                }}
              />
            </GS.Container>
          </GS.Container>
        </GenericModal>
      ));
    } catch (e) {
      const error = e;

      if (error.message && error.type === 'REQUIRED_FIELDS') {
        const newDisplayedFields = error.payload.fields.map((field) => ({
          code: field.code,
          description: field.description,
          isOptional: field.isOptional,
          label: field.label,
          type: field.type,
          options: field?.options || [],
          value:
            /* eslint-disable-next-line no-nested-ternary */
            mapFieldType(field.type) === 'select'
              ? field.options[0].identifier === 0
                ? '0'
                : field.options[0].identifier
              : '',
        }));
        openBasicModal(error.message);
        setShowInitialFields(false);
        setDisplayedFields([...displayedFields, ...newDisplayedFields]);
        newDisplayedFields.forEach((field) =>
          setFormValues({
            ...formValues,
            [loginMode]: { ...formValues[loginMode], [field.code]: field.value },
          })
        );
        console.log('newDisplayedFields', displayedFields, newDisplayedFields);
      } else {
        openBasicModal('Error iniciando sesión, vuelva a intentarlo');
      }
    }
  };

  const handleClickFooterButton = () => {
    console.log('handleClickFooterButton');
    startSessionHandler();
  };

  useEffect(() => {
    if (selectedInstitution) {
      const notOptionalFields = selectedInstitution.authenticationMethods[loginMode].fields.filter(
        (field) => field.isOptional === false
      );
      const initialFields = notOptionalFields.map((field) => ({ ...field, initial: true }));
      console.log('initialFields', initialFields);
      setDisplayedFields(initialFields);
    }
  }, [selectedInstitution, loginMode]);

  useEffect(() => {
    if (displayedFields.length > 0) {
      setNotOptionalInputs(displayedFields.reduce((acc, cur) => [...acc, cur.code], []));
    }
  }, [displayedFields]);

  useEffect(() => {
    if (notOptionalInputs) {
      const canSave = notOptionalInputs.every((item) => {
        const itemValue = Object.keys(formValues[loginMode] || {})
          .filter((key) => {
            const itemValueKey = formValues[loginMode][key];
            return itemValueKey === 0 ? '0' : formValues[loginMode][key];
          })
          .includes(item);
        return itemValue;
      });
      console.log('notOptionalInputs', notOptionalInputs, formValues, canSave);
      setAllFieldsFilled(canSave);
    }
  }, [formValues, loginMode, notOptionalInputs]);

  useHeader(
    <HeaderChat text="Bancos" onBack={() => navigateTo(routes.banksSelect, { mode: 'replace' })} />
  );

  useFooter(
    <FooterNext
      disabled={!institution || !allFieldsFilled}
      onClick={() => handleClickFooterButton()}
    />,
    [institution, allFieldsFilled, formValues]
  );

  return (
    institution && (
      <MaxWidthWrapper>
        <GS.Container
          height="100%"
          justifyContent="start"
          alignItems="center"
          padding={GS.spacing.xs}
          overflow="hidden"
          overflowY
          position="relative"
        >
          <GS.Container gap={GS.spacing.xs}>
            <GS.Text fontBold fontSize={GS.fontSizes.lg}>
              Conecta tu banco
            </GS.Text>
            <GS.Container
              position="relative"
              alignItems="center"
              justifyContent="center"
              aspectRatio="21/9"
              overflow="hidden"
              background={GS.colors.green}
            >
              <GS.Image position="absolute" src={institution?.image ? institution.image : ''} />
            </GS.Container>
            <GS.Text color={GS.colors.green}>
              Por favor, introduce el usuario y contraseña con los que normalmente accedes a tu
              banco.
            </GS.Text>

            <GS.Container gap={GS.spacing.xs2}>
              {displayedFields &&
                displayedFields.map((field, index) => (
                  <GS.Container
                    key={index}
                    display={
                      (field.initial && showInitialFields) || !field.initial ? 'flex' : 'none'
                    }
                  >
                    <GS.Container>
                      {mapFieldType(field.type) !== 'select' && (
                        <Input
                          show={(field.initial && showInitialFields) || !field.initial}
                          key={`${field.code}-${index}`}
                          label={field.label}
                          id={field.code}
                          type={mapFieldType(field.type)}
                          password={mapFieldType(field.type) === 'password'}
                          placeholder={field.description || field.label}
                          value={(formValues[loginMode] && formValues[loginMode][field.code]) || ''}
                          border={`1px solid ${GS.colors.green}`}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [loginMode]: {
                                ...formValues[loginMode],
                                [field.code]: e.target.value,
                              },
                            })
                          }
                        />
                      )}
                      {mapFieldType(field.type) === 'select' && (
                        <Select
                          key={`${field.code}-${index}`}
                          label={field.label}
                          id={field.code}
                          type="select"
                          border={`1px solid ${GS.colors.green}`}
                          value={
                            (formValues[loginMode] && formValues[loginMode][field.code]) ||
                            field?.options[0]?.identifier
                          }
                          options={field.options}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [loginMode]: {
                                ...formValues[loginMode],
                                [field.code]: e.target.value,
                              },
                            })
                          }
                          paddingBottom={GS.spacing.xxs}
                        />
                      )}
                    </GS.Container>
                  </GS.Container>
                ))}
              {(institution.authenticationMethods || []).length > 1 && (
                <GS.Container justifyContent="start" alignItems="center" paddingTop={GS.spacing.xs}>
                  <GS.RowContainer alignItems="center" width="fit-content" gap={GS.spacing.xs}>
                    <GS.RowContainer width="fit-content" gap={GS.spacing.xs2}>
                      <GS.Text>Método de inicio:</GS.Text>
                      <GS.Text fontBold>
                        {institution.authenticationMethods[loginMode].name}
                      </GS.Text>
                    </GS.RowContainer>
                    <Button
                      text="Cambiar"
                      withIcon={false}
                      width="fit-content"
                      minWidth="unset"
                      height={GS.spacing.sm}
                      onClick={() =>
                        openModal((close) => (
                          <GS.Container background={GS.colors.white} padding={GS.spacing.xs}>
                            {institution.authenticationMethods.map((m, i) => (
                              <Button
                                key={i}
                                text={m.name}
                                minWidth="unset"
                                height={GS.spacing.sm}
                                onClick={() => {
                                  setLoginMode(i);
                                  close();
                                }}
                              />
                            ))}
                          </GS.Container>
                        ))
                      }
                    >
                      Cambiar
                    </Button>
                  </GS.RowContainer>
                </GS.Container>
              )}
            </GS.Container>
          </GS.Container>

          {isModalOpen && (
            <BasicModal
              text={modalErrorMessage}
              bgColor={GS.colors.blackMidTransparent}
              close={() => setIsModalOpen(false)}
            />
          )}
        </GS.Container>
      </MaxWidthWrapper>
    )
  );
};
