import { InstitutionAccountsWrapper } from '@coinscrap/webapp-core';
import { filterInstitutions } from 'utils/filterInstitutions';
import moment from 'moment';

const useInstitutionAccounts = () => {
  const { institutionAccounts } = InstitutionAccountsWrapper.use();
  const banks = filterInstitutions(institutionAccounts) || [];
  return banks.map((bank) => ({
    ...bank,
    outDated:
      moment.duration(moment().diff((bank.products || [])[0]?.lastImportDate)).asHours() > 30,
  }));
};

export default useInstitutionAccounts;
