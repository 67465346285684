import {
  UiWrapper,
  NavigationWrapper,
  TargetsWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import Header from './Header';
import Footer from './Footer';
import { HistoricItem } from './HistoricItem';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';
import { useEffect, useState } from 'react';
import { Spinner } from 'components/common/Spinner/Spinner';

const Historic = ({ title, section }) => {
  const { useHeader, useFooter } = UiWrapper.use();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  const { navigateTo } = NavigationWrapper.use();

  const { targets } = TargetsWrapper.use();

  const [isLoading, setIsLoading] = useState(true);

  const [finishedTargets, setFinishedTargets] = useState(undefined);
  useEffect(() => {
    if (targets?.length > 0) {
      const fTargets = targets?.filter((t) => t.status === 'FINISHED');
      if (fTargets.length > 0) {
        setFinishedTargets(fTargets);
        setIsLoading(false);
      } else if (fTargets.length === 0) {
        setIsLoading(false);
      }
    }
  }, [targets]);

  useHeader(
    <Header title={title}>
      {isDesktop && (
        <Footer
          backHandler={() => navigateTo(routes.settings)}
          section={section}
        />
      )}
    </Header>,
    [isDesktop]
  );

  useFooter(!isDesktop && <Footer section={section} />);

  return (
    <MaxWidthWrapper /* maxWidth={isDesktop ? GS.screenSizes.lg : GS.screenSizes.sm} */
    >
      <GS.Container
        padding={isDesktop ? `0 0 ${GS.spacing.xs} 0` : `0 ${GS.spacing.xs}`}
      >
        {isLoading && (
          <GS.Container
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner size={GS.spacing.xsm} />
          </GS.Container>
        )}
        {!isLoading &&
          (finishedTargets === undefined || finishedTargets?.length === 0) && (
            <GS.Container
              height="100%"
              justifyContent="center"
              alignItems="center"
              padding={GS.spacing.xs}
            >
              <GS.Container grow={1} justifyContent="center">
                <GS.Text>No hay objetivos históricos.</GS.Text>
              </GS.Container>
            </GS.Container>
          )}
        {!isLoading && finishedTargets?.length > 0 && (
          <GS.Grid gap={GS.spacing.xs2} numberOfColumns={isDesktop ? 2 : 1}>
            {finishedTargets?.map((target) => (
              <HistoricItem key={target?.id} target={target} />
            ))}
          </GS.Grid>
        )}
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default Historic;
