import React from 'react';

import * as GS from 'styles/globalStyles';

export const BankCard = ({ institution, selected, onClick }) => (
  <GS.Container
    position="relative"
    alignItems="center"
    justifyContent="center"
    background={selected ? GS.colors.lightGreen : GS.colors.lightGrey}
    aspectRatio="16/9"
    overflow="hidden"
    onClick={() => onClick && onClick()}
  >
    <GS.Image
      position="absolute"
      src={institution?.logo ? institution.logo : ''}
      width={GS.spacing.xl3}
    />
  </GS.Container>
);
