import { NavigationWrapper, RulesWrapper } from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import CircularProgress from 'components/common/CircularProgress';
import { HistoricItemData } from './HistoricItemData';

import * as GS from 'styles/globalStyles';

export const HistoricItem = ({ target }) => {
  const { navigateTo } = NavigationWrapper.use();

  const { rules } = RulesWrapper.use();
  const activeRules = rules?.filter((r) => r.targetId === target?.id && r.isActive);
  const progress = (target?.progress.completed / target?.progress.total) * 100 || 0;

  return (
    <GS.Container
      background={GS.colors.lightGrey}
      borderRadius={GS.radius.sm}
      padding={GS.spacing.xs2}
      gap={GS.spacing.xs2}
      onClick={() => {
        navigateTo(routes.target, {
          routeParams: { targetId: target?.id, section: 'graph' },
        });
      }}
    >
      <GS.RowContainer
        position="relative"
        alignItems="center"
        gap={GS.spacing.xs2}
        filter={target?.status === 'PAUSED' ? 'grayscale(100%)' : 'initial'}
        cursor="pointer"
      >
        <GS.Container width="fit-content">
          <CircularProgress value={progress} />
        </GS.Container>
        <GS.Container width="auto" grow={1}>
          <HistoricItemData target={target} activeRules={activeRules} />
        </GS.Container>
        <GS.Container width="fit-content">
          <SVGIcon
            Icon={Angle}
            width={GS.spacing.xs}
            height="auto"
            color={GS.colors.green}
            transform="rotate(270deg)"
          />
        </GS.Container>
      </GS.RowContainer>
    </GS.Container>
  );
};
