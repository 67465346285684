const mockFaqs = [
  {
    id: 1,
    name: 'Empezando',
    faqs: [
      {
        name: '¿En qué consiste #YoSíAhorro?',
        text: `Es un servicio disponible a través de la App Mutualidad Abogacía y del área personal de la página web de Mutualidad de la Abogacía, que te permite establecer reglas de ahorro para ahorrar de forma automática y sin que te des cuenta. A través de estas reglas se genera un ahorro virtual que semanalmente (llegando al mínimo de 30€) se transfiere a tu Sistema de Ahorro  Flexible  (SVA)  del  Plan  Universal  contratado  con  la  Mutualidad  de  la  Abogacía. Creemos que ahorrar se puede convertir en una tarea fácil y sencilla. Nosotros te ayudamos.`,
      },
      {
        name: '¿Por qué debo escoger #YoSíAhorro?',
        text: `Porque  queremos  que  cumplas  tus  sueños  y  ahorrar  para  ellos  no  debe  ser  una  tarea complicada.`,
      },
      {
        name: '¿Es seguro ahorrar con #YoSíAhorro?',
        text: `Todo el ahorro generado se transfiere al fondo acumulado de tu Sistema de Ahorro Flexible (SVA) del Plan Universal de Mutualidad de la Abogacía, entidad autorizada y supervisada por la Dirección General de Seguros y Fondos de Pensiones (DGSFP).`,
      },
      {
        name: '¿Qué requisitos debo cumplir para usar #YoSíAhorro?',
        text: `Para utilizar  el servicio debes tener ser mayor de edad y haber contratado el Sistema de Ahorro Flexible (SVA) del Plan Universal de Mutualidad de la Abogacía.`,
      },
      {
        name: '¿Cómo me doy de alta?',
        text: `Para darte de alta en El servicio necesitarás acceder desde el banner situado en la web o en la aplicación y tener claves de banca electrónica en alguno de los bancos disponibles.`,
      },
      {
        name: '¿Cómo funciona?',
        text: `El funcionamiento es muy sencillo:

        * Selecciona qué objetivo quieres conseguir.

        * Configura cómo quieres ahorrar: Puedes redondear al euro más cercano, a los dos euros…o incluso fijar una aportación periódica mensual. Cuanto más ahorres, antes conseguirás tus objetivos.

        * Añade las cuentas corrientes y/o tarjetas de crédito cuyos cargos quieras redondear: para ello deberás introducir las claves de tu banca electrónica de la entidad bancaria que hayas seleccionado.

        Día a día calculamos el ahorro virtual obtenido a través de todas las reglas que has activado y cada viernes el servicio realiza una aportación por ese importe a tu producto de ahorro de Mutualidad de la Abogacía, siempre y cuando hayas alcanzado el mínimo de 30 €. Si el ahorro acumulado en todas tus reglas no llega a un mínimo de 30€, tendrás la posibilidad de hacer una  aportación  puntual  para  alcanzar  dicha  cuantía, o  esperar  semanalmente  hasta acumular el mínimo de 30€. Dependiendo de la entidad bancaria, esta aportación se cargará en tu cuenta entre los lunes y los miércoles.

        * Todo listo! El servicio ya está ahorrando por ti.`,
      },
    ],
  },
  {
    id: 2,
    name: 'Objetivos',
    faqs: [
      {
        name: '¿Cómo establecer un objetivo?',
        text: `Los objetivos representan las características financieras para alcanzar tus deseos: Importe, periodo y reglas a activar; redondeo, aportaciones mensuales, % de tus ingresos, sin cafeína, sin nicotina, equipo... Dentro de cada objetivo puedes ajustar tus preferencias y alcanzarlo a tu propio ritmo. Para ello tienes que ajustar: 
        
        * Precio (importe) correspondiente al producto o servicio que te quieres comprar, o el importe que quieres ahorrar. 
        
        * Periodo: el periodo representa en cuánto tiempo deseas conseguir el importe elegido antes.

        * Aportaciones mensuales: representa el complemento fijo del ahorro mensual que quieres añadir para alcanzar tu objetivo cuanto antes (cuanto más aportes al mes, antes completarás el objetivo). Esta aportación se realizará el primer viernes de cada mes.

        * Redondeo: esta opción permite escoger a qué altura quieres hacer el redondeo: al euro más cercano (1), a los dos euros (2), a los cinco euros (5) o a los diez euros más cercanos (10). Por ejemplo, si pagas 1,25€ en tu compra y estableces el redondeo a los 2€, ahorrarías 0,75€ en tu lista de redondeos. Con un redondeo a los 5€ ahorrarías 3,75€ y con un redondeo a los 10€ ahorrarías 8,75€.

        * Ingresos: Por cada movimiento positivo que tengas en tu cuenta o tarjeta, ahorrarás un porcentaje.
 
        * Sin cafeína: Activa esta regla y ahorrarás de forma automática 1€ por cada día libre de cafeína. Si caes en la tentación, solo tienes que entrar en el apartado de reglas - sin cafeína y penalizarte sin tu ahorro diario.
 
        * Sin nicotina: Activando esta regla, por cada día que consigas mantenerte alejado de un cigarrillo, ahorrarás de forma automática 1€. Si caes en la tentación, solo tienes que entrar en el apartado de reglas - sin nicotina y penalizarte sin tu ahorro diario.
        
        * Equipo: Estamos conectados con todos los partidos oficiales de primera división de manera que si tienes esta regla activa, con cada partido que gane tu equipo, ahorrarás 1€.`,
      },
      {
        name: '¿Se puede añadir más de un banco a mi objetivo?',
        text: `¡Por supuesto! La idea es ahorrar lo máximo en un tiempo mínimo, por lo que si dispones de otra tarjeta o de otra cuenta bancaria a tu nombre, mejor aprovechar tus gastos para redondear e incrementar tus ahorros. Eso sí, recuerda que podrás añadir más de un banco por objetivo pero no a reglas de diferentes objetivos.`,
      },
      {
        name: '¿Puedo añadir una tarjeta o una cuenta bancaria?',
        text: `Queremos darte la oportunidad de elegir a qué ritmo quieres ahorrar. Por ello, te damos la posibilidad de sincronizar tu objetivo a una o varias tarjetas de crédito de las que seas titular, con el fin de redondear sólo los pagos operados con dicha tarjeta. Podrás igualmente sincronizar tus cuentas bancarias de tal manera que haremos el redondeo sobre cualquier pago domiciliado en tus cuentas. Así que para completar un objetivo lo antes posible es recomendable añadir una cuenta bancaria más que una tarjeta. Y por supuesto, cuantas más cuentas, más ahorras.`,
      },
    ],
  },
  {
    id: 3,
    name: 'Reglas',
    faqs: [
      {
        name: '¿Cuándo  se  añade  en  importe  acumulado  en  mis  reglas  al  Sistema  de Ahorro  Flexible (SVA) del Plan Universal?',
        text: `Las reglas de ahorro se calculan diariamente y cada viernes se realizará una aportación a tu producto  de  ahorro  por  el  valor  que  hayas  acumulado  esa  semana,siempre  que  hayas alcanzado un mínimo de 30€. La aportación mensual se aportará el primer viernes de cada mes.  Si  el  mínimo  no  es  alcanzado  se  intentará  el  cargo  la  semana  siguiente y  así sucesivamente  hasta  que  el  importe  mínimo  se  cumplase  alcance  el  importe  mínimo.  El cargo de las reglas se hará 2 días hábiles después del último viernes de cálculo de las reglas aproximadamente.`,
      },
      {
        name: '¿Qué bancos y tarjetas pueden utilizarse?',
        text: `Disponemos de acceso exclusivo a más de 95% de los bancos y proveedores de tarjetas operando en España. {Aquí} puedes encontrar la lista completa de los bancos y proveedores de tarjeta para ahorrar. 
        
        Es importante distinguir entre tarjeta de débito vs tarjeta de crédito. Ofrecemos el servicio de redondeo solo con tarjetas de crédito, y no con tarjetas de débito. Si solo tienes una tarjeta de débito, siempre puedes configurar tu objetivo para hacer los redondeos y aplicar el resto de reglas a partir de la cuenta bancaria con la cual está vinculada dicha tarjeta, ya que en la cuenta se reflejan los mismos movimientos que en la tarjeta.`,
        link: 'https://coinscrap.com/bancos/',
      },
      {
        name: '¿Puedo utilizar la misma cuenta para aplicar las reglas y para cobros?',
        text: `Claro. Dentro de las cuentas que elijas para cumplir con tu objetivo de ahorro está tú cuenta de Sistema de Ahorro Flexible (SVA). Así pues, podrás utilizar la misma cuentapara las reglas y para los cobros del importe resultante`,
      },
      {
        name: '¿Cómo puedo rescatar mis ahorros?',
        text: `Para solicitar el rescate total o parcial del fondo acumulado deberás remitir a la Mutualidad el formulario de solicitud que puedes descargar en el área personal por correo postal a la c/Serrano, 9, 3ª planta, 28001 (Madrid), o mediante correo electrónico a buzon@mutualidadabogacia.com. Si tienes cualquier duda te atenderemos en el teléfono 91 435 24 86`,
      },
      {
        name: '¿Cómo conecto mi banco?',
        text: `Para conectar tu banco debes conocer tu usuario y tu clave de banca electrónica, que según la entidad bancaria variará. Por ejemplo: si tu banco es el BBVA deberás introducir tu DNI de usuario y tu clave de acceso a la banca electrónica. Una vez hayas introducido tus credenciales, te aparecerán tus cuentas y tarjetas disponibles, por tanto, en ningún momento debes introducir tu número de tarjeta y tu PIN ya que aparecen automáticamente.`,
      },
    ],
  },
  {
    id: 4,
    name: 'Productos de ahorro',
    faqs: [
      {
        name: '¿En dónde se acumula mi ahorro?',
        text: `Todo el ahorro generado se transfiere al fondo acumulado de tu Sistema de Ahorro Flexible (SVA) del Plan Universal de Mutualidad de la Abogacía.`,
      },
      {
        name: '¿Puedo rescatar el dinero ahorrado?',
        text: `Sí, por supuesto. Una vez que el ahorro generado se ha transferido a tu fondo acumulado del Sistema de Ahorro Flexible (SVA) del Plan Universal de Mutualidad de la Abogacía, podrás disponer  del  mismo  en cualquier  momento,  conforme  a  lo  establecido en  las  condiciones aplicables a tu contrato (Reglamento de aportaciones y prestaciones del Plan Universal).`,
      },
    ],
  },
  {
    id: 5,
    name: 'Seguridad',
    faqs: [
      {
        name: '¿Es seguro introducir mis claves bancarias?',
        text: `Para poder calcular los redondeos así como el % de tus ingresos necesitamos conocer tus movimientos. Por eso en El servicio el último paso es introducir tus claves bancarias. Estas claves son sólo de lectura y están encriptadas con máxima seguridad de 256 bits. Los datos nunca son almacenados en ningún dispositivo. La seguridad es una prioridad para nosotros.`,
      },
      {
        name: '¿Cómo se accede a mis movimientos?',
        text: `Según la directiva europea (Payment Service Directive 2015/2366) los datos bancarios pertenecen a los titulares de las cuentas y no a los bancos, por lo que se puede habilitar a terceros el acceso a los mismos. Los movimientos se obtienen a través de un proceso denominado webscraping, utilizado por empresas de renombre del sector Fintech. 
        
        El servicio de información sobre cuentas de pago o agregación bancaria se facilita por el proveedor MORPHEUS AIOLOS S.L. (AFTERBANKS), que cuenta con licencia europea de agregación bancaria. La citada agregación deberá ser aceptada por ti de forma expresa y se prestará bajo los términos y condiciones propios de este servicio. 

        La aceptación del servicio de información sobre cuentas de pago o agregación bancaria es un requisito para  la utilización del servicio #YoSíAhorro, pues de otro modo desde Mutualidad de la Abogacía no podríamos acceder a los movimientos asociados a tus objetivos de ahorro para poder prestarte este servicio.`,
      },
    ],
  },
];

export default mockFaqs;
