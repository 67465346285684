import { UiWrapper } from '@coinscrap/webapp-core';
import colors from 'config/colors';
import bgDark from 'assets/images/bg-dark.png';
import bgMain from 'assets/images/main-bg.jpg';
import commonStyles from './commonStyles';

UiWrapper.defineComponent('view', {
  styles: {
    ...commonStyles.styles,

    default: {
      flexShrink: 1,
    },

    //Atomic
    fullHeightSt: {
      height: '100%',
    },

    flexEqualSizeSt: {
      flex: 1,
    },

    fullWidthSt: {
      width: '100%',
    },

    positionRelativeSt: {
      position: 'relative',
    },

    rowSt: {
      flexDirection: 'row',
    },

    rowSpaceBtwSt: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },

    borderSt: {
      border: '1px solid #f60',
    },

    bgDarkSt: {
      backgroundImage: `url(${bgDark})`,
      backgroundSize: 'cover',
    },
    bgMainSt: {
      backgroundImage: `url(${bgMain})`,
      backgroundSize: 'cover',
    },
    //Components
    headerSt: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 25,
      backgroundColor: colors.white,
    },

    footerSt: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '6px 5px',
      backgroundColor: colors.white,
    },

    footerLogoSt: {
      zIndex: 5,
      backgroundColor: '#fff',
      transition: 'all 0.2s ease',
      padding: 15,
      width: 80,
      top: -30,
      borderRadius: '50%',
    },

    homeBottomSectionSt: {
      height: '50%',
      // clipPath: 'polygon(0 16%, 100% 0, 100% 100%, 0% 100%)',
      backgroundSize: 'cover',
      alignItems: 'flex-end',
    },

    homeBottomSectionLinkSt: {
      flexDirection: 'row',
      width: '50%',
      marginRight: 15,
      marginTop: 10,
      justifyContent: 'flex-end',
    },

    onBoardingDotSt: {
      borderRadius: '50%',
      width: 8,
      height: 8,
      margin: 3,
    },

    targetListSt: {
      justifyContent: 'flex-start',
      flexShrink: 1,
      overflow: 'auto',
    },

    targetItemSt: {
      flexDirection: 'row',
      padding: 20,
      backgroundColor: colors.lightGrey,
      justifyContent: 'space-between',
    },

    targetItemImageContainerSt: {
      width: 7,
      justifyContent: 'start',
      marginTop: -8,
    },

    targetMovementsCollapseSt: {
      backgroundColor: colors.grey,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '8px 20px',
    },

    targetMovementsCollapseContentSt: {
      overflow: 'hidden',
      transition: 'all 0.3s',
      backgroundColor: colors.white,
    },

    targetMovementsItemContainerSt: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '12px 20px',
    },

    banksCollapseSt: {
      backgroundColor: colors.lightBlue,
      flexDirection: 'row',
      padding: '7px 0',
    },

    banksCollapseTitleSt: {
      flex: 2,
      alignItems: 'start',
      marginLeft: 15,
    },

    rulesItemContainerSt: {
      position: 'relative',
      height: 145,
      width: '85%',
      backgroundSize: 'cover',
      justifyContent: 'flex-end',
      marginTop: 30,
      boxShadow: '-1px 1px 10px #c4c4c4',
    },

    summaryItemSt: {
      backgroundColor: colors.white,
      padding: '8px 0',
      margin: '5px 0',
      boxShadow: '2px 2px 10px -1px rgba(0,0,0,0.24)',
      border: 'none',
    },

    scrollArrowSt: {
      position: 'absolute',
      width: 50,
      height: 50,
    },

    targetsSt: {
      height: '100%',
      width: '100%',
      justifyContent: 'start',
      background: '#f7f7f7',
    },

    targetsBottomButtonSt: {
      marginTop: 'auto',
      padding: 40,
      width: '100%',
      background:
        'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 75%, rgba(255,255,255,0) 96%)',
    },
    targetItemDataSt: {
      alignItems: 'start',
      justifyContent: 'space-between',
      height: '90%',
      width: '45%',
    },

    cardSt: {
      backgroundColor: colors.lightBlue,
      border: `2px solid ${colors.lightBlue}`,
      padding: '40px 10px',
      margin: 20,
    },

    bankCardSt: {
      // border: '1px solid #999',
      width: 120,
      height: 110,
      borderRadius: '10px',
      backgroundColor: colors.lightBlue,
      margin: 10,
    },

    bankCardNotSelectedSt: {
      border: `2px solid ${colors.grey}`,
    },

    bankCardSelectedSt: {
      border: `3px solid ${colors.red}`,
      // opacity: 1,
    },

    horizontalSt: {
      flexDirection: 'row',
    },

    spaceBetweenSt: {
      justifyContent: 'space-between',
    },
    spaceAroundSt: {
      justifyContent: 'space-around',
    },
    leftAlignSt: {
      alignItems: 'flex-start',
    },

    startSt: {
      alignItems: 'flex-start',
    },

    topAlignSt: {
      justifyContent: 'flex-start',
    },

    centerAlignSt: {
      alignItems: 'center',
    },

    fullWithSt: {
      width: '100%',
      boxSizing: 'border-box',
    },

    growSt: {
      flexGrow: 1,
    },

    progressBarContainerSt: {
      height: 32,
      width: '100%',
      backgroundColor: '#e0e0de66',
      borderRadius: 50,
      alignItems: 'start',
      position: 'relative',
      marginTop: 20,
      overflow: 'hidden',
    },

    progressBarFillSt: {
      height: '100%',
      backgroundColor: colors.red,
      borderRadius: 'inherit',
      textAlign: 'right',
    },

    progressBarTextContainerSt: {
      flexDirection: 'row',
      position: 'absolute',
      left: 0,
      right: 0,
      margin: 'auto',
    },

    autoWidthSt: {
      width: 'auto',
    },
    rowFlexEndSt: {
      justifyContent: 'flex-end',
    },

    cursorSt: {
      cursor: 'pointer',
    },
  },
});
