import cafe from 'assets/images/rules-coffee.svg';
import nicotin from 'assets/images/rules-smoking.svg';

import euro from 'assets/images/euro.svg';
import euroBg from 'assets/images/euro-bg.jpg';
import euroBgDesktop from 'assets/images/euro-bg-desktop.jpg';

import periodic from 'assets/images/rules-periodic.svg';
import periodicBg from 'assets/images/rules-periodic-bg.jpg';
import periodicBgDesktop from 'assets/images/rules-periodic-bg-desktop.jpg';

import ingresos from 'assets/images/rules_income.svg';
import ingresosBg from 'assets/images/rules_income-bg.jpg';
import ingresosBgDesktop from 'assets/images/rules_income-bg-desktop.jpg';

import cafeina from 'assets/images/cafeina.svg';
import cafeinaBg from 'assets/images/cafeina-bg.jpg';
import cafeinaBgDesktop from 'assets/images/cafeina-bg-desktop.jpg';

import nicotina from 'assets/images/nicotina.svg';
import nicotinaBg from 'assets/images/nicotina-bg.jpg';
import nicotinaBgDesktop from 'assets/images/nicotina-bg-desktop.jpg';

import equipo from 'assets/images/rules-football.svg';
import equipoBg from 'assets/images/rules-football-bg.jpg';
import equipoBgDesktop from 'assets/images/rules-football-bg-desktop.jpg';

export const rulesData = [
  {
    identifier: 'coinscrap_rounds',
    name: 'Redondeo',
    icon: euro,
    question: '¿Quieres aplicar la regla del redondeo?',
    description: [
      'Puedes elegir ahorrar al euro más cercano o a los 2€, 3€, 5€, 10€ ... Si pagas 5.25€ en una compra y estableces el redondeo a 1€, ahorrarás 0.75€',
      'Recuerda que la cuenta de cargo será siempre la asociada a tu producto de Mutualidad.',
    ],
    tooltip: [
      'Esta es nuestra regla estrella ya que te permite ahorrar sin darte cuenta ¿Cómo funciona? Es muy fácil, solo necesitamos leer los movimientos de tus cuentas y tarjetas para calcular los redondeos.',

      'Este ahorro se acumula semanalmente y se añade al resto de reglas. Si la suma de todas las reglas es superior a 30€, pasaremos una aportación a tu póliza. Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulando hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.',
    ],
    type: 'increment',
    subtype: 'currency',
    defaultValue: 1,
    mandatory: false,
    imageBg: euroBg,
    imageBgDesktop: euroBgDesktop,
  },
  {
    identifier: 'periodic_saving',
    name: 'Aportación periódica',
    icon: periodic,
    question: '¿Qué importe de dinero quieres ahorrar cada mes?',
    description: [
      'Representa el complemento fijo de ahorro mensual que quieres añadir para alcanzar tu objetivo cuanto antes',
      'Recuerda que la cuenta de cargo será siempre la asociada a tu producto de Mutualidad.',
    ],
    tooltip: [
      '¿Qué te parecería destinar una cantidad fija para tu objetivo y así acelerar el ritmo de ahorro? La cantidad que decidas aportar cada mes se sumará al resto de reglas. Si la suma de todas las reglas es superior a 30€, pasaremos una aportación a tu póliza.',

      'Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulando hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.',
    ],
    type: 'currency',
    placeHolder: 20,
    defaultValue: 20,
    mandatory: false,
    imageBg: periodicBg,
    imageBgDesktop: periodicBgDesktop,
  },
  {
    identifier: 'earnings_saving',
    name: 'Ingresos',
    icon: ingresos,
    question: '¿Te gustaría ahorrar un % de tus ingresos?',
    description: [
      'Activando esta regla ahorrarás un porcentaje de tus ingresos todos los meses',
      'Recuerda que la cuenta de cargo será siempre la asociada a tu producto de Mutualidad.',
    ],
    tooltip: [
      '¿Y si de cada ingreso que recibes en tu cuenta, apartaras un poco para tu ahorro? Por cada movimiento positivo que tengas en tu cuenta o tarjeta, ahorrarás el porcentaje que tú decidas.',

      'Esta cantidad se sumará al resto de reglas. Si la suma de todas las reglas es superior a 30€, pasaremos una aportación a tu póliza. Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulando hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.',
    ],
    type: 'increment',
    subtype: 'percent',
    defaultValue: 1,
    mandatory: false,
    imageBg: ingresosBg,
    imageBgDesktop: ingresosBgDesktop,
  },
  {
    identifier: 'cafeine',
    name: 'Sin cafeína',
    icon: cafe,
    question: '¿Quieres ahorrar 1€ con cada café que dejes de tomar?',
    description: [
      'Con esta regla ahorrarás 1€ de forma automática por cada día que no tomes café. Si un día no eres capaz de vencer la tentación, entra en tu objetivo y penalízate sin tu ahorro',
      'Recuerda que la cuenta de cargo será siempre la asociada a tu producto de Mutualidad.',
    ],
    tooltip: [
      '¿Llevas tiempo queriendo reducir los cafés que tomas? Te damos un motivo para animarte a hacerlo ahora. Activa esta regla y ahorrarás de forma automática 1€ por cada día que no tomes café. Si no te puedes resistir, entra tu objetivo - reglas y penalízate sin tu ahorro.',

      'La cantidad acumulada por todos esos cafés que has dejado de fumar se sumará al resto de reglas. Si la suma de todas las reglas es superior a 30€, pasaremos una aportación a tu póliza. Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulando hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.',
    ],
    type: 'image',
    image: cafeina,
    imageBg: cafeinaBg,
    imageBgDesktop: cafeinaBgDesktop,
    defaultValue: 'Si',
    mandatory: false,
  },
  {
    identifier: 'nicotine',
    name: 'Sin nicotina',
    icon: nicotin,
    question: '¿Quieres ahorrar 1€ por cada día que no fumes?',
    description: [
      'Esta regla te ayudará a dejar de fumar. Activándola ahorrarás de forma automática por cada día que no fumes. Si algún día te despistas, entra en la aplicación y penalízate sin tu ahorro diario.',
      'Recuerda que la cuenta de cargo será siempre la asociada a tu producto de Mutualidad.',
    ],
    tooltip: [
      'Te ayudamos a que por fin puedas echar el tabaco de tu vida. Activa esta regla y por cada día que consigas mantenerte alejado de un cigarrillo, ahorrarás de forma automática 1€. Si no te puedes resistir, entra tu objetivo - reglas y penalízate sin tu ahorro.',

      'La cantidad acumulada en esta regla se sumará al resto. Si la suma de todas las reglas es superior a 30€, pasaremos una aportación a tu póliza. Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulando hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.',
    ],
    type: 'image',
    image: nicotina,
    imageBg: nicotinaBg,
    imageBgDesktop: nicotinaBgDesktop,
    defaultValue: 'Si',
    mandatory: false,
  },
  {
    identifier: 'team',
    name: 'Equipo',
    icon: equipo,
    question: '¿Con qué equipo quieres ahorrar?',
    description: [
      'Ahorrarás 1€ automáticamente cada vez que tu equipo gane un partido.',
      'Recuerda que la cuenta de cargo será siempre la asociada a tu producto de Mutualidad.',
    ],
    tooltip: [
      ' ¿Cuentas los días hasta el siguiente partido de tu equipo? Estamos conectados con todos los partidos oficiales de manera que si tienes esta regla activa, con cada partido que gane tu equipo, ahorrarás 1€. Suena bien, ¿verdad?.',

      'La cantidad que consigas por los éxitos de tu equipo se sumará al resto de reglas. Si la suma de todas las reglas es superior a 30€, pasaremos una aportación a tu póliza. Si tu ahorro no llega al mínimo, no te preocupes, seguirás acumulando hasta la siguiente semana. Hacemos este proceso cada viernes así que dependiendo de cuál sea tu banco, recibirás el cargo en tu cuenta entre el lunes y el miércoles.',
    ],
    type: 'suggest',
    defaultValue: '',
    defaultAltValue: '1',
    mandatory: false,
    imageBg: equipoBg,
    imageBgDesktop: equipoBgDesktop,
  },
];
