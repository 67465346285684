import { NavigationWrapper, Page, UiWrapper } from '@coinscrap/webapp-core';

import routes from 'config/routes';
import useTarget from 'hooks/useTarget';

import Header from './components/Header';
import Footer from './components/Footer';
import Graph from './components/Graph/Graph';
import Movements from './components/Movements/Movements';
import Rules from './components/Rules/Rules';
import Edit from './components/Edit/Edit';
import EditFooter from './components/Edit/components/Footer';
import { RuleBanks } from './components/RuleBanks/RuleBanks';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const Target = () => {
  const { params, query } = Page.use();
  const { useHeader, useFooter, matched } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const isDesktop = matched.isDesktopMq;

  const target = useTarget(params.targetId);
  const ruleId = query?.rule ?? null;

  const backHandler = () => {
    let destination = routes.targets;
    if (params.section === 'edit' || params.section === 'editBank') {
      destination = routes.target;
    }
    if (target?.status === 'FINISHED') {
      destination = routes.setting;
    }

    let mode = '';
    if (target?.status === 'FINISHED') {
      mode = 'replace';
    }

    let routeParams = {};
    if (params.section === 'edit' || params.section === 'editBank') {
      routeParams = {
        targetId: params.targetId,
        section: 'graph',
      };
    }
    if (target?.status === 'FINISHED') {
      routeParams = {
        section: 'historic',
      };
    }

    navigateTo(destination, {
      mode,
      routeParams,
    });
  };

  useHeader(
    <GS.Container>
      {params.section === 'ruleBanks' ? (
        <></>
      ) : (
        <>
          {isDesktop &&
            (params.section === 'edit' || params.section === 'editBank' ? (
              <EditFooter
                backHandler={backHandler}
                section={params.section}
                targetId={params.targetId}
              />
            ) : (
              <Footer
                backHandler={backHandler}
                section={params.section}
                targetId={params.targetId}
              />
            ))}
        </>
      )}

      {params.section === 'ruleBanks' ? (
        <></>
      ) : (
        <Header
          target={target}
          section={params.section}
          onClickLeft={backHandler}
          onClickRight={() =>
            navigateTo(routes.target, {
              routeParams: {
                targetId: params.targetId,
                section: 'edit',
              },
            })
          }
        />
      )}
    </GS.Container>,
    [params, isDesktop, target]
  );

  let footerContent;
  if (params.section !== 'ruleBanks') {
    if (params.section === 'edit' || params.section === 'editBank') {
      footerContent = <EditFooter section={params.section} targetId={params.targetId} />;
    } else {
      footerContent = <Footer section={params.section} targetId={params.targetId} />;
    }
  }

  useFooter(<>{!isDesktop && footerContent}</>, [params, isDesktop, target]);

  return (
    <MaxWidthWrapper>
      <GS.Container
        position="block"
        height="100%"
        justifyContent="start"
        alignItems="center"
        padding={isDesktop ? 0 : `0 ${GS.spacing.xs}`}
        gap={GS.spacing.xs}
      >
        {params.section === 'graph' && <Graph target={target} />}
        {params.section === 'movements' && <Movements target={target} />}
        {params.section === 'rules' && <Rules targetId={params.targetId} />}
        {(params.section === 'edit' || params.section === 'editBank') && <Edit target={target} />}
        {ruleId && params.section === 'ruleBanks' && (
          <RuleBanks targetId={params.targetId} ruleId={ruleId.toLowerCase()} />
        )}
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default Target;
