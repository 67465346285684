import React, { useEffect, useState } from 'react';
import { InputCheck, UiWrapper, NavigationWrapper, Page } from '@coinscrap/webapp-core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import routes from 'config/routes';

import useInstitutionAccounts from 'hooks/useInstitutionAccounts';

import { aiolosTerms } from 'data/aiolosTerms';
import { aiolosPrivacy } from 'data/aiolosPrivacy';

import { ReactComponent as Bank } from 'assets/svgs/bank.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import FooterNext from 'components/common/FooterNext';
import HeaderChat from 'components/common/Header/HeaderChat';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import { LegalModal } from 'components/common/LegalModal/LegalModal';

import * as GS from 'styles/globalStyles';

export const BanksStart = () => {
  const { useHeader, useFooter } = UiWrapper.use();
  const { query } = Page.use();
  const { navigateTo } = NavigationWrapper.use();
  const banks = useInstitutionAccounts();

  const previousRoute = query?.from?.name || 'setting';

  const [accept, setAccept] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(false);

  const [check, setCheck] = useState(false);
  const [checkPrivacy, setCheckPrivacy] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false);

  useHeader(
    <HeaderChat
      text="Bancos"
      withBack={previousRoute !== 'chargeAccount'}
      onBack={() => {
        /**
         * from: chargeAccount (onBoarding)
         */

        /**
         * from: setting (banksAndAccounts)
         */
        if (previousRoute === 'setting') {
          navigateTo(routes.settingBanksAccount, { mode: 'replace', clearItems: ['from'] });
        }

        /**
         * from: createTarget (rulesAccounts)
         */
        if (previousRoute === 'createTarget' || previousRoute === 'rulesAccounts') {
          navigateTo(routes.createTarget, {
            routeParams: {
              section: 'rule',
              targetId: query?.from?.routeParams?.targetId,
              step: query?.from?.routeParams?.rule,
              //nextRule: query?.from?.routeParams?.nextRule,
            },
            clearItems: ['from'],
          });
        }

        /**
         * from: target (id)
         */
        if (previousRoute === 'target') {
          navigateTo(routes.target, {
            routeParams: {
              section: query?.from?.routeParams?.section || 'ruleBanks',
              targetId: query?.from?.routeParams?.targetId,
            },
            queryParams: {
              rule: query?.from?.routeParams?.rule,
            },
            clearItems: ['from'],
          });
        }
      }}
    />,
    [previousRoute]
  );

  useFooter(
    <FooterNext
      disabled={banks?.length > 0 ? false : !accept || !acceptConditions || !acceptPrivacy}
      onClick={() => {
        /**
         * from: chargeAccount (onBoarding)
         * from: setting (banksAndAccounts)
         */
        navigateTo(routes.banksSelect, { mode: 'replace' });

        /**
         * from: createTarget (rulesAccounts)
         */
        /* if (previousRoute === 'createTarget') {
          navigateTo(routes.rulesAccounts, {
            routeParams: { targetId: query.from.routeParams.targetId },
          });
        } */

        /**
         * from: target (id)
         */
        //navigateTo(routes.banksSelect, { mode: 'replace' });

        /* if (banks?.length > 0 && query?.from?.routeParams?.targetId) {
          navigateTo(routes.rulesAccounts, {
            routeParams: { targetId: query.from.routeParams.targetId },
          });
          return;
        }

        navigateTo(routes.banksSelect, { mode: 'replace' }); */
      }}
    />,
    [banks?.length, query, accept, acceptConditions]
  );

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="center"
        alignItems="center"
        padding={GS.spacing.xs}
        gap={GS.spacing.xs}
      >
        <GS.Container alignItems="center">
          <SVGIcon Icon={Bank} width={GS.spacing.xl2} height="auto" color={GS.colors.green} />
        </GS.Container>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Title Rufina color={GS.colors.green}>
            {banks?.length === 0
              ? 'Agrega las cuentas sobre las que quieras aplicar las reglas de ahorro'
              : 'Vas a agregar una nueva cuenta'}
          </GS.Title>
          <GS.Text color={GS.colors.lightGreen}>
            Nunca haremos gestiones con tus datos personales.
          </GS.Text>
          <GS.Text color={GS.colors.lightGreen}>
            Utilizaremos un cifrado AES-256 Bits que responde a los requisitos bancarios más
            exigentes.
          </GS.Text>
          <GS.Text color={GS.colors.lightGreen}>
            Analizaremos tus movimientos bancarios y haremos los cálculos necesarios para las reglas
            de ahorro.
          </GS.Text>
          <GS.Text color={GS.colors.lightGreen}>
            En ningún momento utilizaremos estas cuentas como cuentas de cargo.
          </GS.Text>

          {banks?.length === 0 && (
            <GS.Container width="auto">
              <GS.RowContainer
                alignItems="center"
                cursor="pointer"
                onClick={() => setIsTermsModalOpen(true)}
              >
                <InputCheck greenSt checkedIcon={<CheckBoxOutlinedIcon />} value={accept} onChange={()=> setAccept(!accept)}
/>
                <GS.Text textLeft color={GS.colors.green} fontSize={GS.fontSizes.xs2}>
                  Acepto los términos y condiciones del servicio
                </GS.Text>
              </GS.RowContainer>
              <GS.RowContainer
                alignItems="center"
                cursor="pointer"
                onClick={() => setIsPrivacyModalOpen(true)}
              >
                <InputCheck greenSt checkedIcon={<CheckBoxOutlinedIcon />} value={acceptPrivacy} onChange={()=> setAcceptPrivacy(!acceptPrivacy)}
/>
                <GS.Text textLeft color={GS.colors.green} fontSize={GS.fontSizes.xs2}>
                  Acepto la política de privacidad del servicio
                </GS.Text>
              </GS.RowContainer>
              <GS.RowContainer
                alignItems="center"
                cursor="pointer"
                onClick={() => setAcceptConditions(!acceptConditions)}
              >
                <InputCheck
                  greenSt
                  checkedIcon={<CheckBoxOutlinedIcon />}
                  value={acceptConditions}
                  onChange={()=> setAcceptConditions(!acceptConditions)}
                />
                <GS.Text textLeft color={GS.colors.green} fontSize={GS.fontSizes.xs2}>
                  Acepto la utilización de las credenciales bancarias para el funcionamiento de
                  #YoSíAhorro
                </GS.Text>
              </GS.RowContainer>
            </GS.Container>
          )}
        </GS.Container>

        <LegalModal
          title="Condiciones generales aplicables al servicio de información sobre cuentas"
          isOpen={isTermsModalOpen}
          data={aiolosTerms}
          close={() => setIsTermsModalOpen(false)}
          onAccept={() => {
            setIsTermsModalOpen(false);
            setCheck(true);
          }}
          required
        />
        <LegalModal
          title="Condiciones generales aplicables al servicio de información sobre cuentas"
          isOpen={isPrivacyModalOpen}
          data={aiolosPrivacy}
          close={() => setIsPrivacyModalOpen(false)}
          onAccept={() => {
            setIsPrivacyModalOpen(false);
            setCheckPrivacy(true);
          }}
          required
        />
      </GS.Container>
    </MaxWidthWrapper>
  );
};
