export const mapFieldType = (type) => {
  switch (type) {
    case 'text':
      return 'text';
    case 'password':
      return 'password';
    case 'enum':
      return 'select';
    default:
      return 'text';
  }
};
