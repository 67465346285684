import { NavigationWrapper } from '@coinscrap/webapp-core';
import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const HeaderChat = ({ text, withBack = true, onBack }) => {
  const { goBack } = NavigationWrapper.use();

  return (
    <GS.RowContainer justifyContent="center" background={GS.colors.white} gap={GS.spacing.xs}>
      <GS.RowContainer
        maxWidth={GS.screenSizes.sm}
        padding={GS.spacing.xs}
        justifyContent={withBack ? 'space-between' : 'center'}
        alignItems="center"
      >
        {withBack && (
          <GS.Container
            width={GS.spacing.xs}
            onClick={onBack || goBack}
            cursor={onBack ? 'pointer' : 'default'}
          >
            <SVGIcon
              Icon={Angle}
              width="100%"
              height="auto"
              color={GS.colors.green}
              transform="rotate(90deg)"
            />
          </GS.Container>
        )}
        <GS.HeaderTitle>{text}</GS.HeaderTitle>
        {withBack && <GS.Container width={GS.spacing.xs} />}
      </GS.RowContainer>
    </GS.RowContainer>
  );
};

export default HeaderChat;
