import { Module, Page, WithSession } from '@coinscrap/webapp-core';

import GlobalWrapper from 'components/common/GlobalWrapper';
import routes from 'config/routes';
import { BasicLayout } from './components/common/BasicLayout';

import { Home } from './components/views/Home/Home';
import Targets from './components/views/Targets/Targets';
import Rules from './components/views/Rules/Rules';
import Rule from './components/views/Rule/Rule';
import Settings from './components/views/Settings/Settings';
import Setting from './components/views/Setting/Setting';
import Target from './components/views/Target/Target';
import CreateTarget from './components/views/CreateTarget/CreateTarget';
import { Summary } from './components/views/Summary/Summary';
import MovementsDetails from './components/views/Target/components/Movements/components/MovementsDetails';
import TokenEntry from './components/views/TokenEntry/TokenEntry';
import ErrorToken from './components/views/ErrorToken/ErrorToken';
import { AutoConfigure } from './components/views/CreateGlobalTarget/AutoConfigure';
import { ChargeAccount } from './components/views/CreateGlobalTarget/ChargeAccount';

import { BanksStart } from './components/views/Banks/BanksStart';
import { BanksSelect } from './components/views/Banks/BanksSelect';
import { BankConnect } from './components/views/Banks/BanksConnect';
import { BankAwait } from './components/views/Banks/BanksAwait';
import { RulesAccounts } from './components/views/RulesAccounts/RulesAccounts';
import OnBoarding from './components/views/OnBoarding/OnBoarding';
import 'intersection-observer';
import { OperationResolution } from './components/views/Operations/OperationResolution';

function App() {
  return (
    <GlobalWrapper>
      <BasicLayout>
        <Page name={routes.tokenEntry} component={TokenEntry} />
        <Page name={routes.errorToken} component={ErrorToken} />
        <Module path="/internal">
          <WithSession pageNoSession={routes.errorToken}>
            <Module path="/onBoarding/:section">
              <Page name={routes.onBoarding} path="/" component={OnBoarding} />
            </Module>

            <Module path="/createGlobalTarget">
              <Page name={routes.autoConfigure} component={AutoConfigure} />
              <Page name={routes.chargeAccount} component={ChargeAccount} />
            </Module>

            <Module path="/operations">
              <Page
                name={routes.operationResolution}
                path="/resolution/:idOperation"
                component={OperationResolution}
              />
            </Module>

            <Page name={routes.home} component={Home} />
            <Page name={routes.rules} component={Rules} />
            <Page name={routes.rule} path="/rule/:ruleName" component={Rule} />
            {/* <Page name={routes.chat} component={Chat} /> */}
            <Page name={routes.targets} component={Targets} />
            <Page name={routes.settings} component={Settings} />
            <Page name={routes.banksSelect} component={BanksSelect} />
            <Page name={routes.banksConnect} path="/banksConnect/:idBank" component={BankConnect} />
            <Page name={routes.banksAwait} path="/banksAwait/:idBank" component={BankAwait} />
            <Page name={routes.banksStart} component={BanksStart} />

            <Module path="/target/:targetId/:section">
              <Page name={routes.target} path="/" component={Target} />
              <Page
                name={routes.details}
                path="/details/:movementId"
                component={MovementsDetails}
              />
            </Module>

            <Module path="/setting">
              <Page name={routes.setting} path="/:section" component={Setting} />
            </Module>

            <Module path="/createTarget/:targetId">
              <Page name={routes.createTarget} path="/:section/:step" component={CreateTarget} />
            </Module>
            <Page name={routes.summary} path="/summary/:targetId" component={Summary} />
            <Page
              name={routes.rulesAccounts}
              path="/rulesAccounts/:targetId"
              component={RulesAccounts}
            />
          </WithSession>
        </Module>
      </BasicLayout>
    </GlobalWrapper>
  );
}

export default App;
