import { useState } from 'react';

import { NavigationWrapper } from '@coinscrap/webapp-core';

import routes from 'config/routes';
import { tourData } from 'data/tourData';

import { HeroImage } from 'components/common/HeroImage/HeroImage';
import { Button } from 'components/common/Button/Button';
import { TourContent } from './TourContent';

import * as GS from 'styles/globalStyles';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

export const Tour = () => {
  const [indexContentPage, setIndexContentPage] = useState(0);
  const { navigateTo } = NavigationWrapper.use();

  return (
    <MaxWidthWrapper>
      <GS.Container height="100%">
        <HeroImage ItemContentPage={tourData[indexContentPage]} lenContentPage={tourData.length} />
        <TourContent ItemContentPage={tourData[indexContentPage]} lenContentPage={tourData.length}>
          <GS.Container alignItems="center">
            <Button
              text="Siguiente"
              maxWidth={GS.screenSizes.xs}
              onClick={() => {
                if (indexContentPage === 3) {
                  navigateTo(routes.onBoarding, { routeParams: { section: 'finished' } });
                } else {
                  setIndexContentPage((indexContentPage + 1) % tourData.length);
                }
              }}
            />
          </GS.Container>
        </TourContent>
      </GS.Container>
    </MaxWidthWrapper>
  );
};
