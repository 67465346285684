import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { NavigationWrapper, UiWrapper, Page, TargetsWrapper } from '@coinscrap/webapp-core';

import routes from 'config/routes';

import { isValidIBANNumber } from 'utils/Iban';
import useTarget from 'hooks/useTarget';
import useInstitutionAccounts from 'hooks/useInstitutionAccounts';
import { useInstitutions } from 'hooks/useInstitutions';

import BanksCollapse from 'components/common/BanksCollapse';
import BankItem from 'components/common/BankItem/BankItem';
import FooterNext from 'components/common/FooterNext';
import HeaderChat from 'components/common/Header/HeaderChat';
import Scrollable from 'components/common/Scrollable/Scrollable';
import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import { Button } from 'components/common/Button/Button';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

export const RulesAccounts = () => {
  const { navigateTo, goBack } = NavigationWrapper.use();
  const { useLoading, useFooter, useHeader } = UiWrapper.use();
  const { saveTargetRulesConfiguration } = TargetsWrapper.use();
  const { data: institutions } = useInstitutions();

  const { params, query } = Page.use();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  const target = useTarget(params.targetId);
  const banks = useInstitutionAccounts();
  const [rulesAccounts, setRulesAccounts] = useState(
    (target?.targetRulesConfiguration?.metadata?.ruleAccounts || []).reduce(
      (acc, act) => ({ ...acc, [act]: true }),
      {}
    )
  );

  const [loading, setLoading] = useState(false);
  const [loadingProps, setLoadingProps] = useState({
    message: 'Guardando datos',
  });

  const [title, setTitle] = useState('Cuentas de reglas');
  useEffect(() => {
    if (query?.from?.routeParams?.rule && query.from.routeParams.rule === 'coinscrap_rounds') {
      setTitle('Cuentas de redondeo');
    }
    if (query?.from?.routeParams?.rule && query.from.routeParams.rule === 'earnings_saving') {
      setTitle('Cuentas de % de ingresos');
    }
  }, [query]);

  const saveTargetRulesAccounts = async () => {
    setLoading(true);
    await saveTargetRulesConfiguration(target?.id, {
      ruleAccounts: Object.keys(rulesAccounts).filter((x) => rulesAccounts[x]),
    });

    if (query?.from?.name === routes.target && query?.from?.routeParams?.rule) {
      navigateTo(routes.target, {
        routeParams: {
          targetId: query?.from?.routeParams?.targetId,
          section: 'rules',
        },
        clearItems: ['from'],
      });
    } else {
      navigateTo(routes.createTarget, {
        routeParams: {
          section: 'rule',
          targetId: query?.from?.routeParams?.targetId,
          step: query?.from?.routeParams?.nextRule,
        },
        clearItems: ['from'],
      });
    }
  };

  const getInstitution = useCallback((id) => institutions?.find((i) => i.identifier === id), [
    institutions?.length,
  ]);

  useLoading(loading, loadingProps);

  useHeader(
    <HeaderChat
      text="Bancos"
      onBack={() => {
        if (query?.from?.routeParams?.targetId) {
          if (query?.from?.name === routes.target && query?.from?.routeParams?.rule) {
            navigateTo(routes.target, {
              routeParams: {
                targetId: query?.from?.routeParams?.targetId,
                section: 'rules',
              },
              clearItems: ['from'],
            });
          } else {
            navigateTo(routes.createTarget, {
              routeParams: {
                section: 'rule',
                targetId: query?.from?.routeParams?.targetId,
                step: query?.from?.routeParams?.rule,
              },
              clearItems: ['from'],
            });
          }
        } else {
          goBack();
        }
      }}
    />
  );
  useFooter(
    <FooterNext
      onClick={() => saveTargetRulesAccounts()}
      disabled={!Object.values(rulesAccounts).includes(true)}
    />,
    [params, query, rulesAccounts]
  );

  return (
    <MaxWidthWrapper>
      <GS.Container
        justifyContent="start"
        alignItems="center"
        padding={`0 ${GS.spacing.xs}`}
        gap={GS.spacing.xs}
        grow={1}
      >
        <SectionTitle text={title} padding={`${GS.spacing.xs} 0 0 0`} />
        <GS.Text>
          Escoge las cuentas sobre las que quieres aplicar tus reglas de ahorro para alcanzar tu
          nuevo objetivo
        </GS.Text>
        <Scrollable arrowBottom={isDesktop}>
          <GS.Container gap={GS.spacing.xs2}>
            {banks.map((bank) => (
              <BanksCollapse
                key={bank?.name}
                logo={
                  bank.institutionIdentifier
                    ? getInstitution(bank.institutionIdentifier)?.logo || bank?.image
                    : bank?.image
                }
                title={bank?.name}
                updated={`Actualizado hace ${moment((bank.products || [])[0]?.lastImportDate)
                  .locale('es')
                  .fromNow()}`}
              >
                {bank?.products
                  ?.filter(
                    query?.from?.routeParams?.rule === 'coinscrap_rounds'
                      ? (account) => account.isOwner === true
                      : (account) =>
                        account.isOwner === true &&
                        account.iban &&
                        isValidIBANNumber(account.iban) === 1
                  )
                  ?.map((account) => (
                    <BankItem
                      key={bank?.name + account?.name + account?.identifier.slice(-4)}
                      type={account?.type}
                      name={account?.name}
                      number={`**** ${account?.identifier.slice(-4)}`}
                      isActive={rulesAccounts[bank.id + '-' + account.id]}
                      onChange={(checked) =>
                        setRulesAccounts({
                          ...rulesAccounts,
                          [bank.id + '-' + account.id]: checked,
                        })
                      }
                    />
                  ))}
              </BanksCollapse>
            ))}
          </GS.Container>
        </Scrollable>
        <GS.Container alignItems="center" paddingBottom={GS.spacing.xs}>
          <Button
            text="Añadir nuevo banco"
            maxWidth={GS.screenSizes.xs}
            buttonStyle="minimal"
            width={GS.spacing.xl7}
            onClick={() =>
              navigateTo(routes.banksStart, {
                queryParams: {
                  from: {
                    name: routes.rulesAccounts,
                    routeParams: {
                      targetId: target?.id,
                      rule: query?.from?.routeParams?.rule || undefined,
                      nextRule: query?.from?.routeParams?.nextRule || undefined,
                    },
                  },
                },
              })
            }
          /* onClick={() => {
          navigateTo(routes.banksStart, {
            queryParams: {
              from: {
                name: routes.target,
                routeParams: {
                  section: 'editBank',
                  targetId: params.targetId,
                },
              },
            },
          });
        }} */
          />
        </GS.Container>
      </GS.Container>
    </MaxWidthWrapper>
  );
};
