import getCurrencyDecimals from 'utils/getCurrencyDecimals';

import * as GS from 'styles/globalStyles';

const MovementsTotal = ({ date, amount }) => (
  <GS.RowContainer
    justifyContent="space-between"
    alignItems="center"
    background={GS.colors.lightGrey}
    borderRadius={GS.radius.sm}
    padding={GS.spacing.xs}
  >
    <GS.Container width="auto" grow={1} gap={GS.spacing.xs3}>
      <GS.Text textLeft fontBold>
        Estás acumulando:
      </GS.Text>
      <GS.Text textLeft fontSize={GS.fontSizes.xs}>
        {date}
      </GS.Text>
    </GS.Container>
    <GS.Container width="fit-content">
      <GS.Text textRight fontBold>
        {amount && getCurrencyDecimals(amount)} €
      </GS.Text>
    </GS.Container>
  </GS.RowContainer>
);

export default MovementsTotal;
