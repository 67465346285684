import { UiWrapper } from '@coinscrap/webapp-core';
import { ReactComponent as Back } from '../../../assets/svgs/angle.svg';
import { ReactComponent as Edit } from '../../../assets/svgs/edit.svg';
import { SVGIcon } from '../SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const Header = ({ title, back, onBack, next, onNext }) => {
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  return (
    <GS.RowContainer
      padding={isDesktop ? `${GS.spacing.xs} 0` : GS.spacing.xs}
      justifyContent="space-between"
      gap={GS.spacing.xs}
    >
      <GS.Container
        alignItems="start"
        justifyContent="center"
        width={GS.spacing.xs}
        cursor="pointer"
        onClick={back && onBack ? onBack : null}
      >
        {back && (
          <SVGIcon
            Icon={Back}
            width={GS.spacing.xs}
            height="auto"
            color={GS.colors.green}
            marginLeft={GS.spacing.xxs}
            transform="rotate(90deg)"
          />
        )}
      </GS.Container>
      <GS.Container justifyContent="center" width="auto" grow={1}>
        <GS.HeaderTitle>{title}</GS.HeaderTitle>
      </GS.Container>
      <GS.Container
        alignItems="end"
        justifyContent="center"
        width={GS.spacing.xs}
        cursor="pointer"
        onClick={next && onNext ? onNext : null}
      >
        {next && (
          <SVGIcon
            Icon={Edit}
            width={GS.spacing.xs}
            height="auto"
            color={GS.colors.green}
            marginLeft={GS.spacing.xxs}
          />
        )}
      </GS.Container>
    </GS.RowContainer>
  );
};

export default Header;
