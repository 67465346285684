import styled from 'styled-components/macro';
import * as GS from 'styles/globalStyles';

export const Dots = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${GS.spacing.xs2};
  justify-content: center;
  width: 100%;
`;

export const Dot = styled.div`
  background-color: ${(props) => (props.active ? GS.colors.green : GS.colors.lightGreen)};
  border-radius: 999px;
  height: ${({ height }) => height || GS.spacing.xs2};
  width: ${({ width }) => width || GS.spacing.xs2};
`;
