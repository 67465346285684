import { NavigationWrapper } from '@coinscrap/webapp-core';
import * as GS from 'styles/globalStyles';
import { Button } from './Button/Button';

export const FooterBackNext = ({ text = 'Siguiente', disabled, onBack, onClick }) => {
  const { goBack } = NavigationWrapper.use();

  return (
    <GS.Container maxWidth={GS.screenSizes.sm}>
      <GS.RowContainer
        justifyContent="space-between"
        alignItems="center"
        padding={GS.spacing.xs}
        gap={GS.spacing.xs2}
      >
        <Button
          buttonStyle="invertedWithLine"
          minWidth="unset"
          text="Volver"
          onClick={onBack || goBack}
        />
        <Button minWidth="unset" text={text} disabled={disabled} onClick={onClick} />
      </GS.RowContainer>
    </GS.Container>
  );
};
