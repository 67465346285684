import { useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import ScrollArrow from '../ScrollArrow/ScrollArrow';
import { ScrollBottom } from './components/ScrollBottom';

import * as GS from 'styles/globalStyles';

const Scrollable = ({ children, restart, arrowBottom, onScrollBottom }) => {
  const { ref, inView } = useInView();
  const scrollContainerRef = useRef(null);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (restart) {
      scrollContainerRef.current.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    if (inView) {
      onScrollBottom && onScrollBottom();
    }
  }, [inView]);

  return (
    <>
      {arrowBottom && (
        <GS.Container position="fixed" width="auto" bottom={GS.spacing.xsm} right={GS.spacing.xsm}>
          <ScrollArrow lastItem={scrollRef} />
        </GS.Container>
      )}
      <GS.Container ref={scrollContainerRef}>
        {children}
        <ScrollBottom refProp={ref} />
        <GS.Container ref={scrollRef} />
      </GS.Container>
    </>
  );
};

export default Scrollable;
