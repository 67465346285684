import { useCallback, useState } from 'react';
import moment from 'moment';
import {
  Text,
  View,
  Image,
  InstitutionAccountsWrapper,
  PickerHOC,
  UiWrapper,
  TargetsWrapper,
  NavigationWrapper,
  TargetsUtilsWrapper,
} from '@coinscrap/webapp-core';

import fonts from 'config/fonts';
import routes from 'config/routes';

import { filterInstitutions } from 'utils/filterInstitutions';
import { isValidIBANNumber } from 'utils/Iban';

import { useInstitutions } from 'hooks/useInstitutions';

import BanksCollapse from 'components/common/BanksCollapse';
import BankItem from 'components/common/BankItem/BankItem';
import FooterNext from 'components/common/FooterNext';

import mas from 'assets/images/mas.svg';

export const ChargeAccount = PickerHOC({
  parentTarget: {
    arrayItemMatcher: {
      allowNoExistence: true,
      itemPropertyPath: 'level',
      type: 'value',
      value: 0,
    },
    sourcePath: 'targets',
    sourceWrapper: TargetsWrapper,
  },
  institutionAccounts: {
    sourcePath: 'institutionAccounts',
    sourceWrapper: InstitutionAccountsWrapper,
  },
})(() => {
  const { navigateTo } = NavigationWrapper.use();
  const { useLoading, useFooter } = UiWrapper.use();
  const { saveTargetProperty } = TargetsUtilsWrapper.use();
  const { data: institutions } = useInstitutions();

  const { parentTarget, institutionAccounts } = PickerHOC.use();
  const iAFiltered = filterInstitutions(institutionAccounts);

  const [chargeAccount, setChargeAccount] = useState(
    parentTarget?.creationData?.sourceProduct?.iban?.trim()
  );

  const saveTargetChargeAccount = async () => {
    await saveTargetProperty(parentTarget?.id, 'creationData', {
      sourceProduct: {
        fromPolicy: false,
        iban: chargeAccount,
      },
    });

    navigateTo(routes.autoConfigure, { mode: 'replace' });
  };

  const getInstitution = useCallback((id) => institutions?.find((i) => i.identifier === id), [
    institutions?.length,
  ]);

  useLoading(false);
  useFooter(<FooterNext onClick={() => saveTargetChargeAccount()} disabled={!chargeAccount} />, [
    chargeAccount,
  ]);

  return (
    <View fullWidthSt style={{ justifyContent: 'flex-start', flexDirection: 'column' }}>
      <Text
        style={{
          fontSize: 18,
          textTransform: 'uppercase',
          fontFamily: fonts.GothamBold,
          marginTop: 70,
          marginBottom: 40,
        }}
      >
        Cuenta de cargo
      </Text>
      <Text style={{ fontSize: 13, textAlign: 'center', width: '80%', lineHeight: 1.5 }}>
        Escoge la cuenta donde quieres que realizen los cargos de tus aportaciones al ahorro
      </Text>
      <View
        fullWidthSt
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginTop: 50,
        }}
      >
        {iAFiltered.map((bank) => (
          <BanksCollapse
            key={bank?.name}
            logo={
              bank.institutionIdentifier
                ? getInstitution(bank.institutionIdentifier)?.logo || bank?.image
                : bank?.image
            }
            title={bank?.name}
            updated={`Actualizado hace ${moment((bank.products || [])[0]?.lastImportDate)
              .locale('es')
              .fromNow()}`}
          >
            {bank?.products
              ?.filter((account) => account.iban && isValidIBANNumber(account.iban) === 1)
              .map((account, index) => (
                <BankItem
                  key={account?.name + index}
                  type={account?.type}
                  name={account?.name}
                  number={account?.number}
                  isActive={chargeAccount?.trim() === account.iban?.trim()}
                  onChange={(checked) =>
                    checked ? setChargeAccount(account.iban?.trim()) : setChargeAccount('')
                  }
                  whiteSwitch
                />
              ))}
          </BanksCollapse>
        ))}

        <View
          style={{ marginTop: 50 }}
          onClick={() =>
            navigateTo(routes.banksStart, {
              queryParams: { from: { name: routes.chargeAccount } },
            })
          }
        >
          <Image src={mas} style={{ marginBottom: 10 }} />
          <Text style={{ fontSize: 12, textAlign: 'center' }}>Añadir banco</Text>
        </View>
      </View>
    </View>
  );
});
