import { useEffect, useState } from 'react';
import {
  InputSwitch,
  Page,
  RulesWrapper,
  PickerHOC,
  RuleDefinitionsWrapper,
  UiWrapper,
  NavigationWrapper,
} from '@coinscrap/webapp-core';

import routes from 'config/routes';

import getCurrencyDecimals from 'utils/getCurrencyDecimals';

import { ReactComponent as Euro } from 'assets/svgs/euro-circle.svg';
import { ReactComponent as Clock } from 'assets/svgs/clock-circle.svg';
import { ReactComponent as Sack } from 'assets/svgs/sack-circle.svg';
import { ReactComponent as Mug } from 'assets/svgs/mug-circle.svg';
import { ReactComponent as Smoking } from 'assets/svgs/smoking-circle.svg';
import { ReactComponent as Football } from 'assets/svgs/football-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { Button } from 'components/common/Button/Button';
import { Modal } from 'components/common/Modal/Modal';

import * as GS from 'styles/globalStyles';

export const RulesItem = PickerHOC({
  ruleDefinitions: {
    sourceWrapper: RuleDefinitionsWrapper,
    sourcePath: 'ruleDefinitions',
  },
})(({ weekly, total, rule, refProp, paused }) => {
  const { params } = Page.use();
  const { desactivateRule } = RulesWrapper.use();
  const { ruleDefinitions } = PickerHOC.use();
  const { launchEvent } = RulesWrapper.use();
  const { useLoading } = UiWrapper.use();
  const { navigateTo } = NavigationWrapper.use();

  const [isActive, setIsActive] = useState(rule.isActive);
  const [rejected, setRejected] = useState(false);
  const [isOpenBrokenRuleModal, setIsOpenBrokenRuleModal] = useState(false);

  const identifier = rule.identifier.toUpperCase();

  const eventDefinitions = ruleDefinitions.reduce(
    (acc, act) => [...acc, ...(act.eventDefinitions || [])],
    []
  );

  const Icon = () => {
    switch (rule.icon.replace('/static/media/', '').split('.')[0]) {
      case 'euro':
        return <Euro />;
      case 'rules-periodic':
        return <Clock />;
      case 'rules_income':
        return <Sack />;
      case 'rules-coffee':
        return <Mug />;
      case 'rules-smoking':
        return <Smoking />;
      case 'rules-football':
        return <Football />;
      default:
        return <Euro />;
    }
  };

  useEffect(() => {
    setIsActive(rule.isActive);
  }, [rule.isActive]);

  useEffect(() => {
    rejected && setIsActive(false);
    setRejected(false);
  }, [rejected]);

  const toggleActive = async () => {
    if (isActive) {
      setIsActive(false);
      desactivateRule(identifier, params.targetId);
    } else {
      navigateTo(routes.target, {
        routeParams: {
          targetId: params.targetId,
          section: 'ruleBanks',
        },
        queryParams: {
          rule: identifier,
        },
        //mode: 'replace',
      });
    }
  };

  const doEvent = async (eventDefinition, { target, amount }) => {
    try {
      await launchEvent(
        eventDefinition.identifier,
        [
          amount && {
            identifier: 'hot_event_amount',
            data: { value: Number(amount) },
          },
        ].filter((x) => x),
        target
      );
      setIsOpenBrokenRuleModal(true);
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  const doCafeine = async () => {
    const eventDefinition = eventDefinitions.find((def) => def.identifier === 'EVENT_CAFEINE');
    await doEvent(eventDefinition, {});
  };
  const doNicotine = async () => {
    const eventDefinition = eventDefinitions.find((def) => def.identifier === 'EVENT_NICOTINE');
    await doEvent(eventDefinition, {});
  };

  useLoading(false);

  return (
    <GS.Container
      ref={refProp}
      background={GS.colors.lightGrey}
      borderRadius={GS.radius.sm}
      padding={GS.spacing.xs}
    >
      <GS.RowContainer justifyContent="space-between" alignItems="center" gap={GS.spacing.xs}>
        <SVGIcon Icon={Icon} width={GS.spacing.sm} height="auto" color={GS.colors.green} />
        <GS.Container width="auto" grow={1}>
          <GS.Text textLeft fontBold paddingBottom={GS.spacing.xs3}>
            {rule?.name || rule?.identifier}
          </GS.Text>
          <GS.Text textLeft>
            Acumulado: <GS.Span fontBold>{getCurrencyDecimals(weekly)}€</GS.Span>
          </GS.Text>

          <GS.Text textLeft>
            Total: <GS.Span fontBold>{getCurrencyDecimals(total)}€</GS.Span>
          </GS.Text>
        </GS.Container>
        {!paused && (
          <GS.Container width="fit-content" alignItems="end" gap={GS.spacing.xs2}>
            <InputSwitch
              style={{
                marginRight: -10,
                backgroundColor: 'red',
              }}
              value={isActive}
              onChange={() => toggleActive()}
            />
            {isActive && rule?.ruleDefinition?.identifier === 'NICOTINE' && (
              <Button
                text="He fumado"
                buttonStyle="minimal"
                width={GS.spacing.xl3}
                onClick={() => doNicotine()}
              />
            )}
            {isActive && rule?.ruleDefinition?.identifier === 'CAFEINE' && (
              <Button
                text="He tomado café"
                buttonStyle="minimal"
                width={GS.spacing.xl3}
                onClick={() => doCafeine()}
              />
            )}
          </GS.Container>
        )}
      </GS.RowContainer>

      <Modal isOpen={isOpenBrokenRuleModal} close={() => setIsOpenBrokenRuleModal(false)}>
        <GS.Container alignItems="center" gap={GS.spacing.xs}>
          <GS.Text fontBold>¡Ups, has caído!</GS.Text>
          <GS.Text>Hoy no ahorrarás para tu objetivo con esta regla.</GS.Text>
          <Button text="OK" onClick={() => setIsOpenBrokenRuleModal(false)} />
        </GS.Container>
      </Modal>
    </GS.Container>
  );
});
