import SectionTitle from 'components/common/SectionTitle/SectionTitle';

import moment from 'moment';
import Chart from './components/Chart';

import * as GS from 'styles/globalStyles';

const createRange = (beginning, end) => {
  const format = 'MM/YY';
  const start = moment(beginning).startOf('month');

  const result = [];
  while (start.isBefore(end)) {
    result.push(start.format(format));
    start.add(1, 'month');
  }
  return result;
};

const addRealMonth = (d) => {
  const fm = moment(d).add(1, 'M');
  const fmEnd = moment(fm).endOf('month');
  return d.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;
};

const stuctureDataMovements = (target) => {
  const newArray = [];

  if (target?.movements) {
    target?.movements.forEach((item) => {
      const monthYear = moment(item.valueDate).format('MMMM YYYY');
      const monthYearForGraph = moment(item.valueDate).format('MM/YY');
      const findDate = newArray.findIndex((arrayItem) => arrayItem.date === monthYear);
      if (findDate === -1) {
        newArray.push({
          dateObject: item.valueDate,
          date: monthYear,
          dateGraph: monthYearForGraph,
          total: item.amount,
          movements: [item],
        });
      } else {
        newArray[findDate] = {
          ...newArray[findDate],
          total: newArray[findDate].total + item.amount,
          movements: [...newArray[findDate].movements, item],
        };
      }
    });
  }

  return newArray;
};

const optionsGraph = {
  legend: {
    labels: {
      usePointStyle: false,
      fontColor: GS.colors.green,
      boxWidth: 20,
    },
    position: 'bottom',
  },
  scales: {
    xAxes: [
      {
        ticks: {
          fontColor: GS.colors.darkGreen,
          autoSkip: true,
          maxTicksLimit: 12,
          stepSize: 0.2,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          callback(label, _index, _labels) {
            return label + ' €';
          },

          fontColor: GS.colors.darkGreen,
        },

        scaleLabel: {
          display: true,
        },
      },
    ],
  },
};

const Graph = ({ target }) => {
  const movements = stuctureDataMovements(target);

  const getDataGoal = () => {
    const numberOfMonths = createRange(target?.createDate, target?.creationData.date).length;
    const goalPerMonth =
      (target?.progress.total - (target?.creationData?.initialContribution || 0)) /
      (numberOfMonths - 1);

    const goalArray = [];
    [...Array(numberOfMonths)].forEach((_, index) => {
      goalArray.push(
        goalPerMonth * index + (parseInt(target?.creationData?.initialContribution) || 0)
      );
    }, 0);

    return goalArray;
  };

  const getDataCompleted = () => {
    const numberOfMonths = createRange(target?.createDate, moment().toDate()).length;

    const currentMonth = moment(target?.createDate);
    const labelsArray = [];
    [...Array(numberOfMonths)].reduce((acc) => {
      labelsArray.push(acc);
      return addRealMonth(acc);
    }, currentMonth);

    const mapped = labelsArray.map((item, _index) => {
      const find = movements.find(
        (object) => moment(object.dateObject).format('MM/YY') === item.format('MM/YY')
      );

      let returnValue = 0;

      if (find) {
        returnValue = find.total;
      }

      // if (target?.creationData?.initialContribution && index === 0) {
      //   returnValue += parseInt(target?.creationData?.initialContribution);
      // }

      return returnValue;
    });

    const newArray = [];
    mapped.reduce((accumulator, currentValue) => {
      newArray.push(accumulator + currentValue);
      return accumulator + currentValue;
    }, 0);

    return newArray;
  };

  const getDataProyected = () => {
    const numberOfMonths = createRange(target?.createDate, target?.creationData.date).length;
    const dataGoal = getDataGoal();
    const dataCompleted = getDataCompleted();

    const proyectedArray = [];

    const lastCompleted = dataCompleted[dataCompleted.length - 1];
    const matchingGoal = dataGoal[dataCompleted.length - 1];
    const completionIndex = lastCompleted / matchingGoal;
    [...Array(numberOfMonths)].forEach((_item, index) => {
      dataCompleted[index] === 0
        ? proyectedArray.push(0)
        : proyectedArray.push(dataCompleted[index] || dataGoal[index] * completionIndex);
    });

    return proyectedArray;
  };

  const getLabels = () => createRange(target?.createDate, target?.creationData.date);

  const dataGraph = {
    type: 'LINE',
    series: [
      { title: 'Proyección', type: 'LINE', dashed: true },
      { title: 'Ahorro', type: 'AREA' },
    ],
    labels: getLabels(),
    datasets: [
      {
        borderColor: GS.colors.green,
        backgroundColor: 'transparent',
        data: getDataProyected(),
        label: 'Proyección',
        type: 'line',
        pointRadius: 0,
        borderDash: [7, 5],
        borderWidth: 1,
      },
      {
        borderColor: GS.colors.green,
        data: getDataCompleted(),
        label: 'Mi ahorro',
        type: 'line',
        backgroundColor: GS.colors.green + 'aa',
        pointRadius: 0,
        borderWidth: 0,
      },
      {
        borderColor: GS.colors.electricGreen,
        data: getDataGoal(),
        label: 'Mi objetivo',
        type: 'line',
        backgroundColor: GS.colors.electricGreen + 'aa',
        pointRadius: 0,
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      <SectionTitle text="Gráficas de movimientos" padding={`${GS.spacing.xs} 0 0 0`} />
      <Chart data={dataGraph} options={optionsGraph} />
    </>
  );
};

export default Graph;
