export const formatNumber = (number) => {
  let result = 0;
  result = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);

  return result;
};

export const setCurrency = (value) => {
  let newValue = value || '0';
  newValue += '';
  newValue = newValue.replace('.', '').replace(',', '.');

  return parseFloat(newValue);
};

export const formatValueNumber = (val) => val?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');

export const cleanNumber = (num) => num.replace(/\./g, '');
