import { useState } from 'react';
import { InputCheck, NavigationWrapper, UserWrapper } from '@coinscrap/webapp-core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

import routes from 'config/routes';

import { ReactComponent as MABLogo } from 'assets/mab-logo-text.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { LegalModal } from 'components/common/LegalModal/LegalModal';
import { TermsComponent } from 'components/views/LegalDocuments/Terms';
import { Button } from 'components/common/Button/Button';

import * as GS from 'styles/globalStyles';

const Finished = () => {
  const { navigateTo } = NavigationWrapper.use();
  const [check, setCheck] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

  const { user, updateUser } = UserWrapper.use();

  return (
    <>
      <GS.Container
        alignItems="center"
        height="100%"
        gap={GS.spacing.sm}
        background={GS.colors.green}
        padding={GS.spacing.xs}
      >
        <GS.Container gap={GS.spacing.sm} grow={1} alignItems="center" justifyContent="center">
          <SVGIcon Icon={MABLogo} width={GS.spacing.xl12} height="auto" color={GS.colors.pink} />
          <GS.Container alignItems="center" gap={GS.spacing.xs}>
            <GS.Title Rufina color={GS.colors.pink}>
              ¡Ya has terminado!
            </GS.Title>
            <GS.Text color={GS.colors.white}>
              Ahora que ya conoces un poco en qué consiste #YoSíAhorro, ya estás listo para crear tu
              nuevo plan de ahorro. ¿Comenzamos?
            </GS.Text>
            <GS.Container width="auto">
              <GS.RowContainer
                alignItems="center"
                cursor="pointer"
                onClick={() => setIsTermsModalOpen(true)}
              >
                <InputCheck whiteSt checkedIcon={<CheckBoxOutlinedIcon />} value={check} />
                <GS.Text color={GS.colors.white} fontSize={GS.fontSizes.xs2}>
                  He leído y acepto los Términos y Condiciones de{' '}
                  <GS.Span fontBold>#YoSíAhorro</GS.Span>
                </GS.Text>
              </GS.RowContainer>
            </GS.Container>
          </GS.Container>
        </GS.Container>
        <GS.Container alignItems="center">
          <Button
            text="Siguiente"
            maxWidth={GS.screenSizes.xs}
            buttonStyle="inverted"
            disabled={check}
            onClick={() => {
              navigateTo(routes.onBoarding, { routeParams: { section: routes.fetchPolicies } });
            }}
          />
        </GS.Container>
      </GS.Container>
      <LegalModal
        title="Términos y condiciones del servicio #YoSíAhorro"
        isOpen={isTermsModalOpen}
        HtmlData={<TermsComponent />}
        close={() => setIsTermsModalOpen(false)}
        onAccept={async () => {
          await updateUser({
            metadata: {
              ...user?.metadata,
              consentInformed: true,
              consentDate: new Date(),
            },
          });
          setIsTermsModalOpen(false);
          setCheck(true);
        }}
        required
      />
    </>
  );
};

export default Finished;
