import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('textCurrency', {
  styles: {
    default: {
      entireComponent: {},
      decimalComponent: {},
    },
  },
});
