import { useEffect, useState } from 'react';
import { NavigationWrapper, RulesWrapper, UserWrapper } from '@coinscrap/webapp-core';

import { teams } from 'data/teamsData';
import routes from 'config/routes';
import useRuleValue from 'hooks/useRuleValue';
import { formatValueNumber } from 'utils/formatNumber';

import { ReactComponent as MinusCircle } from 'assets/svgs/minus-circle.svg';
import { ReactComponent as PlusCircle } from 'assets/svgs/plus-circle.svg';
import { ReactComponent as Euro } from 'assets/svgs/euro.svg';
import { ReactComponent as Mug } from 'assets/svgs/mug-circle.svg';
import { ReactComponent as Smoking } from 'assets/svgs/smoking-circle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';
import { AcceptDecline } from './AcceptDecline/AcceptDecline';
import { Input } from 'components/common/Input/Input';
import RulesTooltip from './RulesTooltip/RulesTooltip';

import * as GS from 'styles/globalStyles';

const RuleModal = ({
  targetId,
  rule,
  needBank,
  hasBanks,
  hasSelectedRulesAccounts,
  close,
  setRejected,
}) => {
  const [value, setValue] = useRuleValue(rule);
  const { desactivateRule, activateRule, upsertRule } = RulesWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const identifier = rule.identifier.toUpperCase();
  const { user } = UserWrapper.use();
  const [isMounted, setIsMounted] = useState(false);

  const nextStepBankHandler = () => {
    if (hasBanks) {
      navigateTo(routes.rulesAccounts, {
        queryParams: {
          from: {
            name: routes.target,
            routeParams: {
              targetId,
              rule: rule.identifier,
            },
          },
        },
      });
    } else {
      navigateTo(routes.banksStart, {
        queryParams: {
          from: {
            name: routes.target,
            routeParams: {
              targetId,
              rule: rule.identifier,
            },
          },
        },
      });
    }
  };

  const nextStepHandler = (isActive) => {
    if (isActive) {
      let ruleData = [
        {
          identifier: rule.fieldDefinitions[0].identifier,
          data: { value },
        },
      ];

      if (rule.fieldDefinitions[1]?.identifier) {
        ruleData = [
          {
            identifier: rule.fieldDefinitions[0].identifier,
            data: { value },
          },
          {
            identifier: rule.fieldDefinitions[1].identifier,
            data: { value: rule.defaultAltValue },
          },
        ];
      }

      try {
        if (rule && !rule.isActive) {
          activateRule(identifier, targetId);
        }
        upsertRule(identifier, user.id, targetId, ruleData);
      } catch (e) {
        console.error('## ERROR ACTIVANDO', e);
      }
    } else {
      try {
        if (rule) {
          desactivateRule(identifier, targetId);
        }
      } catch (e) {
        console.error('## ERROR DESACTIVANDO', e);
      }
    }
  };

  const handleChangeValue = (val) => {
    const selectedValue = parseInt(val.replace(/\D/g, '')) || 0;
    setValue(selectedValue > 10000 ? 1000 : selectedValue);
  };

  const Icon = () => {
    if (rule.type === 'image') {
      switch (rule.image.replace('/static/media/', '').split('.')[0]) {
        case 'cafeina':
          return <Mug />;
        case 'nicotina':
          return <Smoking />;
        default:
          return <Euro />;
      }
    }
  };

  const [filteredTeams, setFilteredTeams] = useState([]);
  const [openSuggest, setOpenSuggest] = useState(false);

  useEffect(() => {
    if (rule.type === 'suggest') {
      const allTeams = teams.filter((t) => t.active === true);
      const filtered = allTeams.filter((team) =>
        team.label.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTeams(filtered);
      if (value.length > 2 && filtered.length > 0) {
        if (value !== filtered[0].label) {
          setOpenSuggest(true);
        } else {
          setOpenSuggest(false);
        }
      } else {
        setOpenSuggest(false);
      }
    }
  }, [value]);

  useEffect(() => {
    setIsMounted(true);

    return () => setIsMounted(false);
  }, []);

  return (
    <GS.Container
      alignItems="center"
      maxWidth={GS.screenSizes.md}
      opacity={isMounted ? 1 : 0}
      transition="all 0.8s ease"
      gap={GS.spacing.sm}
    >
      <GS.Container alignItems="center">
        <GS.Title fontBold Rufina paddingBottom={GS.spacing.xs3}>
          {rule?.name}
        </GS.Title>
        <GS.Text>{rule?.question}</GS.Text>
        <RulesTooltip
          content={
            <GS.Container gap={GS.spacing.xs}>
              {rule.tooltip.map((text) => (
                <GS.Text key={text.substring(3, 9)} textLeft fontSize={GS.fontSizes.sm}>
                  {text}
                </GS.Text>
              ))}
            </GS.Container>
          }
        />
      </GS.Container>

      {(!needBank || (hasBanks && hasSelectedRulesAccounts)) && (
        <>
          <GS.Container alignItems="center">
            {rule.type === 'image' && (
              <SVGIcon
                Icon={Icon}
                width={GS.spacing.xl}
                height="auto"
                color={GS.colors.lightGreen}
              />
            )}

            {rule.type === 'increment' && (
              <GS.RowContainer justifyContent="center" alignItems="center" gap={GS.spacing.xs}>
                <GS.Container
                  width="fit-content"
                  cursor="pointer"
                  onClick={() => setValue(value > 1 ? value - 1 : 1)}
                >
                  <SVGIcon
                    Icon={MinusCircle}
                    width={GS.spacing.sm}
                    height="auto"
                    color={GS.colors.green}
                  />
                </GS.Container>
                <GS.Container width={GS.spacing.xl4}>
                  <GS.Title fontBold Rufina>
                    {value + (rule.subtype === 'currency' ? '€' : '%  ')}
                  </GS.Title>
                </GS.Container>
                <GS.Container
                  width="fit-content"
                  cursor="pointer"
                  onClick={() => setValue(value + 1)}
                >
                  <SVGIcon
                    Icon={PlusCircle}
                    width={GS.spacing.sm}
                    height="auto"
                    color={GS.colors.green}
                  />
                </GS.Container>
              </GS.RowContainer>
            )}

            {rule.type === 'currency' && (
              <GS.Container alignItems="center">
                <Input
                  value={formatValueNumber(value) === '0' ? '' : formatValueNumber(value)}
                  type="text"
                  inputMode="numeric"
                  //unit="€"
                  placeholder="Cantidad"
                  RightAdornment={() => (
                    <GS.Container width="fit-content" marginLeft={GS.spacing.xs}>
                      <SVGIcon Icon={Euro} width="18px" height="18px" color={GS.colors.green} />
                    </GS.Container>
                  )}
                  maxWidth={GS.spacing.xl6}
                  border={`1px solid ${GS.colors.lightGreen}`}
                  onChange={(e) => handleChangeValue(e.target?.value)}
                />
              </GS.Container>
            )}

            {rule.type === 'suggest' && (
              <GS.Container position="relative" alignItems="center">
                <Input
                  value={value}
                  type="text"
                  textCenter
                  placeholder="Escribe tu equipo"
                  maxWidth={GS.spacing.xl10}
                  border={`1px solid ${GS.colors.lightGreen}`}
                  onChange={(e) => setValue(e.target?.value)}
                />
                {openSuggest && (
                  <GS.Container
                    position="absolute"
                    top={GS.spacing.lg}
                    gap={GS.spacing.xs2}
                    width="fit-content"
                    padding={GS.spacing.xs}
                    zIndex={1}
                    background={GS.colors.white}
                  >
                    {filteredTeams.map((team) => (
                      <GS.Container
                        key={team.id}
                        cursor="pointer"
                        onClick={() => {
                          setValue(team.label);
                          setOpenSuggest(false);
                        }}
                      >
                        <GS.Text>{team.label}</GS.Text>
                      </GS.Container>
                    ))}
                  </GS.Container>
                )}
              </GS.Container>
            )}
          </GS.Container>

          <GS.Container gap={GS.spacing.xs2}>
            {rule.description
              ? rule.description.map((line) => <GS.Text key={line.id}>{line}</GS.Text>)
              : ''}
          </GS.Container>
        </>
      )}

      {(!needBank || (hasBanks && hasSelectedRulesAccounts)) && (
        <GS.Container gap={GS.spacing.xs}>
          <GS.Container>
            <GS.Text fontBold>
              Esta aportación es {rule.mandatory ? 'obligatoria' : 'opcional'}
            </GS.Text>
            <GS.Text targetMovementItemAmountSt>¿Quieres aplicar la regla?</GS.Text>
          </GS.Container>
          <AcceptDecline
            onDecline={() => {
              setRejected(true);
              close();
            }}
            onAccept={() => (value && nextStepHandler(true)) || close()}
          />
        </GS.Container>
      )}

      {needBank && (!hasBanks || !hasSelectedRulesAccounts) && (
        <GS.Container gap={GS.spacing.xs}>
          <GS.Container>
            <GS.Text fontBold>
              Antes de aplicar esta regla debes seleccionar la cuenta sobre la que la vas a aplicar
            </GS.Text>
            <GS.Text targetMovementItemAmountSt>¿Quieres seleccionar la cuenta?</GS.Text>
          </GS.Container>
          <AcceptDecline
            onDecline={() => {
              setRejected(true);
              close();
            }}
            onAccept={() => nextStepBankHandler()}
          />
        </GS.Container>
      )}
    </GS.Container>
  );
};

export default RuleModal;
