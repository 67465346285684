import { Tooltip } from '@coinscrap/webapp-core';
import React from 'react';

import * as GS from 'styles/globalStyles';

const RulesTooltip = ({ content }) => (
  <Tooltip
    style={{
      containerComponent: {
        border: `2px solid ${GS.colors.green}`,
        borderRadius: '50%',
        height: 20,
        width: 20,
        color: GS.colors.green,
        fontSize: 13,
        cursor: 'pointer',
      },
      popperContentComponent: { backgroundColor: GS.colors.lightGrey, padding: GS.spacing.xs },
    }}
    placement="bottom-left"
    content={content}
  >
    ?
  </Tooltip>
);

export default RulesTooltip;
