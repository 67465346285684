import {
  UiWrapper,
  NavigationWrapper,
  TargetsWrapper,
  TargetsUtilsWrapper,
} from '@coinscrap/webapp-core';
import Footer from 'components/common/Footer/Footer';
import Header from 'components/common/Header/Header';

import routes from 'config/routes';
import TargetList from './components/TargetList';

import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';
import { Button } from 'components/common/Button/Button';

import * as GS from 'styles/globalStyles';
import { useEffect, useState } from 'react';

const Targets = () => {
  const { useHeader, useFooter, useLoading, matched } = UiWrapper.use();
  const { instanciateNewTarget } = TargetsUtilsWrapper.use();
  const { navigateTo } = NavigationWrapper.use();
  const isDesktop = matched.isDesktopMq;

  const { targets } = TargetsWrapper.use();
  const activeTargets = targets?.filter((t) => t.status !== 'FINISHED' && t.status !== 'CANCELLED');
  const parentTarget = targets?.find((t) => t.level === 0);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (targets) {
      setLoading(false);
    }
  }, [targets]);

  useLoading(loading);
  useHeader(
    isDesktop ? <Footer location="targets" /> : <Header title="Mis objetivos de ahorro" />,
    [isDesktop]
  );
  useFooter(!isDesktop && <Footer location="targets" />, [isDesktop]);

  const createNew = async () => {
    try {
      setLoading(true);
      const tp = await instanciateNewTarget({ parentTarget: parentTarget.id });
      navigateTo(routes.createTarget, {
        routeParams: { section: 'start', targetId: tp.id, step: undefined },
      });
    } catch (e) {
      console.error('ERROR', e);
    }
  };

  return !targets ? null : (
    <MaxWidthWrapper /* maxWidth={isDesktop ? GS.screenSizes.lg : GS.screenSizes.sm} */>
      {activeTargets.length >= 1 ? (
        <>
          <GS.Container
            height="100%"
            justifyContent="start"
            alignItems="center"
            padding={isDesktop ? `${GS.spacing.xs} 0 0 0` : `0 ${GS.spacing.xs}`}
          >
            <GS.Container grow={1}>
              <TargetList targets={activeTargets} />
            </GS.Container>
            <GS.Container alignItems="center" paddingBottom={GS.spacing.xs}>
              <Button text="Nuevo Objetivo" maxWidth={GS.screenSizes.xs} onClick={createNew} />
            </GS.Container>
          </GS.Container>
        </>
      ) : (
        <GS.Container
          height="100%"
          justifyContent="center"
          alignItems="center"
          padding={GS.spacing.xs}
        >
          <GS.Container grow={1} justifyContent="center">
            <GS.Text>No hay objetivos creados, crea tu primer objetivo.</GS.Text>
          </GS.Container>
          <GS.Container alignItems="center">
            <Button text="Nuevo Objetivo" maxWidth={GS.screenSizes.xs} onClick={createNew} />
          </GS.Container>
        </GS.Container>
      )}
    </MaxWidthWrapper>
  );
};

export default Targets;
