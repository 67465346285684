import styled from 'styled-components/macro';
import * as GS from '../../../styles/globalStyles';

export const ModalContent = styled(GS.Container)`
  align-items: center;
  background: ${GS.colors.white};
  border-radius: ${GS.radius.md};
  max-width: ${GS.screenSizes.sm};
  padding: ${({ isDesktop }) =>
    isDesktop ? `${GS.spacing.lg} ${GS.spacing.xs2}` : `${GS.spacing.xs} ${GS.spacing.xs2}`};
  position: relative;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: ${({ isDesktop }) => (isDesktop ? GS.spacing.xs2 : '0')};
  top: ${({ isDesktop }) => (isDesktop ? `${GS.spacing.xs2}` : `-${GS.spacing.md}`)};
`;
