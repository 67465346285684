const { RulesWrapper } = require('@coinscrap/webapp-core');
const { useEffect, useState } = require('react');

const useRuleValue = (rule) => {
  const { extractValue } = RulesWrapper.use();
  const extractedValue = extractValue(rule, rule?.ruleDefinition?.fieldDefinitions[0]?.identifier);
  const [value, setValue] = useState(extractedValue || rule.defaultValue);

  console.log('RULE', rule);
  useEffect(() => {
    if (rule && rule.ruleDefinition?.fieldDefinitions) {
      setValue(extractedValue);
    }
  }, [extractedValue]);

  return [value, setValue];
};

export default useRuleValue;
