import styled from 'styled-components/macro';
import * as GS from '../../../styles/globalStyles';

export const ModalContainer = styled.div`
  align-items: center;
  background-color: ${({ bgColor }) => bgColor || GS.colors.white};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
`;

export const ModalContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  max-width: ${GS.screenSizes.sm};
  padding: 2em 0 2em 0;
  width: calc(100% - ${GS.spacing.xs});
`;
