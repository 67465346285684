import { useEffect, useState } from 'react';
import { Page, UserWrapper } from '@coinscrap/webapp-core';
import routes from 'config/routes';

import { Tour } from './components/Tour';
import Finished from './components/Finished';
import { FetchPolicies } from './components/FetchPolicies';

import * as GS from 'styles/globalStyles';

const OnBoarding = () => {
  const { params } = Page.use();
  const { user } = UserWrapper.use();
  const [section, setSection] = useState(undefined);

  useEffect(() => {
    if (user?.metadata?.consentInformed) {
      setSection(routes.fetchPolicies);
    } else {
      setSection(params.section);
    }
  }, [user, params.section]);

  return (
    <GS.Container height="100%" justifyContent="center" alignItems="center">
      {user && section && (
        <>
          {section === routes.tour && <Tour />}
          {section === routes.finished && <Finished />}
          {section === routes.fetchPolicies && <FetchPolicies />}
        </>
      )}
    </GS.Container>
  );
};

export default OnBoarding;
