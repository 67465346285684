import { ReactComponent as MABLogo } from 'assets/mab-logo-text.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import { Button } from 'components/common/Button/Button';

import * as GS from 'styles/globalStyles';

const Modal = ({ close, txt, click }) => (
  <GS.Container width="100%" background={GS.colors.white} borderRadius={GS.radius.sm}>
    <GS.Container
      justifyContent="start"
      alignItems="center"
      padding={GS.spacing.xs}
      gap={GS.spacing.xs}
      grow={1}
    >
      <SVGIcon Icon={MABLogo} width={GS.spacing.xl8} height="auto" color={GS.colors.green} />
      <GS.Text Rufina fontBold fontSize={GS.fontSizes.md}>
        {txt}
      </GS.Text>
      <Button
        text="Ok"
        maxWidth={GS.spacing.xl}
        onClick={() => {
          click();
          close();
        }}
      />
    </GS.Container>
  </GS.Container>
);

export default Modal;
