/* eslint-disable react/no-danger */
/* eslint-disable prettier/prettier */
export const TermsComponent = () => (
  <div
    dangerouslySetInnerHTML={{
      __html: `
        <p style="text-align:center"><span style="font-size:14pt"><span style="font-family:Rufina"><span style="color:#001a13"><strong>Información general</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">El titular de este servicio es la MUTUALIDAD GENERAL DE LA ABOGACÍA, MUTUALIDAD DE PREVISIÓN SOCIAL A PRIMA FIJA(en adelante “Mutualidad de la Abogacía” o “la Mutualidad”), con domicilio en calle Serrano, nº 9, 28001, Madrid y NIF V-28/024149, inscrita en el Registro Mercantil de Madrid, en el tomo 22, folio 115, sección 8ª, hoja nº 478 y autorizada para el ejercicio de la actividad aseguradora por la Dirección General de Seguros y Fondos de Pensiones, inscrita en el Registro Público de Entidades Aseguradoras con la Clave P-2131.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Los términos y condiciones recogidos en el presente documento regulan el us del servicio #YoSíAhorro(el “Servicio”), disponible a través de la App Mutualidad Abogacía y del área personal de la página web de Mutualidad de la Abogacía.</span></span></span></p>
  
  <p>&nbsp;</p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Descripción del servicio #YoSíAhorro</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Mutualidad de la Abogacía pone a disposición de los mutualistas que hayan contratado el Sistema de Ahorro Flexible (SVA) del Plan Universal el servicio #YoSíAhorro, que facilita ahorrar a través de innovadoras metodologías.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Así, el servicio #YoSíAhorrote permite establecer reglas de ahorro personalizadas que acumularán de forma automática un ahorro virtual que se transferirá a tu Sistema de Ahorro Flexible contratado con la Mutualidad, incrementando tu fondo acumulado.</span></span></span></p>
  
  <p>&nbsp;</p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Condiciones de acceso y prestación del Servicio</strong></span></span></span></p>
  
  <ul>
      <li style="list-style-type:disc"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Acceso al servicio</strong></span></span></span></li>
  </ul>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Puedes contratar y acceder al Servicio si eres mutualista mayor de edad y has contratado el Sistema de Ahorro Flexible (SVA) del Plan Universal.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">IMPORTANTE: para poder hacer uso del Servicio, será necesario que facilites las credenciales o claves de acceso a tu oficina virtual de su/s entidad/es bancaria/s, las cuales variarán según tu entidad bancaria (por ejemplo: DNI del usuario y contraseña, o DNI del usuario y fecha de nacimiento o usuario y contraseña) y procedas a la vinculación de una o varias cuentas o tarjetas bancarias cuyos movimientos quieras asociar a la prestación del servicio.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Las mencionadas credenciales o claves de acceso que el Servicio te solicitará para poder acceder a los datos e información de tu/s cuenta/s bancaria/s únicamente permitirán al servicio consultar tus movimientos bancarios. Es decir, se tratará en todo caso de credenciales o claves de consulta y nunca operativas. Por tanto, a través del servicio,se realizará la consulta de tus movimientos pero no se podrá, en ningún caso, operar con tu/s cuenta/s bancaria/s ni te solicitará información alguna que lo permita por ningún medio(como por ejemplo claves adicionales o coordenadas facilitadas por tu/s entidad/es bancaria/s para realizar operaciones tales como transferencias, traspasos, contratación de productos bancarios, pagos, elevación de límites de tarjetas, etc.).</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">IMPORTANTE: El servicio de información sobre cuentas de pago o agregación bancaria se facilita por el proveedor MORPHEUS AIOLOS S.L. (AFTERBANKS), que cuenta con licencia europea de agregación bancaria. La citada agregación deberá ser aceptada por ti de forma expresa y se prestará bajo los términos y condiciones propios de este servicio.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La aceptación del servicio de información sobre cuentas de pago o agregación bancaria es un requisito para la utilización del servicio #YoSíAhorro, pues de otro modo desde Mutualidad de la Abogacía no podríamos acceder a los movimientos asociados a tus objetivos de ahorro para poder prestarte este servicio.</span></span></span></p>
  
  <ul>
      <li style="list-style-type:disc"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Establecimiento de objetivos</span></span></span></li>
  </ul>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Los objetivos representan las características financieras para alcanzar tus deseos: importe, periodo y reglas a activar; redondeo, aportaciones mensuales, % de tus ingresos, sin cafeína, sin nicotina, equipo, etc.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Dentro de cada objetivo puedes ajustar tus preferencias y alcanzarlo a tu propio ritmo. Para ello, tienes la opción de personalizar las siguientes variables:</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Precio (importe) correspondiente al producto o servicio que te quieres comprar, o el importe que quieres ahorrar.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Periodo: el periodo representa en cuánto tiempo deseas conseguir el importe elegido antes.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Aportaciones mensuales: representa el complemento fijo del ahorro mensual que quieres añadir para alcanzar tu objetivo cuanto antes (cuanto más aportes al mes, antes completarás el objetivo). Esta aportación se realizará el primer viernes de cada mes.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Redondeo: esta opción permite escoger a qué altura quieres hacer el redondeo: al euro más cercano (1),a los dos euros (2), a los cinco euros (5) o a los diez euros más cercanos (10). Por ejemplo, si pagas 1,25€ en tu compra y estableces el redondeo a los 2€, ahorrarías 0,75€ en tu lista de redondeos. Con un redondeo a los 5€ ahorrarías 3,75€ y con un redondeo a los 10€ ahorrarías 8,75€. 
  El servicio de redondeo podrá utilizarse vinculando tarjetas de crédito (no de débito). En caso de no disponer de una tarjeta de crédito, podrás configurar los objetivos a partir de una cuenta bancaria.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Ingresos: Por cada movimiento positivo que tengas en tu cuenta o tarjeta, ahorrarás un porcentaje.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Sin cafeína: Activa esta regla y ahorrarás de forma automática 1€ por cada día libre de cafeína. Si caes en la tentación, solo tienes que entrar en el apartado de reglas - sincafeína y penalizarte sin tu ahorro diario.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Sin nicotina: Activando esta regla, por cada día que consigas mantenerte alejado de un cigarrillo, ahorrarás de forma automática 1€. Si caes en la tentación, solo tienes que entrar en el apartado de reglas -sinnicotina y penalizarte sin tu ahorro diario.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"> - Equipo: Estamos conectados con todos los partidos oficiales del Campeonato Nacional de Liga de Primera División de manera que si tienes esta regla activa, con cada partido que gane tu equipo, ahorrarás 1€.</span></span></span></p>
  
  <p>&nbsp;</p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Acumulación del ahorro y rescates</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">El ahorro acumulado se calcula diariamente y se transferirá a tu Sistema de Ahorro Flexible cada viernes (según la entidad bancaria el plazo de cobro varía entre 2 a 3 días laborales), siempre y cuando hayas alcanzado el mínimo de 30 €. Si el ahorro acumulado en todas tus reglas no llega a un mínimo de 30€, tendrás la posibilidad de hacer una aportación puntual para alcanzar dicha cuantía, o esperar semanalmente hasta acumular el mínimo de 30€</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Las aportaciones periódicas se cargan el primer viernes de cada mes junto con el importe que corresponda en esa semana, en su caso, en concepto del resto de reglas de ahorro establecidas.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Asimismo, podrás hacer el seguimiento de tu ahorro: la cantidad ahorrada y la velocidada la cual estás ahorrando con tus redondeos y resto de reglas. También podrás comprobar cuándo se cargarán tus aportaciones y medir el esfuerzo realizado.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Una vez que el ahorro generado se ha transferido a tu fondo acumulado del Sistema de Ahorro Flexible(SVA) del Plan Universal de Mutualidad de la Abogacía, podrás disponer del mismo en cualquier momento, conforme a lo establecido en las condiciones aplicables a tu contrato (Reglamento de aportaciones y prestaciones del Plan Universal).</span></span></span></p>
  
  <p>&nbsp;</p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Condiciones de uso</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">El alta en el Servicio implica la lectura y aceptación sin reserva alguna de los presentes términos y condiciones, los cuales manifiestas haber conocido previamente a su aceptación, pudiendo ser almacenados y reproducidos.Mutualidad de la Abogacía se reserva el derecho a modificar las condiciones de prestación del servicio,en cuyo caso te lo notificaremos cuando vuelvas a acceder al servicioy/o a través de tu correo electrónico.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Con el alta en el servicio, te comprometes expresamente a hacer un usoadecuado de los contenidos, con total sujeción a los términos y condiciones, así como a la normativa que sea de aplicación, y a no emplearlos para incurrir en actividades ilícitas o contrarias a la buena fe y al ordenamiento legal.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Queda expresamente prohibido realizar acciones lesivas a los intereses o derechos de terceros, o que de cualquier forma pueda dañar, inutilizar o deteriorar la aplicación o sus servicios o impedir un normal disfrute de la aplicación por otros usuarios, o que dañen, interrumpan ogeneren errores en dichos sistemas o servicios.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Igualmente, te comprometes a no introducir programas, virus, macros, applets, controles ActiveX o cualquier otro dispositivo lógico o secuencia de caracteres que causen o sean susceptibles de causar cualquier tipo de alteración en los sistemas informáticos de Mutualidad de la Abogacía o de terceros.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Mutualidad de la Abogacía se reserva el derecho a limitar, impedir o eliminar el acceso al Servicio cuando surjan dificultades técnicas por hechos o circunstancias ajenos a la compañía que, a su juicio, disminuyan o anulen los niveles de seguridad adoptados para el correcto funcionamiento del acceso al mismo, así como por motivos técnicos, de mantenimiento o por cualquier otra causa.</span></span></span></p>
  
  <p>&nbsp;</p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Tratamiento de datos personales y seguridad de la información</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">El tratamiento de tus datos de carácter personal se somete a lo dispuesto en la Política de privacidad . Te recomendamos que con carácter previo al alta en el servicio leas detenidamente la Política de privacidad.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La Mutualidad ha adoptado los niveles de seguridad adecuados a los datos facilitados por los usuarios y, además, ha instalado todos los medios y medidas a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y extracción de los mismos.El servicio funciona sobre un servidor seguro utilizando el protocolo SSL. El servidor seguro establece una conexión de modo que la información se transmite cifrada mediante algoritmos de 256 bits, que aseguran que solo sea inteligible para el ordenador del usuario y el del Sitio Web</span></span></span></p>
  
  <p>&nbsp;</p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Baja del servicio</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Podrás cancelar tu cuenta en cualquier momento enviando una comunicación al correo electrónico yosiahorro@mutualidadabogacia.com.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La simple eliminación de la aplicación de sus dispositivos móviles no supondrá la baja de los servicios contratados, siendo necesario en caso de desearla seguir el procedimiento establecido en este documento.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Una vez cancelados los servicios, no podrás tener acceso a tus datos ni a ninguna de tus operaciones realizadas por medio de la Aplicación.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Si, solicitada la baja, existiera ahorro acumulado, éste no será transferido a tu Sistema de Ahorro Flexible si no alcanza el importe mínimo, sino que se tendrá por no generado.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Mutualidad de la Abogacía podrá requerir que durante este proceso rellenes algún tipo de formulario relativo a las impresiones y opiniones del usuario sobre su experiencia con la Aplicación. La decisión de cumplimentar y enviar dicho formulario será tuya, no pudiendo Mutualidad de la Abogacía impedir la baja en caso de que no desees responder.</span></span></span></p>
  
  <p>&nbsp;</p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Limitación de la responsabilidad</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La Mutualidad no será responsable en caso de que se tenga que interrumpir el servicio, retraso o mal funcionamiento y otros inconvenientes que tengan su origen en causas que se escapen del control de la Mutualidad y/o sean debidas a una actuación dolosa o culpable por tu parte y/o tengan su origen por causas de fuerza mayor.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La información mostrada puede no ser correcta, pese a los esfuerzos realizados para llevara cabo su exactitud y actualización. En consecuencia, la Mutualidad no se responsabiliza de los errores contenidos, ni de los daños o perjuicios derivados de su utilización.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">El servicio puede contener enlaces a otros sitios web de cuyo contenido, utilidado exactitud no se puede responsabilizar la Mutualidad. La Mutualidad no conoce los contenidos y servicios de los enlaces y por tanto no se hace responsable por los daños derivados de su falta de calidad, desactualización, indisponibilidad, error, inutilidad o ilegalidad y no responde de las manifestaciones realizadas o de los contenidos o servicios proporcionados a través de ellos. Si tuvieras conocimiento de que los enlaces remiten a páginas cuyos contenidos o servicios sean ilícitos, nocivos, denigrantes, violentos o inmorales, podrás ponerte en contacto con la Mutualidad indicándolo.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La Mutualidad no se hace responsable de los daños y perjuicios derivados del uso de esta aplicación de sus contenidos, no garantizando la ausencia de virus o la imposibilidad de causar daños en tus sistemas informáticos.</span></span></span></p>

  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La Mutualidad no se hace responsable de las condiciones de la prestación del servicio de información sobre cuentas de pago o agregación bancaria por parte de MORPHEUS AIOLOS S.L. (AFTERBANKS) ni de eventuales daños y perjuicios que puedan ocasionarse con motivo de la prestación del servicio por aquél.</span></span></span></p>
  
  <p>&nbsp;</p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828"><strong>Ley aplicable y jurisdicción</strong></span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">La relación entre Mutualidad de la Abogacía y el usuario se regirá por los presentes términos y condiciones, por el Reglamento de aportaciones y prestaciones del Plan Universal y la normativa española vigente.</span></span></span></p>
  
  <p style="text-align:left"><span style="font-size:10pt"><span style="font-family:Mutualidad"><span style="color:#003828">Las partes se someten para la resolución de los conflictos, a los juzgados y tribunales del domicilio del usuario. Ello, sin perjuicio de la utilización de cualquiera de las vías a disposición de los mutualistas para la resolución de controversias, que puede consultar en www.mutualidadabogacia.com/aviso-legal/</span></span></span></p>

  <p>&nbsp;</p>`,
    }}
  />
);
