import { Page } from '@coinscrap/webapp-core';
import { BanksAndAccounts } from './components/BanksAndAccounts';
import Historic from './components/Historic';
import Faqs from './components/Faqs';

const Setting = () => {
  const { params } = Page.use();

  return (
    <>
      {params.section === 'banksAndAccounts' && (
        <BanksAndAccounts title="Bancos y cuentas" section={params.section} />
      )}
      {params.section === 'historic' && <Historic title="Histórico" section={params.section} />}
      {params.section === 'faqs' && <Faqs title="FAQ'S" section={params.section} />}
    </>
  );
};

export default Setting;
