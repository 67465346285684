import { UiWrapper } from '@coinscrap/webapp-core';
import colors from '../colors';

UiWrapper.defineComponent('slider', {
  styles: {
    default: {
      dotsContainerComponent: {},
      dotComponent: {
        backgroundColor: colors.white,
        margin: '0 4px',
      },
      dotActiveComponent: {
        backgroundColor: colors.red,
      },
    },

    homeSliderSt: {
      dotsContainerComponent: {
        paddingTop: 20,
        position: 'initial',
      },
    },
  },
});
