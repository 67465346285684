const routes = {
  errorToken: 'errorToken',
  tokenEntry: 'tokenEntry',
  splash: 'splash',
  access: 'access',
  home: 'home',
  onBoarding: 'onBoarding',
  tour: 'tour',
  finished: 'finished',
  fetchPolicies: 'fetchPolicies',
  autoConfigure: 'autoConfigure',
  chargeAccount: 'chargeAccount',

  targets: 'targets',
  rules: 'rules',
  rule: 'rule',
  target: 'target',
  createTarget: 'createTarget',
  rulesAccounts: 'rulesAccounts',

  details: 'details',
  banksStart: 'banksStart',
  banksSelect: 'banksSelect',
  banksConnect: 'banksConnect',
  banksAwait: 'banksAwait',
  settingBanksAccount: 'setting/banksAndAccounts',
  settingFaqs: 'setting/faqs',

  operationResolution: 'operationResolution',

  summary: 'summary',
  chat: 'chat',
  settings: 'settings',
  setting: 'setting',
};

export default routes;
