//TODO: real data

export const aiolosPrivacy = [
  {
    description: `En Morpheus Aiolos S.L., somos conscientes que la protección y privacidad de su información personal es muy importante, por ello, estamos comprometidos con estándares de seguridad de alto nivel para proteger la confidencialidad, e integridad de sus datos. 
    
    En esta política de privacidad, en adelante, (" Política de Privacidad "), le mostraremos cómo Morpheus Aiolos S.L. recopila, usa, divulga y protege la Información Personal que usted como usuario nos envía al acceder y usar todos los sitios web de Arcopay, de forma voluntaria. 
    
    Nuestra Política de Privacidad ha sido elaborada para cumplir con las normativa aplicable, en particular, el Reglamento (UE) 2016/679 de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos , así como, la Ley Orgánica 3/2018 de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales .`,
  },
  {
    title: 'Identidad y dirección del Responsable de Tratamiento',
    description: `El Responsable del Tratamiento de los datos personales que resultan del servicio prestado en este sitio web es Morpheus Aiolos S.L, titular de la marca Arcopay. Los datos de contacto son los siguientes: 
    
    -Calle De San Andrés 8, local Madrid (Madrid), 28004. 
    -CIF: B86556420. 
    -Tel.: 911126709. 
    -El correo electrónico a los efectos de facilitarle cualquier información o aclaración en materia de protección de datos, es gdpr@arcopay.io 
    
    Morpheus Aiolos S.L. es una Entidad de Pago debidamente autorizada y registrada en el Banco de España para la prestación de los servicios de iniciación de pagos e información sobre cuentas. Su número de registro en el Registro Oficial de Entidades del Banco de España es 6901.`,
  },
  {
    title: 'Finalidad y legitimación del tratamiento',
    description: `
 A continuación, le mostramos las diferentes situaciones en las que recopilamos su información personal, indicándole la finalidad y la base jurídica del tratamiento: 
 
 (i) Cuando se registra en la aplicación de Arcopay para recibir un servicio de iniciación de pago o de información sobre cuenta, recopilamos los datos necesarios para la prestación de los mencionados servicios. El tratamiento es necesario para que podamos llevar a cabo de manera efectiva la prestación de nuestros servicios a usted. La base jurídica es el consentimiento que se otorga a través de las casillas habilitadas a tal efecto. 
 
 (ii) Cuando usted haya sido redireccionado a cualquier site de la web de Morpheus Aiolos porque esté utilizando un servicio a través de otra empresa que sea nuestro cliente. El tratamiento es necesario para que los servicios prestados sean efectivos. La base jurídica es el consentimiento que usted otorga previamente a través de las casillas habilitadas a tal efecto. 
 
 (iii) Cuando a través del chat de Arcopay, recopilamos su nombre, el nombre de su empresa, su correo electrónico, y otra información de contacto. Estos datos serán tratados con la finalidad de ponernos en contacto con usted a través de su solicitud. La base jurídica de este tratamiento es el consentimiento que recibimos de su propia solicitud.

 (iv) Cuando accede a la web de Morpheus Aiolos S.L., nosotros recibimos de usted datos técnicos de información a través de la aceptación de las cookies. La finalidad de este tratamiento es el diagnóstico de cualquier problema técnico, preferencias personales, estadísticas de uso,etc. En la política de cookies de Morpheus Aiolos S.L., se puede conocer el detalle de las cookies que usa Morpheus Aiolos S.L., así como la manera en la que usted puede controlar ese tratamiento. La base jurídica de este tratamiento es el consentimiento que usted presta al aceptar el uso de cookies. 
 
 Conforme al consentimiento otorgado, se le informa al usuario que tiene pleno derecho a retirar el consentimiento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
`,
  },
  {
    title: 'Plazo de conservación de la información personal.',
    description: `El plazo de conservación de la información personal que almacenamos de usted, se basará en el motivo por el que la hayamos recopilado. En concreto, conforme a lo siguiente: 
    
    (i) Cuando haya accedido con motivo de iniciar una solicitud de información a través del chat o cualquier otro apartado así dispuesto en la web de Morpheus Aiolos S.L., conservaremos sus datos hasta que la relación entre nosotros haya cesado. 
    
    (ii) Cuando se registre en la aplicación de Morpheus Aiolos S.L., conservaremos sus datos hasta que decida darse de baja de la misma. 
    
    (iii) Cuando usted haya sido redireccionado con la finalidad de obtención de otro servicio por otra empresa que es nuestro cliente, conservaremos sus datos durante el periodo necesario estipulado en los acuerdos con nuestros clientes.`,
  },
  {
    title: 'Destinatarios de cesiones o transferencias internacionales de datos.',
    description: `No se prevé la cesión o transferencia de los datos de carácter personal a destinatarios internacionales. 
    
    En el caso de que transfiramos en un futuro información personal a países fuera del Espacio Económico Europeo, utilizaremos las medidas de seguridad adecuadas para la protección de su privacidad, derechos y libertades fundamentales, y se lo notificaremos con la actualización de la presente política de privacidad.`,
  },
  {
    title: 'Derechos que le asisten y formas de ejercicio.',
    description: `Le informamos que usted tiene una serie de derechos que puede ejercer poniéndose en contacto con Morpheus Aiolos S.L., como Responsable de tratamiento, en concreto: derecho de acceso, derecho de supresión, derecho de limitación de su tratamiento, derecho de oposición, derecho de revocación, derecho a la portabilidad de sus datos y derecho a retirar el consentimiento prestado. 
    
    La forma en la que puede ejercer estos derechos es remitiendo un correo electrónico a la dirección gdpr@arcopay.io ., con el asunto “datos personales”. Igualmente, tiene derecho a reclamar ante la Autoridad de Control.`,
  },
  {
    title: 'Revisión de la Política de Privacidad',
    description: `Morpheus Aiolos S.L. realizará una revisión de la Política de Privacidad anualmente, salvo que existan exigencias normativas o de otra índole que hagan necesario adaptar la Política con una periodicidad inferior. Por tanto, se aconseja a los usuarios que consulten la Política en cada una de las ocasiones que vaya a acceder o utilizar páginas, websites o herramientas web propiedad de Morpheus Aiolos S.L. o, al menos, consulte periódicamente su contenido. 
    
    Fecha actualización: 27 de noviembre de 2020. 
`,
  },
];
