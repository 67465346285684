import React from 'react';

import { Spinner } from '../Spinner/Spinner';
import * as S from './styles';

import * as GS from 'styles/globalStyles';

export const CustomLoading = ({ loadingProps }) => {
  const { type, message } = loadingProps;

  const bgColor =
    type && type === 'transparent' ? GS.colors.blackStrongTransparent : GS.colors.pink;

  let text = 'Recopilando datos';
  if (message) {
    text = message === 'no' ? '' : message;
  }

  return (
    <S.ModalContainer bgColor={bgColor}>
      <S.ModalContent>
        <Spinner color="violet" />
        <GS.Text
          color={type && type === 'transparent' ? GS.colors.green : GS.colors.darkViolet}
          paddingTop={GS.spacing.xs}
        >
          {text}
        </GS.Text>
      </S.ModalContent>
    </S.ModalContainer>
  );
};
