import { RulesWrapper } from '@coinscrap/webapp-core';
import getCurrencyDecimals from 'utils/getCurrencyDecimals';
import { getRemainingMonths } from 'utils/getMonths';

import { ReactComponent as Check } from 'assets/svgs/check.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const DataRow = ({ text }) => (
  <GS.RowContainer alignItems="center" gap={GS.spacing.xs3}>
    <SVGIcon Icon={Check} width={GS.spacing.xs} height="auto" color={GS.colors.green} />
    <GS.Text fontBold textLeft fontSize={GS.fontSizes.xs}>
      {text}
    </GS.Text>
  </GS.RowContainer>
);

const ItemData = ({ target, rufina }) => {
  const { rules } = RulesWrapper.use();
  const activeRules = rules.filter((r) => r.targetId === target?.id && r.isActive);

  return (
    <GS.Container gap={GS.spacing.xs2}>
      <GS.Text textLeft fontBold Rufina={rufina} fontSize={GS.fontSizes.lg}>
        {target?.name || 'Objetivo'}
      </GS.Text>
      <GS.Container>
        <DataRow text={getCurrencyDecimals(target?.progress.total) + '€'} />
        <DataRow text={getRemainingMonths(target) + ' meses'} />
        <DataRow text={activeRules.length ? 'Reglas de ahorro' : 'Sin reglas de ahorro'} />
      </GS.Container>
    </GS.Container>
  );
};

export default ItemData;
