import { useCallback, useEffect, useState } from 'react';
import { NavigationWrapper, Page, TargetsWrapper, UiWrapper } from '@coinscrap/webapp-core';
import moment from 'moment';

import routes from 'config/routes';

import useTarget from 'hooks/useTarget';
import useInstitutionAccounts from 'hooks/useInstitutionAccounts';
import { useInstitutions } from 'hooks/useInstitutions';

import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import BanksCollapse from 'components/common/BanksCollapse';
import BankItem from 'components/common/BankItem/BankItem';
import Scrollable from 'components/common/Scrollable/Scrollable';
import { Button } from 'components/common/Button/Button';

import * as GS from 'styles/globalStyles';

const Bank = () => {
  const { navigateTo } = NavigationWrapper.use();
  const { saveTargetRulesConfiguration } = TargetsWrapper.use();
  const { data: institutions } = useInstitutions();
  const { params } = Page.use();
  const target = useTarget(params.targetId);
  const banks = useInstitutionAccounts();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  //const [rulesAccounts, setRulesAccounts] = useState();

  const [rulesAccounts, setRulesAccounts] = useState(undefined);
  useEffect(() => {
    if (target?.targetRulesConfiguration?.metadata?.ruleAccounts) {
      setRulesAccounts(
        target.targetRulesConfiguration.metadata.ruleAccounts.reduce(
          (acc, act) => ({ ...acc, [act]: true }),
          {}
        )
      );
    }
  }, [target]);

  const saveTargetRulesAccounts = async (rules) => {
    await saveTargetRulesConfiguration(params.targetId, {
      ruleAccounts: Object.keys(rules).filter((x) => rules[x]),
    });
  };

  const onBankItemChange = (item, product, checked) => {
    const newRulesAccounts = {
      ...rulesAccounts,
      [item.id + '-' + product.id]: checked,
    };
    setRulesAccounts(newRulesAccounts);
    saveTargetRulesAccounts(newRulesAccounts);
  };

  const getInstitution = useCallback((id) => institutions?.find((i) => i.identifier === id), [
    institutions?.length,
  ]);

  return (
    <>
      <GS.Container>
        <SectionTitle text="Bancos y cuentas" padding={`${GS.spacing.xs} 0`} />
        <Scrollable arrowBottom={isDesktop}>
          <GS.Container gap={GS.spacing.xs2}>
            {banks?.map((bank) => (
              <BanksCollapse
                key={bank?.name}
                logo={
                  bank.institutionIdentifier
                    ? getInstitution(bank.institutionIdentifier)?.logo || bank?.image
                    : bank?.image
                }
                title={bank?.name}
                updated={`Actualizado hace ${moment((bank.products || [])[0]?.lastImportDate)
                  .locale('es')
                  .fromNow()}`}
              >
                {bank?.products
                  ?.filter((account) => account.isOwner === true)
                  .map((account, index) => (
                    <BankItem
                      key={account?.name}
                      type={account?.type}
                      name={account?.name}
                      number={`**** ${account?.identifier.slice(-4)}`}
                      isActive={rulesAccounts?.[bank.id + '-' + account.id]}
                      onChange={(checked) => onBankItemChange(bank, account, checked)}
                      last={
                        index === bank.products.filter((acc) => acc.isOwner === true).length - 1
                      }
                    />
                  ))}
              </BanksCollapse>
            ))}
          </GS.Container>
        </Scrollable>
      </GS.Container>
      <GS.Container alignItems="center" paddingBottom={GS.spacing.xs}>
        <Button
          text="Añadir nuevo banco"
          buttonStyle="minimal"
          width={GS.spacing.xl7}
          onClick={() => {
            navigateTo(routes.banksStart, {
              queryParams: {
                from: {
                  name: routes.target,
                  routeParams: {
                    section: 'editBank',
                    targetId: params.targetId,
                  },
                },
              },
            });
          }}
        />
      </GS.Container>
    </>
  );
};

export default Bank;
