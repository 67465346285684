import { UiWrapper } from '@coinscrap/webapp-core';
import * as GS from 'styles/globalStyles';

UiWrapper.defineComponent('inputCheck', {
  styles: {
    default: {
      containerComponent: {},
      labelComponent: {},
      checkComponent: {
        padding: 4,
      },
      checkedMode: {
        checkComponent: {
          color: GS.colors.green,
          backgroundColor: GS.colors.white,
          padding: 4,
        },
      },
    },

    whiteSt: {
      containerComponent: {
        backgroundColor: 'transparent',
      },
      checkComponent: {
        color: GS.colors.white,
        backgroundColor: 'transparent',
        padding: 4,
      },
      checkedMode: {
        checkComponent: {
          color: GS.colors.white,
          backgroundColor: 'transparent',
          padding: 4,
        },
      },
    },

    greenSt: {
      containerComponent: {
        backgroundColor: 'transparent',
      },
      checkComponent: {
        color: GS.colors.green,
        backgroundColor: 'transparent',
        padding: 4,
      },
      checkedMode: {
        checkComponent: {
          color: GS.colors.green,
          backgroundColor: 'transparent',
          padding: 4,
        },
      },
    },
  },
});
