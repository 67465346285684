export const mergeRulesById = (a1, a2) =>
  a1?.map((itm) => ({
    ...a2?.find((item) => item.ruleDefinitionId === itm.id && item),
    ...itm,
  }));

export const mergeRulesByIdentifier = (a1, a2) =>
  a1?.map((itm) => ({
    ...a2?.find((item) => item.identifier.toLowerCase() === itm.identifier && item),
    ...itm,
  }));
