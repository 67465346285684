import { UiWrapper } from '@coinscrap/webapp-core';

UiWrapper.defineComponent('inputSelect', {
  styles: {
    default: {
      containerComponent: {},
      selectContainerComponent: {},
      labelComponent: {},
      menuContainerComponent: {},
    },
  },
});
