import { NavigationWrapper, Page, UiWrapper } from '@coinscrap/webapp-core';
import moment from 'moment';
import routes from 'config/routes';

import useTarget from 'hooks/useTarget';
import getCurrencyDecimals from 'utils/getCurrencyDecimals';
import { ruleNameConverter } from 'utils/ruleNameConverter';

import Collapse from 'components/common/Collapse';
import SectionTitle from 'components/common/SectionTitle/SectionTitle';
import Header from '../../Header';
import Footer from '../../Footer';
import MovementsItem from './MovementsItem';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

const MovementsDetails = () => {
  const { params } = Page.use();
  const { useHeader, useFooter } = UiWrapper.use();
  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;
  const target = useTarget(params.targetId);
  const movement = target?.movements.find((m) => m.id === params.movementId);

  const { navigateTo } = NavigationWrapper.use();

  useHeader(
    <GS.Container>
      {isDesktop && (
        <Footer
          backHandler={() => navigateTo(routes.target)}
          section={params.section}
          id={params.id}
        />
      )}
      <Header
        target={target}
        onClickLeft={() =>
          navigateTo(routes.target, { routeParams: { targetId: params.targetId } })
        }
        onClickRight={() =>
          navigateTo(routes.target, { routeParams: { section: 'edit', targetId: params.targetId } })
        }
      />
    </GS.Container>,
    [target, params.section, isDesktop]
  );

  useFooter(<>{!isDesktop && <Footer section={params.section} targetId={params.targetId} />}</>, [
    params,
    isDesktop,
    target,
  ]);

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="start"
        alignItems="center"
        padding={isDesktop ? `${GS.spacing.xs} 0` : GS.spacing.xs}
        gap={GS.spacing.xs}
      >
        <SectionTitle text="Detalle del movimiento" />
        <GS.Container background={GS.colors.lightGrey} padding={GS.spacing.xs}>
          <GS.Text textLeft fontBold>
            {moment(movement?.valueDate).format('DD [de] MMMM')}
          </GS.Text>
        </GS.Container>
        <GS.RowContainer
          justifyContent="space-between"
          alignItems="center"
          background={GS.colors.lighterGrey}
          padding={GS.spacing.xs}
        >
          <GS.Text textLeft fontBold>
            {movement?.concept}
          </GS.Text>
          <GS.Text textLeft fontBold>
            {getCurrencyDecimals(movement?.amount)}€
          </GS.Text>
        </GS.RowContainer>
        {movement?.movementLines && (
          <Collapse title="Detalle" isOpened>
            {movement.movementLines.map((movementLine) => (
              <MovementsItem
                key={movementLine.id}
                date={moment(movementLine.createDate).format('D MMM')}
                amount={movementLine.amount}
                text={ruleNameConverter(movementLine.title)}
              />
            ))}
          </Collapse>
        )}
      </GS.Container>
    </MaxWidthWrapper>
  );
};

export default MovementsDetails;
