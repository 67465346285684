import { UiWrapper } from '@coinscrap/webapp-core';
import colors from 'config/colors';
import fonts from 'config/fonts';

import commonStyles from './commonStyles';

UiWrapper.defineComponent('text', {
  styles: {
    default: {
      lineHeight: 1,
      color: colors.darkBlue,
    },

    centerSt: {
      textAlign: 'center',
    },

    boldSt: {
      fontFamily: fonts.GothamBold,
    },

    //Components
    footerLabelSt: {
      transition: 'all 0.2s ease',
      fontSize: 9,
      marginTop: 8,
      fontWeight: 700,
    },

    accessPreTitleSt: {
      textTransform: 'uppercase',
      color: colors.red,
      fontFamily: fonts.GothamBold,
    },

    h1St: {
      color: colors.lightBlue,
      fontSize: 20,
      fontFamily: fonts.GothamBold,
      lineHeight: 1.2,
    },

    h1UpperCaseSt: {
      fontSize: 18,
      textTransform: 'uppercase',
      fontFamily: fonts.GothamBold,
      marginTop: 40,
      marginBottom: 30,
      textAlign: 'center',
    },

    txtGralSt: {
      fontSize: 13,
      lineHeight: 1.3,
      color: colors.black,
      $mediaQueries: {
        isDesktopMq: {
          fontSize: 16,
        },
      },
    },

    onBoardingDescriptionTextSt: {
      textAlign: 'center',
      width: '75%',
      marginTop: 20,
      marginBottom: 20,
      fontSize: 14,
      $mediaQueries: {
        isDesktopMq: {
          fontSize: 16,
        },
      },
    },

    homeSliderAmountSt: {
      color: colors.darkBlue,
      fontFamily: fonts.GothamBold,
      fontSize: 40,
      marginTop: 20,
      lineHeight: 0.8,
    },

    homeSliderTextSt: {
      width: '50%',
      textAlign: 'center',
      lineHeight: 1.2,
    },

    homeBottomSectionTextSt: {
      width: '50%',
      fontSize: 25,
      fontFamily: fonts.GothamBold,
      color: colors.white,
      textAlign: 'right',
      marginRight: 15,
      lineHeight: 1.1,
      marginTop: 35,
    },

    homeBottomSectionLinkTextSt: {
      fontSize: 18,
      fontFamily: fonts.GothamBold,
      color: colors.white,
      marginRight: 5,
    },

    mainButtonTextSt: {
      color: colors.white,
      fontFamily: fonts.GothamBold,
      fontStyle: 'italic',
      fontSize: 13,
    },

    settingTopTitleSt: {
      backgroundColor: colors.grey,
      padding: 12,
      marginBottom: 2,
      width: '100%',
      fontFamily: fonts.GothamBold,
    },

    createTargetRuleTooltipSentenceSt: {
      fontSize: 14,
      marginTop: 10,
    },
    createTargetRuleIncrementSt: {
      fontSize: 37,
      fontFamily: fonts.GothamBold,
      margin: '0 25px 0 40px',
    },
    createTargetRuleInfoSt: {
      textAlign: 'center',
      marginTop: 20,
      lineHeight: 1.35,
    },

    targetHeaderTitleSt: {
      color: colors.white,
      fontFamily: fonts.GothamBold,
    },

    targetHeaderInfoSt: {
      color: colors.white,
      fontSize: 11,
      fontFamily: fonts.GothamBold,
    },

    targetMovementItemDateSt: {
      fontSize: 11,
      color: colors.red,
      textTransform: 'uppercase',
      fontFamily: fonts.GothamBold,
    },

    targetMovementItemAmountSt: {
      fontSize: 16,
      margin: '10px 0',
    },

    banksCollapseTitleTextSt: {
      textTransform: 'uppercase',
      fontSize: 13,
      fontFamily: fonts.GothamBold,
      color: colors.white,
    },

    banksCollapseTitleUpdateSt: {
      fontSize: 11,
      fontFamily: fonts.GothamBold,
      color: colors.white,
      marginTop: 6,
    },

    banksItemNameSt: {
      textTransform: 'uppercase',
      fontSize: 13,
      fontFamily: fonts.GothamBold,
    },

    questionFormSt: {
      margin: '30px 0 5px 0',
      // width: '45%',
      fontSize: 14,
      lineHeight: 1.25,
      fontFamily: fonts.GothamBold,
      textTransform: 'uppercase',
    },

    questionRuleSt: {
      margin: '50px 0 10px 0',
      fontSize: 14,
      lineHeight: 1.25,
      fontFamily: fonts.GothamBold,
      textTransform: 'uppercase',
    },
    targetItemDataTitleSt: {
      fontFamily: fonts.GothamBold,
      color: colors.darkBlue,
    },

    progressBarTextSt: {
      color: 'white',
      fontFamily: fonts.GothamBold,
      fontSize: 14,
    },

    pausedTextSt: {
      'font-size': '12px',
      'font-weight': 'bold',
      color: '#3c84cc',
    },
    ...commonStyles.styles,
  },
});
