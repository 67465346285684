import styled from 'styled-components/macro';
import * as GS from 'styles/globalStyles';

export const CloseButon = styled.div`
  background-color: ${GS.colors.darkGreen};
  border-radius: 50%;
  color: ${GS.colors.white};
  cursor: pointer;
  display: grid;
  font-size: ${GS.fontSizes.sm};
  font-weight: ${GS.fontWeights.bold};
  height: ${GS.spacing.sm};
  place-items: center;
  position: absolute;
  right: ${GS.spacing.xsm};
  text-align: right;
  top: ${GS.spacing.xsm};
  width: ${GS.spacing.sm};
`;
