import { InputSwitch } from '@coinscrap/webapp-core';

import houseIcon from 'assets/images/icon-house.png';
import cardIcon from 'assets/images/icon-credit-card.png';

import * as GS from 'styles/globalStyles';
import * as S from './styles';

const BankItem = ({
  type,
  name,
  number,
  amount,
  whiteSwitch,
  onChange,
  isActive,
  hideSwitch,
  last,
}) => (
  <GS.RowContainer
    background={GS.colors.lighterGrey}
    borderRadius={last ? `0 0 ${GS.radius.sm} ${GS.radius.sm}` : 0}
    padding={GS.spacing.xs2}
    alignItems="center"
    borderBottom={`1px solid ${GS.colors.white}`}
    opacity={isActive ? 1 : 0.5}
  >
    <GS.RowContainer alignItems="center" width="auto" grow={1} gap={GS.spacing.xs}>
      <S.Icon src={type === 'checking' ? houseIcon : cardIcon} />
      <GS.Container alignItems="start">
        <GS.Text fontBold>{name}</GS.Text>
        {number && (
          <GS.Text color={GS.colors.lightGreen} fontSize={GS.fontSizes.xs2}>
            {`**** ${number.substring(number.length - 4)}`}
          </GS.Text>
        )}
        {amount && (
          <GS.Text fontBold color={GS.colors.lightGreen}>
            {amount + '€'}
          </GS.Text>
        )}
      </GS.Container>
    </GS.RowContainer>
    <GS.Container width="fit-content">
      {!hideSwitch && <InputSwitch value={isActive} onChange={onChange} />}
    </GS.Container>
  </GS.RowContainer>
);

export default BankItem;
