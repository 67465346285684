import React, { useEffect } from 'react';

import {
  UiWrapper,
  View,
  Text,
  Button,
  TextField,
  Enfatize,
  SoftSwitcher,
  Page,
  NavigationWrapper,
  FinancialWrapper,
  OperationsWrapper,
} from '@coinscrap/webapp-core';
import HeaderChat from 'components/common/Header/HeaderChat';
import moment from 'moment';
import routes from 'config/routes';

const typesMap = {
  FETCH_TRANSACTIONS: 'Obtención de transacciones',
  FETCH_PRODUCTS: 'Obtención de productos',
};

let closeFunc = () => undefined;

export const OperationResolution = () => {
  const { operations } = OperationsWrapper.use();
  const { runOperation } = FinancialWrapper.use();
  const {
    params: { idOperation },
  } = Page.use();
  const operation = operations?.[idOperation];
  const { useHeader, useLoading, openModal } = UiWrapper.use();
  useLoading(!operation);
  const { navigateTo } = NavigationWrapper.use();
  //operation && console.log(" OPERATION TO RESOLVE ", operation);
  //institutionAccounts && console.log(" INST ", institutionAccounts);

  useHeader(
    <HeaderChat
      text="Operaciones Pend."
      onBack={() => {
        closeFunc();
        navigateTo(routes.operationResolution, { handleRedirection: true, mode: 'replace' });
      }}
    />
  );

  useEffect(() => {
    if (operation) {
      (async () => {
        try {
          //console.log(' GOING TO RUN OPERATION');
          await runOperation(
            operation.id,
            undefined,
            'AWAIT_FULL_PROCESS',
            (message, otpSetter, discard) => {
              //console.log(' ON REQUESTING PENDING', message, otpSetter, discard);
              openModal(
                (close, context, setContext) => {
                  closeFunc = close;
                  return (
                    <View style={{ padding: 10 }}>
                      <Text style={{ marginBottom: 25 }}>{message}</Text>
                      <TextField
                        afterBanksInputSt
                        label="Código de activación"
                        value={context.value}
                        onChange={(e) => setContext({ value: e.target.value })}
                      />
                      <View style={{ marginTop: 25 }}>
                        <Button
                          style={{ padding: 7 }}
                          onClick={() => {
                            otpSetter(context.value);
                            close();
                          }}
                        >
                          Aceptar
                        </Button>
                        <Button
                          style={{ padding: 7 }}
                          onClick={() => {
                            discard();
                            close();
                          }}
                        >
                          Cancelar
                        </Button>
                      </View>
                    </View>
                  );
                },
                { disableClickAway: true }
              );
            },
            {
              onSuccess: () => {
                //console.log(' RUNED SUCCESS');
              },
              onFailure: () => {
                //console.log(' RUNED FAILURE');
              },
              onDiscard: () => {
                //console.log(' RUNED DISCARDED');
              },
            }
          );
        } catch (e) {
          //console.log('ERROR', e);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operation?.id]);
  moment.locale('es');
  return (
    <View fullHeightSt style={{ justifyContent: 'flex-start' }}>
      <Text>Operación pendiente: </Text>
      <Text>
        <Enfatize boldSt> {typesMap[operation?.type]} </Enfatize>
      </Text>
      <Text style={{ fontSize: 10 }}>
        {operation
          ? `${moment(operation.createDate).format('DD/MM/YYYY hh:mm:ss')} (${moment(
            operation.createDate
          )
            .locale(moment.locale('es'))
            .fromNow()})`
          : ''}{' '}
      </Text>
      <br />
      <SoftSwitcher
        evaluate={
          operation ? ['PENDING', 'SUCCESS', 'FAILURE', 'DISCARDED'].indexOf(operation.status) : 0
        }
      >
        <>
          <View style={{ width: '80%' }}>
            <Text centerSt>Espera mientras resolvemos la operación pendiente</Text>
          </View>
          <View style={{ width: '80%' }}>
            <Text centerSt>
              Es posible que durante el proceso se le pidan credenciales de acceso
            </Text>
          </View>
        </>
        <>
          <View style={{ width: '80%' }}>
            <Text centerSt>Operacion realizada correctamente</Text>
          </View>
        </>
        <>
          <View style={{ width: '80%' }}>
            <Text centerSt>Ha ocurrido un error en la operacion</Text>
          </View>
        </>
        <>
          <View style={{ width: '80%' }}>
            <Text centerSt>Operacion descartada</Text>
          </View>
        </>
      </SoftSwitcher>
    </View>
  );
};
