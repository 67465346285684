import { PickerHOC, TargetsWrapper, UiWrapper } from '@coinscrap/webapp-core';

import Footer from 'components/common/Footer/Footer';
import BottomSection from './components/BottomSection';
import Slider from './components/Slider';
import { MaxWidthWrapper } from 'wrappers/MaxWidthWrapper';

import * as GS from 'styles/globalStyles';

export const Home = PickerHOC({
  userTargets: { sourceWrapper: TargetsWrapper, sourcePath: 'targets' },
})(() => {
  const { useHeader, useFooter, useLoading } = UiWrapper.use();

  useLoading(false);

  const { matched } = UiWrapper.use();
  const isDesktop = matched.isDesktopMq;

  useHeader(isDesktop ? <Footer location="home" /> : <GS.Container />, [isDesktop]);
  useFooter(!isDesktop && <Footer location="home" />, [isDesktop]);

  return (
    <MaxWidthWrapper>
      <GS.Container
        height="100%"
        justifyContent="start"
        alignItems="center"
        padding={GS.spacing.xs}
        gap={GS.spacing.xs}
      >
        <Slider />
        <BottomSection />
      </GS.Container>
    </MaxWidthWrapper>
  );
});
