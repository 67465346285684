import {
  ApplicationWrapper,
  NavigationWrapper,
  UiWrapper,
  BackendWrapper,
  InAppBrowserWrapper,
  SessionWrapper,
  UserWrapper,
  TargetTypesWrapper,
  OperationsWrapper,
  InstitutionsWrapper,
  InstitutionAccountsWrapper,
  FinancialWrapper,
  RulesWrapper,
  TargetsUtilsWrapper,
  TargetsWrapper,
  RuleDefinitionsWrapper,
} from '@coinscrap/webapp-core';

import { MobileProvider } from 'contexts/MobileContext';
import config from 'config';
import routes from 'config/routes';
import { CustomLoading } from './CustomLoading/CustomLoading';

class StartupParamsProviderUtility {
  get params() {
    return this._params;
  }

  set params(value) {
    this._params = value;
  }
}

export const startupParamsProviderUtility = new StartupParamsProviderUtility();

ApplicationWrapper.init({
  projectName: 'mabogacia',
  contextTheme: 'light',
  disableNative: true,
  onStartupParams: (params = {}) => {
    startupParamsProviderUtility.params = {
      token: params.token,
      idDato: params.idDato,
      nMut: params.nMut,
      sva: params.sva,
    };
  },
});

UiWrapper.defineMedia('isDesktopMq', { minWidth: 750 });

const GlobalWrapper = ({ children }) => (
  <ApplicationWrapper>
    <BackendWrapper enableNativeBridge>
      <NavigationWrapper>
        <InAppBrowserWrapper>
          <SessionWrapper
            entryPoint={{
              pageIfValid: routes.tokenEntry,
              pageIfInvalid: routes.tokenEntry,
            }}
          >
            <UserWrapper>
              <TargetTypesWrapper>
                <OperationsWrapper disableInitialLoading config={{ poolingMs: 250 }}>
                  <InstitutionsWrapper>
                    <InstitutionAccountsWrapper>
                      <FinancialWrapper>
                        <RuleDefinitionsWrapper>
                          <TargetsWrapper enableNextSaving enableTargetRulesConfiguration>
                            <RulesWrapper>
                              <TargetsUtilsWrapper>
                                <UiWrapper
                                  config={config}
                                  loadingGenerator={(loadingProps) => (
                                    <CustomLoading loadingProps={loadingProps} />
                                  )}
                                >
                                  <MobileProvider>{children}</MobileProvider>
                                </UiWrapper>
                              </TargetsUtilsWrapper>
                            </RulesWrapper>
                          </TargetsWrapper>
                        </RuleDefinitionsWrapper>
                      </FinancialWrapper>
                    </InstitutionAccountsWrapper>
                  </InstitutionsWrapper>
                </OperationsWrapper>
              </TargetTypesWrapper>
            </UserWrapper>
          </SessionWrapper>
        </InAppBrowserWrapper>
      </NavigationWrapper>
    </BackendWrapper>
  </ApplicationWrapper>
);

export default GlobalWrapper;
