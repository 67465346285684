import { useState } from 'react';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const FaqItemCollapse = ({ title, children, last }) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <GS.RowContainer
        alignItems="center"
        justifyContent="space-between"
        background={GS.colors.lighterGrey}
        borderRadius={last ? `0 0 ${GS.radius.sm} ${GS.radius.sm}` : 0}
        padding={GS.spacing.xs}
        gap={GS.spacing.xs}
        cursor="pointer"
        onClick={() => setVisible((v) => !v)}
      >
        <GS.Text textLeft fontBold>
          {title}
        </GS.Text>
        <SVGIcon
          Icon={Angle}
          width={GS.spacing.xs}
          height="auto"
          color={GS.colors.green}
          transform={visible ? '' : 'rotate(-90deg)'}
        />
      </GS.RowContainer>
      <GS.Container
        maxHeight={visible ? 'none' : '0'}
        overflow="hidden"
        background={GS.colors.lighterGrey}
        padding={
          visible ? `0 ${GS.spacing.xs} ${GS.spacing.xs} ${GS.spacing.xs}` : `0 ${GS.spacing.xs}`
        }
      >
        <GS.Text textLeft fontSize={GS.fontSizes.xs}>
          {children}
        </GS.Text>
      </GS.Container>
    </>
  );
};

export default FaqItemCollapse;
