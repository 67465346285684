import { UiWrapper } from '@coinscrap/webapp-core';
import gallery from 'assets/images/gallery.png';
import * as GS from 'styles/globalStyles';

UiWrapper.defineComponent('imageSelector', {
  styles: {
    default: {
      imageComponent: {},
      cameraComponent: {},
      buttonComponent: {
        textComponent: {},
        disabledMode: {
          textComponent: {},
        },
      },
    },
    imageSelectorSt: {
      imageComponent: {
        width: '100%',
        boxShadow: 'none',
        backgroundColor: GS.colors.white,
        borderRadius: '0',
        fontFamily: GS.fontFamilies.Mutualidad,
      },
      modalContentComponent: {
        padding: GS.spacing.xs,
      },
      resultsContainerParentComponent: {
        width: '100%',
        flexWrap: 'wrap',
        flexFlow: 'initial',
      },
      resultsContainerComponent: {
        flexFlow: 'wrap',
      },
      resultItemComponent: {
        width: '100%',
        marginBottom: GS.spacing.xs,
      },
      resultItemImageComponent: {
        width: '100%',
        minWidth: 0,
        height: 'auto',
        borderRadius: 0,
      },
      modalComponent: {
        margin: 0,
        containerComponent: {
          margin: 0,
          padding: GS.spacing.xs,
        },
        contentComponent: {
          padding: 0,
          margin: 0,
          borderRadius: GS.radius.xs,
          overflow: 'hidden',
        },
        baseComponent: {
          width: '100%',
          maxWidth: GS.screenSizes.md,
          margin: 'auto',
          marginTop: 0,
          marginBottom: 0,
        },
      },
      buttonComponent: {
        padding: GS.spacing.xs,
        border: 'none',
        backgroundColor: 'transparent',
        fontSize: GS.fontSizes.xs,
        textComponent: {
          fontSize: GS.fontSizes.xs,
          fontFamily: GS.fontFamilies.Mutualidad,
          color: '#cecece',
        },
      },
      cameraComponent: {
        backgroundImage: `url(${gallery})`,
        backgroundSize: 'cover',
        fill: 'transparent',
      },
      headerContainerComponent: {
        padding: 0,
        margin: 0,
      },
      headerTextComponent: {
        fontSize: GS.fontSizes.xs,
        color: GS.colors.green,
        fontFamily: GS.fontFamilies.Mutualidad,
        display: 'none',
      },
      closeIconComponent: {
        color: GS.colors.green,
        fontSize: GS.fontSizes.md,
      },
      searchContainerComponent: {
        width: '100%',
        fontFamily: GS.fontFamilies.Mutualidad,
      },
      searchFieldComponent: {
        padding: 0,
        margin: 0,
        marginTop: 0,
        marginBottom: 0,
        backgroundColor: GS.colors.white,
        baseComponent: {
          padding: 0,
          margin: 0,
        },
        labelComponent: {
          fontFamily: GS.fontFamilies.Mutualidad,
        },
        containerComponent: {
          width: '100%',
          backgroundColor: GS.colors.white,
          border: `1px solid ${GS.colors.green}`,
        },
        inputContainerComponent: {
          borderRadius: 0,
          marginBottom: 0,
          fontFamily: GS.fontFamilies.Mutualidad,
          backgroundColor: GS.colors.white,
        },
      },
      emptyImagesTextComponent: {
        fontFamily: GS.fontFamilies.Mutualidad,
      },
    },
  },
});
