import styled, { css } from 'styled-components/macro';
import * as GS from '../../../styles/globalStyles';

const filled = css`
  color: white;
`;

export const SelectContainer = styled(GS.Container)`
  align-items: center;
  border-radius: ${({ borderRadius }) => borderRadius || GS.radius.sm};
  ${({ border }) => border && border}
  flex-direction: row;
  transition: all 0.3s;
  ${(props) => props.value && filled}
`;

export const Select = styled.select`
  background: transparent;
  border: none;
  color: ${({ color }) => color || GS.colors.white};
  font-family: inherit;
  font-size: ${GS.fontSizes.md};
  outline: none;
  padding: ${GS.spacing.xxxs} ${GS.spacing.xxs} ${GS.spacing.xxs} ${GS.spacing.xxs};
  text-align: ${({ textLeft }) => (textLeft ? 'left' : 'center')};
  transition: all 0.3s;
  width: 100%;
  &::placeholder {
    color: ${GS.colors.white};
    font-family: inherit;
    font-size: ${GS.fontSizes.md};
    font-weight: ${GS.fontWeights.medium};
  }
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  }
`;

export const Option = styled.option`
  background: ${GS.colors.white};
  color: ${GS.colors.grey};
`;

export const LabelText = styled(GS.Text)`
  color: ${({ color }) => color || GS.colors.whiteMidTransparent};
  font-size: ${GS.fontSizes.xs};
  padding-top: ${GS.spacing.xxxs};
`;
