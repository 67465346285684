import { useState } from 'react';

import { ReactComponent as Angle } from 'assets/svgs/angle.svg';
import { SVGIcon } from 'components/common/SVGIcon/SVGIcon';

import * as GS from 'styles/globalStyles';

const Collapse = ({ title, children, style, isOpened = false, setOpened, showArrow = true }) => {
  const capitalizedTitle = title?.[0]?.toUpperCase() + title?.substring(1);

  const [visible, setVisible] = useState(false);

  return (
    <GS.Container>
      {title && (
        <GS.RowContainer
          alignItems="center"
          justifyContent="space-between"
          background={!visible ? GS.colors.lightGrey : GS.colors.lightElectricGreen}
          borderRadius={!visible ? GS.radius.sm : `${GS.radius.sm} ${GS.radius.sm} 0 0`}
          padding={GS.spacing.xs}
          gap={GS.spacing.xs}
          cursor="pointer"
          onClick={() => setVisible(!visible)}
        >
          <GS.Text textLeft fontBold>
            {capitalizedTitle}
          </GS.Text>
          {showArrow && (
            <SVGIcon
              Icon={Angle}
              width={GS.spacing.xs}
              height="auto"
              color={GS.colors.green}
              transform={visible ? '' : 'rotate(-90deg)'}
            />
          )}
        </GS.RowContainer>
      )}
      <GS.Container maxHeight={visible ? 'none' : '0'} overflow="hidden">
        {children}
      </GS.Container>
    </GS.Container>
  );
};

export default Collapse;
